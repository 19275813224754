import {
  AppBar,
  styled,
  AppBarProps,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps,
  Badge,
  BadgeProps,
  Select,
  SelectProps,
  Grid,
  GridProps,
  Typography,
  Toolbar,
  Paper,
  ListItemIcon,
  MenuItem,
  TypographyProps,
  Tabs,
  Avatar,
  keyframes,
  Menu,
  Button,
  Card,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { CheckCircle, Circle } from '@mui/icons-material';

const NavContainer = styled(Box)(() => ({
  flexGrow: 'unset',
  height: '80px',
  zIndex: '0',
}));

const NavigationContainer = styled(AppBar)<AppBarProps>(({ theme }) => ({
  height: '80px',
  position: 'sticky',
  backgroundImage: 'none',
  boxShadow: 'none',
  backgroundColor: theme.palette.background.paper,
  borderBottom: '1px solid rgba(165, 165, 165, 0.12)',
}));

const MenuContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
  [theme.breakpoints.down('md')]: {
    gap: '0px',
  },
}));

const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const Count = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    background:
      'linear-gradient(89.68deg, #3BA6F9 10.34%, #36ADF3 18.23%, #00F6C0 94.46%)',
  },
}));

const CustomSelect = styled(Select)<SelectProps>(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '8.5px 14px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiList-root': {
    overflow: 'auto',
    maxHeight: '300px',
  },
}));

const TypoNotify = styled(Typography)<TypographyProps>(() => ({
  padding: '10px 20px',
  textAlign: 'center',
}));

const LastOption = styled(Paper)(({ theme }) => ({
  position: 'sticky',
  bottom: '0',
  backgroundColor: theme.palette.background.paper,
}));

const UserProfileContainer = styled(Grid)<GridProps>(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  margin: 'auto',
  height: '40px',
}));

const UserDetailsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  marginLeft: '8px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ArrowButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '0px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

const AvatarCircle = styled(PersonIcon)(({ theme }) => ({
  height: '40px',
  width: '40px',
  color: theme.palette.text.primary,
}));

const ProjectText = styled(Typography)(({ theme }) => ({
  paddingRight: '16px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavToolBar = styled(Toolbar)(() => ({
  height: 'inherit',
  display: 'flex',
  justifyContent: 'flex-end',
}));

const NavBox = styled(Box)(({ theme }) => ({
  flexGrow: '1',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const glow = keyframes`
0% {
  -webkit-box-shadow: 0 0 3px #0091b2;
}
50% {
  -webkit-box-shadow: 0 0 15px #21c7ed;
}
100% {
  -webkit-box-shadow: 0 0 3px #0091b2;
}
`;

const AccountMenuContainer = styled(Paper)(() => ({
  width: '243px',
  '& .MuiCheckbox-root': {
    padding: '0',
  },
  '& .notSeenGlow': {
    animation: `${glow} 1s ease-in-out infinite alternate`,
    color: '#0091b2',
  },
}));

const ItemIcon = styled(ListItemIcon)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

const ItemIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
}));

const CustomMenuItem = styled(MenuItem)(() => ({
  // flexDirection: 'column',
  display: 'flex',
  alignItems: 'flex-start !important',
  '& .MuiTypography-root': {
    whiteSpace: 'pre-line',
  },
}));

const MenuItemDesc = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.light,
}));

const MaplayoutTab = styled(Tabs)(({ theme }) => ({
  '& .MuiButtonBase-root.MuiTab-root': {
    color: theme.palette.text.light,
    textTransform: 'capitalize',
  },
  '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
    color: theme.palette.primary.dark,
    textTransform: 'capitalize',
  },
  '& .MuiTypography-root': {
    fontWeight: '600',
  },
}));

const AvatarContainer = styled(Avatar)(() => ({
  '& img': {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
}));

const ReportModalContainer = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 5,
  [theme.breakpoints.down('sm')]: {
    width: '300px',
  },
}));

const ReportBlock = styled(Paper)(({ theme }) => ({
  width: '600px',
  backgroundImage: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const ReportInputBlock = styled(Box)(({ theme }) => ({
  padding: '13px 30px',
  [theme.breakpoints.down('sm')]: {
    padding: '13px 10px',
  },
  '& .MuiTypography-caption, .MuiTypography-subtitle1': {
    color: theme.palette.text.light,
  },
  '& .MuiTypography-caption.error': {
    color: theme.palette.error.main,
  },
}));

const ActionButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  paddingTop: '24px',
  '& .action': {
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    height: '42px',
  },
  '& .cancel': {
    marginRight: 20,
  },
}));

const ReportHeader = styled(Typography)(({ theme }) => ({
  padding: '0 0 10px 9px',
  color: theme.palette.common.white,
}));

const SignUpSuccessMessage = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: theme.palette.common.white,
  }),
);

const SignUpSuccessDesc = styled(Typography)<TypographyProps>(({ theme }) => ({
  textAlign: 'center',
  fontSize: 15,
  marginTop: 15,
  color: theme.palette.common.white,
}));

const SignUpIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const SignupIconBlock = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const SuccessButtonsBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '24px',
}));

const NotificationMenu = styled(Menu)(() => ({
  '& .MuiList-root': {
    padding: '0',
  },
}));

const NotificationContainer = styled(Box)(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.background.paper,
  '& .MuiTypography-subtitle1': {
    padding: '16px 16px 0 16px',
  },
  '& .read': {
    marginLeft: '10px',
  },
}));

const NotificationsTab = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: '10px',
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',
  },
  '& .MuiButtonBase-root.MuiTab-root .MuiTypography-root': {
    color: theme.palette.text.icon,
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  '& .MuiButtonBase-root.MuiTab-root.Mui-selected .MuiTypography-root': {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontWeight: '700',
  },
}));

const CustomBtn = styled(Button)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  width: '100%',
  height: '60px',
  textTransform: 'capitalize',
  marginTop: '10px',
  '& .MuiTypography-root': {
    fontWeight: '600',
  },
}));

const UnreadDot = styled(Circle)(() => ({
  width: '6px',
  height: '6px',
  color: '#D32F2F',
  marginTop: '5px',
  marginRight: '5px',
}));

const CustomCard = styled(Card)(({ theme }) => ({
  marginTop: '10px',
  marginBottom: '5px',
  '& .MuiCardActionArea-root': {
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.paper,
    height: '150px',
    backgroundImage:
      'repeating-linear-gradient(0deg, #ffffff3B, #ffffff3B 10px, transparent 10px, transparent 18px, #ffffff3B 18px), repeating-linear-gradient(90deg, #ffffff3B, #ffffff3B 10px, transparent 10px, transparent 18px, #ffffff3B 18px), repeating-linear-gradient(180deg, #ffffff3B, #ffffff3B 10px, transparent 10px, transparent 18px, #ffffff3B 18px), repeating-linear-gradient(270deg, #ffffff3B, #ffffff3B 10px, transparent 10px, transparent 18px, #ffffff3B 18px)',
    backgroundSize: '2px 100%, 100% 2px, 2px 100% , 100% 2px',
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
    '& .MuiCardHeader-root': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '49px',
      marginBottom: '4px',
      color: theme.palette.text.primaryLight,
    },
    '& .MuiTypography-subtitle1': {
      color: theme.palette.primary.main,
      fontWeight: '700',
    },
  },
}));

const ImagePreview = styled(Box)(({ theme }) => ({
  width: '300px',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  padding: '5px',
  margin: '5px 0',
  '& img': {
    height: 'inherit',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primaryLight,
  },
  '& .MuiTypography-caption': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const NavProjectBox = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
}));

export {
  NavigationContainer,
  MenuContainer,
  CustomIconButton,
  Count,
  CustomSelect,
  UserProfileContainer,
  UserDetailsContainer,
  ArrowButton,
  AvatarCircle,
  NavContainer,
  ProjectText,
  NavToolBar,
  NavBox,
  AccountMenuContainer,
  ItemIcon,
  CustomMenuItem,
  MenuItemDesc,
  MaplayoutTab,
  AvatarContainer,
  ItemIconButton,
  ReportModalContainer,
  ReportBlock,
  ReportInputBlock,
  ActionButtonsBox,
  ReportHeader,
  SignUpSuccessMessage,
  SignUpSuccessDesc,
  SignUpIcon,
  SignupIconBlock,
  SuccessButtonsBox,
  NotificationMenu,
  NotificationsTab,
  CustomBtn,
  NotificationContainer,
  UnreadDot,
  CustomCard,
  ImagePreview,
  LastOption,
  NavProjectBox,
  TypoNotify,
};
