import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { mapapi, floors, submitMap } from '../../services/apiDefinition';

type uploadMapType = {
  mapData: FormData;
  uuid: string;
};

type updateMapType = {
  mapData: any;
  uuid: string;
};

type floorType = {
  project: string;
  dispatch: any;
};

type createFloorType = {
  project: string;
  name: string;
  dispatch: any;
  mapData: FormData | null;
};

type editFloorType = {
  uuid: string;
  project: string;
  name: string;
  dispatch: any;
};

type deleteFloorType = {
  uuid: string;
  project: string;
  dispatch: any;
};

export const saveMapDetails = createAsyncThunk(
  'maplayout/saveMapDetails',
  async ({ mapData, uuid }: updateMapType) => {
    try {
      const response = await axiosApiInstance.put(`${mapapi}${uuid}/`, { metaData: mapData });
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.resp);
      }
    }
  },
);

export const getMapData = createAsyncThunk('maplayout/getMapData', async (uuid: string) => {
  try {
    const response = await axiosApiInstance.get(`${mapapi}${uuid}`);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const uploadMap = createAsyncThunk('maplayout/uploadMap', async ({ mapData, uuid }: uploadMapType) => {
  try {
    const response = await axiosApiInstance.post(mapapi, mapData, { params: { floor: uuid } });
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const deleteMap = createAsyncThunk('maplayout/deleteMap', async (uuid: string) => {
  try {
    const response = await axiosApiInstance.delete(`${mapapi}${uuid}/`);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const submitFloorPlan = createAsyncThunk('maplayout/submitFloorPlan', async (mapId: string) => {
  try {
    const response = await axiosApiInstance.put(`${submitMap}${mapId}/`);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const getFloors = createAsyncThunk('maplayout/getFloors', async ({ project, dispatch }: floorType) => {
  try {
    const response = await axiosApiInstance.get(floors, {
      params: { project },
    });
    if (response.data.length > 0) {
      dispatch(getMapData(response.data[0].uuid));
    }
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const createFloor = createAsyncThunk(
  'maplayout/createFloor',
  async ({ project, name, dispatch, mapData }: createFloorType) => {
    try {
      const response = await axiosApiInstance.post(
        floors,
        {
          name,
        },
        { params: { project } },
      );
      if (mapData !== null) {
        await axiosApiInstance.post(mapapi, mapData, { params: { floor: response.data.uuid } });
      }
      dispatch(getFloors({ project, dispatch }));
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.resp);
      }
    }
  },
);

export const deleteFloor = createAsyncThunk('maplayout/deleteFloor', async ({ uuid, project, dispatch }: deleteFloorType) => {
  try {
    await axiosApiInstance.delete(`${floors}${uuid}/`, { params: { project } });
    dispatch(getFloors({ project, dispatch }));
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});

export const editFloor = createAsyncThunk('maplayout/editFloor', async ({ uuid, project, name, dispatch }: editFloorType) => {
  try {
    await axiosApiInstance.put(
      `${floors}${uuid}/`,
      {
        name,
      },
      { params: { project } },
    );
    dispatch(getFloors({ project, dispatch }));
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.resp);
    }
  }
});
