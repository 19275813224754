import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { trainModelApi, aiData } from '../../services/apiDefinition';

type modelType = {
  mapId: string;
  date: Record<string, unknown>;
};

export const startTrainModel = createAsyncThunk(
  'aiTab/startTrainModel',
  async ({ mapId, date }: modelType) => {
    try {
      await axiosApiInstance.post(trainModelApi, date, {
        params: { mapid: mapId },
      });
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getAiData = createAsyncThunk('aiTab/getAiData', async (key: string) => {
  try {
    const response = await axiosApiInstance.get(`${aiData}${key}`);
    return [response.data, key];
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.message);
    }
  }
});
