import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { addProject, updateProject, deleteProject } from '../thunks/projectThunk';
import { addArea, updateArea, deleteArea } from '../thunks/areaThunk';
import { addDeviceInArea, updateDeviceInArea, deleteDeviceInArea } from '../thunks/devicesThunk';
import {
  API_PENDING_STATUS,
  API_SUCCESS_STATUS,
  API_FAILURE_STATUS,
} from '../../utils/constants';
import { crudReducerTypes } from '../../utils/types/reducerStateTypes';

const initialState: crudReducerTypes = {
  projectAPILoadingStatus: false,
  projectAPIExecutionStatus: API_PENDING_STATUS,
  projectAPIFailureMessage: '',
  projectSingleNode: {},
  projectDeleted: '',
  areaAPILoadingStatus: false,
  areaAPIExecutionStatus: API_PENDING_STATUS,
  areaAPIFailureMessage: '',
  areaCreated: false,
  areaUpdatedNode: {},
  areaDeleted: '',
  deviceAPILoadingStatus: false,
  deviceAPIExecutionStatus: API_PENDING_STATUS,
  deviceAPIFailureMessage: '',
  deviceSingleNode: {},
  deviceCreated: false,
  deviceUpdated: {},
  deviceDeleted: '',
};

const crudSlice = createSlice({
  name: 'crudData',
  initialState,
  reducers: {
    resetProjectData: (state) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIExecutionStatus = API_PENDING_STATUS;
      state.projectAPIFailureMessage = '';
      state.projectSingleNode = {};
      state.projectDeleted = '';
    },
    resetAreaData: (state) => {
      state.areaAPIExecutionStatus = API_PENDING_STATUS;
      state.areaAPILoadingStatus = false;
      state.areaAPIFailureMessage = '';
      state.areaUpdatedNode = {};
      state.areaCreated = false;
      state.areaDeleted = '';
    },
    resetDeviceData: (state) => {
      state.deviceAPIExecutionStatus = API_PENDING_STATUS;
      state.deviceAPILoadingStatus = false;
      state.deviceAPIFailureMessage = '';
      state.deviceUpdated = {};
      state.deviceCreated = false;
      state.deviceDeleted = '';
    },
  },
  extraReducers: {
    [addProject.pending.type]: (state) => {
      state.projectAPILoadingStatus = true;
      state.projectAPIFailureMessage = '';
      state.projectAPIExecutionStatus = API_PENDING_STATUS;
    },
    [addProject.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      if (action.payload.hashed_identifier) {
        state.projectSingleNode = action.payload;
        state.projectAPIExecutionStatus = API_SUCCESS_STATUS;
      }
    },
    [addProject.rejected.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIExecutionStatus = API_FAILURE_STATUS;
      state.projectAPIFailureMessage = action.error.message;
    },
    [updateProject.pending.type]: (state) => {
      state.projectAPILoadingStatus = true;
    },
    [updateProject.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      if (action.payload.hashed_identifier) {
        state.projectSingleNode = action.payload;
        state.projectAPIExecutionStatus = API_SUCCESS_STATUS;
      }
    },
    [updateProject.rejected.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIExecutionStatus = API_FAILURE_STATUS;
      state.projectAPIFailureMessage = action.error.message;
    },
    [deleteProject.pending.type]: (state) => {
      state.projectAPILoadingStatus = true;
    },
    [deleteProject.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectDeleted = action.payload;
      state.projectAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [deleteProject.rejected.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIExecutionStatus = API_FAILURE_STATUS;
      state.projectAPIFailureMessage = action.error.message;
    },
    [addArea.pending.type]: (state) => {
      state.areaAPILoadingStatus = true;
    },
    [addArea.fulfilled.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      if (action.payload.uuid) {
        state.areaCreated = true;
        state.areaAPIExecutionStatus = API_SUCCESS_STATUS;
      }
    },
    [addArea.rejected.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      state.areaAPIExecutionStatus = API_FAILURE_STATUS;
      state.areaAPIFailureMessage = action.error.message;
    },
    [updateArea.pending.type]: (state) => {
      state.areaAPILoadingStatus = true;
    },
    [updateArea.fulfilled.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      if (action.payload.uuid) {
        state.areaUpdatedNode = action.payload;
        state.areaAPIExecutionStatus = API_SUCCESS_STATUS;
      }
    },
    [updateArea.rejected.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      state.areaAPIExecutionStatus = API_FAILURE_STATUS;
      state.areaAPIFailureMessage = action.error.message;
    },
    [deleteArea.pending.type]: (state) => {
      state.areaAPILoadingStatus = true;
    },
    [deleteArea.fulfilled.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      // if (action.payload.uuid) {
      state.areaDeleted = action.payload.uuid;
      state.areaAPIExecutionStatus = API_SUCCESS_STATUS;
      // }
    },
    [deleteArea.rejected.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      state.areaAPIExecutionStatus = API_FAILURE_STATUS;
      state.areaAPIFailureMessage = action.error.message;
    },
    [addDeviceInArea.pending.type]: (state) => {
      state.deviceAPILoadingStatus = true;
    },
    [addDeviceInArea.fulfilled.type]: (state) => {
      state.areaAPILoadingStatus = false;
      // if (action.payload.uuid) {
      state.deviceCreated = true;
      state.deviceAPIExecutionStatus = API_SUCCESS_STATUS;
      // }
    },
    [addDeviceInArea.rejected.type]: (state, action) => {
      state.deviceAPILoadingStatus = false;
      state.deviceAPIExecutionStatus = API_FAILURE_STATUS;
      state.deviceAPIFailureMessage = action.error.message;
    },
    [updateDeviceInArea.pending.type]: (state) => {
      state.deviceAPILoadingStatus = true;
    },
    [updateDeviceInArea.fulfilled.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      // if (action.payload.uuid) {
      state.deviceUpdated = action.payload;
      state.deviceAPIExecutionStatus = API_SUCCESS_STATUS;
      // }
    },
    [updateDeviceInArea.rejected.type]: (state, action) => {
      state.deviceAPILoadingStatus = false;
      state.deviceAPIExecutionStatus = API_FAILURE_STATUS;
      state.deviceAPIFailureMessage = action.error.message;
    },
    [deleteDeviceInArea.pending.type]: (state) => {
      state.deviceAPILoadingStatus = true;
    },
    [deleteDeviceInArea.fulfilled.type]: (state, action) => {
      state.areaAPILoadingStatus = false;
      // if (action.payload.uuid) {
      state.deviceDeleted = action.payload;
      state.deviceAPIExecutionStatus = API_SUCCESS_STATUS;
      // }
    },
    [deleteDeviceInArea.rejected.type]: (state, action) => {
      state.deviceAPILoadingStatus = false;
      state.deviceAPIExecutionStatus = API_FAILURE_STATUS;
      state.deviceAPIFailureMessage = action.error.message;
    },
  },
});

export const {
  resetProjectData,
  resetAreaData,
  resetDeviceData,
} = crudSlice.actions;

const crudPersistConfig = {
  key: 'operations',
  storage,
};

export default persistReducer(crudPersistConfig, crudSlice.reducer);
