import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { API_PENDING_STATUS, API_FAILURE_STATUS, API_SUCCESS_STATUS } from '../../utils/constants';

import { deviceReducer } from '../../utils/types/reducerStateTypes';
import { getDevices } from '../thunks/devicesThunk';

const initialState: deviceReducer = {
  devices: [],
  edges: [
    {
      id: 'e1-2', source: '1', target: '2', type: 'smart',
    },
  ],
  deviceAPILoadingStatus: false,
  deviceAPIExecutionStatus: API_PENDING_STATUS,
  deviceAPIFailureMessage: '',
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    addUpdateEdge: (state, action) => {
      state.edges = action.payload;
    },
  },
  extraReducers: {
    [getDevices.pending.type]: (state) => {
      state.deviceAPILoadingStatus = true;
    },
    [getDevices.fulfilled.type]: (state, action) => {
      state.deviceAPILoadingStatus = false;
      state.deviceAPIExecutionStatus = API_SUCCESS_STATUS;
      state.devices = action.payload;
    },
    [getDevices.rejected.type]: (state) => {
      state.deviceAPILoadingStatus = false;
      state.deviceAPIExecutionStatus = API_FAILURE_STATUS;
    },
  },
});

export const { addUpdateEdge } = deviceSlice.actions;

const devicePersistConfig = {
  key: 'device',
  storage,
  whitelist: ['devices'],
};

export default persistReducer(devicePersistConfig, deviceSlice.reducer);
