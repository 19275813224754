import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { currentState, dashboardUrls } from '../../services/apiDefinition';
import { getOrdersData } from './ordersThunk';
import { getInventoryData } from './inventoryThunk';
import { getProjectDetails } from './projectThunk';
import { getFloors } from './maplayoutThunk';
import { getCompanyIntegrations } from './integrationsThunk';

type switchProjectProps = {
  id: string | null;
  dispatch: any;
  updateProjectData?: boolean;
};

type getProjectType = {
  dispatch: any;
  full?: boolean;
};

export const getDashboardUrls = createAsyncThunk(
  'welcome/getDashboardUrls',
  async (name: string) => {
    try {
      const response = await axiosApiInstance.get(dashboardUrls + name, {
        withCredentials: true,
      });
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getCurrentProject = createAsyncThunk(
  'welcome/getCurrentProject',
  async ({ dispatch, full = true }: getProjectType) => {
    try {
      const response = await axiosApiInstance.get(currentState, {
        withCredentials: true,
      });
      if (
        response.data.state !== null &&
        response.data.state.current_project !== undefined &&
        response.data.state.current_project !== null
      ) {
        const params = {
          start: undefined,
          stop: undefined,
        };
        if (full) {
          dispatch(getOrdersData(params));
          dispatch(getInventoryData(params));
          dispatch(getProjectDetails({ hashed_identifier: response.data.state.current_project, dispatch }));
          dispatch(getFloors({ project: response.data.state.current_project, dispatch }));
          dispatch(getDashboardUrls(response.data.state.current_project));
        }
      }
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const updateCurrentProject = createAsyncThunk(
  'welcome/updateCurrentProject',
  async ({ id, dispatch, updateProjectData }: switchProjectProps) => {
    try {
      const response = await axiosApiInstance.put(
        currentState,
        { state: { current_project: id } },
        {
          withCredentials: true,
        },
      );
      if (id !== null) {
        dispatch(getDashboardUrls(id));
        const params = {
          start: undefined,
          stop: undefined,
        };
        if (updateProjectData) {
          dispatch(getOrdersData(params));
          dispatch(getInventoryData(params));
          dispatch(getFloors({ project: id, dispatch }));
          dispatch(getCompanyIntegrations());
        }
      }
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const updateUITheme = createAsyncThunk(
  'welcome/updateUITheme',
  async (theme: 'light' | 'dark') => {
    try {
      const response = await axiosApiInstance.put(
        currentState,
        { state: { ui_theme: theme } },
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
