/* eslint-disable max-len */
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

type deviceReducer = {
  logicBuilderData: any;
}

const initialState: deviceReducer = {
  logicBuilderData: [],
};

export const devicesAdapter = createEntityAdapter();

export const devicesSelectors = devicesAdapter.getSelectors((state: RootState) => state.devicesData);
const devicesSlice = createSlice({
  name: 'devicesData',
  initialState: devicesAdapter.getInitialState(initialState),
  reducers: {
    setDevices: devicesAdapter.setAll,
    updateDevice: devicesAdapter.updateOne,
    addLogicBuilderData: (state, action) => {
      state.logicBuilderData = [
        ...state.logicBuilderData,
        ...action.payload,
      ];
    },
  },
});

export const { setDevices, updateDevice, addLogicBuilderData } = devicesSlice.actions;

export default devicesSlice;
