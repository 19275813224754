import { createSlice } from '@reduxjs/toolkit';
import {
  API_FAILURE_STATUS, API_PENDING_STATUS, API_SUCCESS_STATUS, PAYMENT_STATUS_FAILURE, PAYMENT_STATUS_PENDING, PAYMENT_STATUS_SUCCESS,
  // PAYMENT_STATUS_SUCCESS,
} from '../../utils/constants';
import { settingsReducer } from '../../utils/types/reducerStateTypes';
import {
  deleteUserAsAdmin,
  editCard,
  getCardDetails,
  getInvoicesHistory,
  getUserDetails,
  getUserInvites,
  getUsers,
  reportABug,
  retryPayment,
} from '../thunks/settingsThunk';

const initialState: settingsReducer = {
  menuIndex: 0,
  isLoading: false,
  isFaliure: false,
  userData: {},
  usersData: {},
  userInvites: {},
  usersDataAPILoadingStatus: false,
  usersDataAPIExecutionStatus: API_PENDING_STATUS,
  reportAPILoadingStatus: false,
  reportAPIExecutionStatus: API_PENDING_STATUS,
  reportAPIFailureMessage: '',
  cardDetails: {},
  invovicesHistory: [],
  paymentStatus: PAYMENT_STATUS_PENDING,
  clientSecret: '',
  userDeleteAPILoadingStatus: false,
  userDeleteAPIExecutionStatus: API_PENDING_STATUS,
  userDeleteAPIFailureMessage: '',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetReportData: state => {
      state.reportAPILoadingStatus = false;
      state.reportAPIExecutionStatus = API_PENDING_STATUS;
      state.reportAPIFailureMessage = '';
    },
    resetCardDetails: (state) => {
      state.cardDetails = {};
    },
    updateCardDetails: (state, action) => {
      state.cardDetails = action.payload;
    },
    setMenuIndex: (state, action) => {
      state.menuIndex = action.payload;
    },
    resetUserDeleteAPIStatus: state => {
      state.userDeleteAPILoadingStatus = false;
      state.userDeleteAPIExecutionStatus = API_PENDING_STATUS;
      state.userDeleteAPIFailureMessage = '';
    },
  },
  extraReducers: {
    [getUserDetails.pending.type]: state => {
      state.isLoading = true;
    },
    [getUserDetails.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.userData = action.payload;
    },
    [getUserDetails.rejected.type]: state => {
      state.isLoading = false;
      state.isFaliure = true;
    },

    [getUsers.pending.type]: state => {
      state.isLoading = true;
      state.usersDataAPILoadingStatus = true;
    },
    [getUsers.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.usersDataAPILoadingStatus = false;
      state.usersDataAPIExecutionStatus = API_SUCCESS_STATUS;
      state.usersData = action.payload;
    },
    [getUsers.rejected.type]: state => {
      state.isLoading = false;
      state.isFaliure = true;
      state.usersDataAPILoadingStatus = false;
      state.usersDataAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [getUserInvites.pending.type]: state => {
      state.isLoading = true;
      state.usersDataAPILoadingStatus = true;
    },
    [getUserInvites.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.usersDataAPILoadingStatus = false;
      state.usersDataAPIExecutionStatus = API_SUCCESS_STATUS;
      state.userInvites = action.payload;
    },
    [getUserInvites.rejected.type]: state => {
      state.isLoading = false;
      state.isFaliure = true;
      state.usersDataAPILoadingStatus = false;
      state.usersDataAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [reportABug.pending.type]: state => {
      state.reportAPILoadingStatus = true;
    },
    [reportABug.fulfilled.type]: state => {
      state.reportAPILoadingStatus = false;
      state.reportAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [reportABug.rejected.type]: (state, action) => {
      state.reportAPILoadingStatus = false;
      state.reportAPIExecutionStatus = API_FAILURE_STATUS;
      state.reportAPIFailureMessage = action.error.message;
    },

    [getCardDetails.fulfilled.type]: (state, action) => {
      state.cardDetails = action.payload;
    },
    [getCardDetails.rejected.type]: state => {
      state.isFaliure = true;
    },

    [getInvoicesHistory.fulfilled.type]: (state, action) => {
      state.invovicesHistory = action.payload.data;
    },
    [getInvoicesHistory.rejected.type]: state => {
      state.isFaliure = true;
    },
    [retryPayment.fulfilled.type]: (state) => {
      state.paymentStatus = PAYMENT_STATUS_SUCCESS;
    },
    [retryPayment.rejected.type]: state => {
      state.isFaliure = true;
      state.paymentStatus = PAYMENT_STATUS_FAILURE;
    },

    [editCard.fulfilled.type]: (state, action) => {
      state.clientSecret = action.payload.client_secret;
    },
    [editCard.rejected.type]: state => {
      state.isFaliure = true;
    },

    [deleteUserAsAdmin.pending.type]: state => {
      state.userDeleteAPILoadingStatus = true;
      state.userDeleteAPIExecutionStatus = API_PENDING_STATUS;
      state.userDeleteAPIFailureMessage = '';
    },
    [deleteUserAsAdmin.fulfilled.type]: state => {
      state.userDeleteAPILoadingStatus = false;
      state.userDeleteAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [deleteUserAsAdmin.rejected.type]: (state, action) => {
      state.userDeleteAPILoadingStatus = false;
      state.userDeleteAPIExecutionStatus = API_FAILURE_STATUS;
      state.userDeleteAPIFailureMessage = action.error.message;
    },
  },
});

export const { resetReportData, resetCardDetails, updateCardDetails, setMenuIndex, resetUserDeleteAPIStatus } = settingsSlice.actions;

export default settingsSlice;
