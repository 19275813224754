export const userLogin = '/users/login/';
export const userSignup = '/users/register/';
export const getProjects = '/project/api/';
export const sampleProject = '/project/sample/';
export const logout = '/accounts/logout/';
export const subscriptionPlans = 'https://run.mocky.io/v3/9f8ccffd-7ea1-4715-8237-59da3d4e1b3c';
export const requestPayment = 'http://localhost:4242/create-payment-intent';
export const verifySesssion = '/accounts/verifysession/';
export const downloadJsonData = '/project/info';
export const analytics = '/features/analysis/';
export const mapapi = '/features/map/';
export const floors = '/project/floor/';
export const userDetails = '/accounts/update-profile/';
export const passwordUpdate = '/accounts/update-password/';
export const users = '/accounts/companymembers/';
export const userInvites = '/accounts/admininvites/list';
export const addAreaToProject = '/project/warehouse/';
export const addConectedDevice = '/connected_device/node/';
export const deleteConnectedDevice = '/connected_device/node/';
export const updateConnectedDevice = '/connected_device/node/';
export const getAllInfo = '/accounts/companyinfo/';
export const getDeviceList = '/device/';
export const termsAndCondtions = '/api/terms_and_conditions/';
export const tourInfo = '/accounts/tour/';
export const reportBug = '/report_bug/';
export const inventory = 'api/save_company_data/?type=inventory&page=1';
export const startAnalysis = 'features/startsanalysis';
export const getStripClientSecret = '/api/stripe_subscription/';
export const resetInflux = '/accounts/reset/influx/';
export const makeQuery = '/features/query/influxdb/';
export const sendInvite = '/accounts/sendinvites/';
export const sampledata = '/api/sample-data';
export const cardDetails = '/accounts/company/card-details';
export const stripeVerifyCoupon = 'api/stripe_verify_coupon/';
export const stripeAddCoupon = 'api/stripe_add_coupon/';
export const currentState = '/accounts/state/';
export const selfDelete = 'users/self/delete/';
export const resetPassowrd = '/accounts/request-new-password/';
export const invovicesHistory = 'api/stripe_invoices/';
export const retryPaymentApi = 'api/stripe_payment/';
export const editCardApi = 'api/stripe_setup_intent/';
export const updatePaymentMethodStatus = 'api/payment_method_status/';
export const trainModelApi = '/features/train/model/';
export const aiData = '/api/ai-data/';
export const dashboardUrls = '/project/dashboard/urls/';
export const subscriptionPriceDetails = 'api/stripe_product_price/';
export const getSubscriptionPlan = '/api/stripe_subscription/';
export const getConnectors = '/api/get_connectors/';
export const connectorConfig = '/api/company_integration_config/';
export const companyIntegrations = '/api/get_company_integrations/';
export const wmsInventory = '/api/get_wms_inventory/';
export const wmsOrder = '/api/get_wms_order/';
export const optimize = '/features/inventory/optimize/';
export const submitMap = '/features/submit-map/';
export const verificationEmail = '/users/resend/verification/';
export const grafanaLogin = '/daboard/login';
export const grafanaLogout = '/daboard/logout';
export const adminDelete = '/users/admin/delete/';
