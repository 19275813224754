import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { API_FAILURE_STATUS, API_PENDING_STATUS, API_SUCCESS_STATUS } from '../../utils/constants';

import { subscriptionReducer } from '../../utils/types/reducerStateTypes';
import {
  getPlans,
  selectSubcriptionPlan,
  verifyStripeCoupon,
  addStripeCoupon,
  getSubscriptionPriceDetails,
  currentSubcriptionPlan,
} from '../thunks/subscriptionThunk';

const initialState: subscriptionReducer = {
  yearly: false,
  planTypes: {
    plansAPILoadingStatus: false,
    plansAPIFaliureStatus: false,
    data: {},
    currentDetails: {},
  },
  subscriptionAPILoadingStatus: false,
  subscriptionAPIExecutionStatus: API_PENDING_STATUS,
  subscriptionAPIFailureMessage: '',
  clientSubcriptionData: {},
  verifyCouponAPILoadingStatus: false,
  verifyCouponAPIExecutionStatus: API_PENDING_STATUS,
  verifyCouponAPIFailureMessage: '',
  verifiedData: {},
  addCouponAPILoadingStatus: false,
  addCouponAPIExecutionStatus: API_PENDING_STATUS,
  addCouponAPIFailureMessage: '',
  priceDetails: [],
  upgradeSubAPILoadingStatus: false,
  upgradeSubAPIExecutionStatus: API_PENDING_STATUS,
  upgradeSubAPIFailureMessage: '',
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    togglePlan: (state, action) => {
      state.yearly = action.payload;
    },
    resetSubscriptionPlan: (state) => {
      state.upgradeSubAPILoadingStatus = false;
      state.upgradeSubAPIExecutionStatus = API_PENDING_STATUS;
      state.upgradeSubAPIFailureMessage = '';
    },
    resetCouponData: (state) => {
      state.verifyCouponAPIExecutionStatus = API_PENDING_STATUS;
      state.verifyCouponAPILoadingStatus = false;
      state.verifyCouponAPIFailureMessage = '';
      state.verifiedData = {};
      state.addCouponAPILoadingStatus = false;
      state.addCouponAPIExecutionStatus = API_PENDING_STATUS;
      state.addCouponAPIFailureMessage = '';
    },
  },
  extraReducers: {
    [getPlans.pending.type]: (state) => {
      state.planTypes.plansAPILoadingStatus = true;
    },
    [getPlans.fulfilled.type]: (state, action) => {
      state.planTypes.data = action.payload;
      state.planTypes.plansAPILoadingStatus = false;
    },
    [getPlans.rejected.type]: (state) => {
      state.planTypes.plansAPILoadingStatus = false;
      state.planTypes.plansAPIFaliureStatus = true;
    },
    [selectSubcriptionPlan.pending.type]: (state) => {
      state.upgradeSubAPILoadingStatus = true;
    },
    [selectSubcriptionPlan.fulfilled.type]: (state, action) => {
      state.clientSubcriptionData = action.payload;
      state.upgradeSubAPIExecutionStatus = API_SUCCESS_STATUS;
      state.upgradeSubAPILoadingStatus = false;
    },
    [selectSubcriptionPlan.rejected.type]: (state, action) => {
      state.upgradeSubAPILoadingStatus = false;
      state.upgradeSubAPIExecutionStatus = API_FAILURE_STATUS;
      state.upgradeSubAPIFailureMessage = action.error.message;
    },
    [getSubscriptionPriceDetails.pending.type]: (state) => {
      state.subscriptionAPILoadingStatus = true;
    },
    [getSubscriptionPriceDetails.fulfilled.type]: (state, action) => {
      state.priceDetails = action.payload.message;
      state.subscriptionAPILoadingStatus = false;
    },
    [getSubscriptionPriceDetails.rejected.type]: (state, action) => {
      state.subscriptionAPILoadingStatus = false;
      state.subscriptionAPIFailureMessage = action.error.message;
    },
    [currentSubcriptionPlan.pending.type]: (state) => {
      state.planTypes.plansAPILoadingStatus = true;
    },
    [currentSubcriptionPlan.fulfilled.type]: (state, action) => {
      state.planTypes.currentDetails = action.payload.subscription;
      state.planTypes.plansAPILoadingStatus = false;
    },
    [currentSubcriptionPlan.rejected.type]: (state) => {
      state.planTypes.plansAPILoadingStatus = false;
      state.planTypes.plansAPIFaliureStatus = true;
    },
    [verifyStripeCoupon.pending.type]: (state) => {
      state.verifyCouponAPILoadingStatus = true;
    },
    [verifyStripeCoupon.fulfilled.type]: (state, action) => {
      state.verifyCouponAPILoadingStatus = false;
      state.verifiedData = action.payload;
      state.verifyCouponAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [verifyStripeCoupon.rejected.type]: (state, action) => {
      state.verifyCouponAPILoadingStatus = false;
      state.verifyCouponAPIFailureMessage = action.error.message;
      state.verifyCouponAPIExecutionStatus = API_FAILURE_STATUS;
    },
    [addStripeCoupon.pending.type]: (state) => {
      state.addCouponAPILoadingStatus = true;
    },
    [addStripeCoupon.fulfilled.type]: (state) => {
      state.addCouponAPIExecutionStatus = API_SUCCESS_STATUS;
      state.addCouponAPILoadingStatus = false;
    },
    [addStripeCoupon.rejected.type]: (state, action) => {
      state.addCouponAPILoadingStatus = false;
      state.addCouponAPIExecutionStatus = API_FAILURE_STATUS;
      state.addCouponAPIFailureMessage = action.error.message;
    },
  },
});

export const { togglePlan, resetSubscriptionPlan, resetCouponData } = subscriptionSlice.actions;

const subscriptionPersistConfig = {
  key: 'device',
  storage,
  whitelist: ['clientSubcriptionData'],
};

export default persistReducer(subscriptionPersistConfig, subscriptionSlice.reducer);
