import mixpanel from 'mixpanel-browser';

const actions = {
  identify: (id: any) => {
    mixpanel.identify(id);
  },
  alias: (id: any) => {
    mixpanel.alias(id);
  },
  track: (name: string) => {
    mixpanel.track(name);
  },
  people: {
    set: (props: any) => {
      mixpanel.people.set(props);
    },
  },
};

export const initiMixPanel = (token: string) => {
  const isToken = token && token !== '';
  if (isToken) {
    mixpanel.init(token, { debug: true });
  }
};

export const Mixpanel = actions;
