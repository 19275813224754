/* eslint-disable max-len */
import React from 'react';

const Welcome = () => (
  <svg width="151" height="167" viewBox="0 0 151 167" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.5662 133.276L46.9688 164.055" stroke="#BCBCBC" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M56.5664 133.276L66.1638 164.055" stroke="#BCBCBC" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M56.5663 137.146C66.0026 137.146 73.6522 135.413 73.6522 133.276C73.6522 131.139 66.0026 129.406 56.5663 129.406C47.1301 129.406 39.4805 131.139 39.4805 133.276C39.4805 135.413 47.1301 137.146 56.5663 137.146Z" fill="#044825" />
    <path d="M50.1191 153.948L60.8184 146.919" stroke="#BCBCBC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M52.2695 146.919L62.9716 153.948" stroke="#BCBCBC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M73.3804 99.9597C73.3371 101.325 73.356 106.104 73.1367 108.149" stroke="#1F161E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M18.4138 39.5131C18.4138 38.5433 17.6465 37.7571 16.7001 37.7571C15.7536 37.7571 14.9863 38.5433 14.9863 39.5131V45.5744C14.9863 46.5442 15.7536 47.3303 16.7001 47.3303C17.6465 47.3303 18.4138 46.5442 18.4138 45.5744V39.5131Z" fill="black" />
    <path d="M16.7 57.5195C18.5989 57.5195 20.1383 55.9422 20.1383 53.9964C20.1383 52.0507 18.5989 50.4734 16.7 50.4734C14.8011 50.4734 13.2617 52.0507 13.2617 53.9964C13.2617 55.9422 14.8011 57.5195 16.7 57.5195Z" fill="#FBDC8E" />
    <path d="M131.648 95.8375H133.272C133.443 95.8368 133.606 95.767 133.727 95.6433C133.847 95.5197 133.916 95.3522 133.916 95.1773V84.9466C133.916 84.8599 133.9 84.774 133.867 84.694C133.835 84.614 133.787 84.5414 133.727 84.4805L131.675 82.3778C131.615 82.3163 131.544 82.2675 131.466 82.2342C131.388 82.2008 131.304 82.1837 131.22 82.1836H125.158C124.987 82.1843 124.824 82.2541 124.703 82.3778C124.582 82.5014 124.514 82.6689 124.514 82.8438V83.8314" fill="#05B7E2" />
    <path d="M128.918 83.8313H122.962C122.866 83.8313 122.772 83.8506 122.684 83.8882C122.595 83.9257 122.515 83.9807 122.448 84.05C122.38 84.1194 122.327 84.2016 122.291 84.2922C122.254 84.3827 122.236 84.4797 122.236 84.5775V96.7279C122.236 96.9253 122.313 97.1147 122.449 97.2546C122.585 97.3944 122.769 97.4734 122.962 97.4741H130.919C131.111 97.4734 131.296 97.3944 131.432 97.2546C131.568 97.1147 131.644 96.9253 131.644 96.7279V86.6303C131.645 86.5326 131.626 86.4357 131.59 86.3452C131.554 86.2548 131.5 86.1726 131.433 86.1033L129.43 84.0505C129.294 83.9109 129.11 83.832 128.918 83.8313Z" fill="#FDFDFD" />
    <path d="M131.647 86.339H129.625C129.517 86.339 129.414 86.2952 129.338 86.2172C129.262 86.1391 129.219 86.0333 129.219 85.9229V83.8313" fill="#969696" />
    <path d="M133.925 84.6941H131.903C131.795 84.6934 131.691 84.649 131.615 84.5706C131.538 84.4922 131.495 84.3861 131.494 84.2752V82.1836" fill="white" />
    <path d="M122.246 69.9419H108.287C107.834 69.9419 107.467 70.3182 107.467 70.7824V75.4539C107.467 75.9181 107.834 76.2945 108.287 76.2945H122.246C122.699 76.2945 123.066 75.9181 123.066 75.4539V70.7824C123.066 70.3182 122.699 69.9419 122.246 69.9419Z" fill="#05B7E2" />
    <path d="M115.264 80.6497L117.142 74.8186H113.385L115.264 80.6497Z" fill="#05B7E2" />
    <path d="M111.54 71.6338L109.965 74.4273H113.113L111.54 71.6338Z" fill="white" />
    <path d="M118.988 74.4273L120.563 71.6338H117.412L118.988 74.4273Z" fill="white" />
    <path d="M115.263 74.2441C115.917 74.2441 116.446 73.7014 116.446 73.0318C116.446 72.3623 115.917 71.8196 115.263 71.8196C114.61 71.8196 114.08 72.3623 114.08 73.0318C114.08 73.7014 114.61 74.2441 115.263 74.2441Z" fill="white" />
    <path d="M125.472 85.0852H123.555C123.415 85.0852 123.301 85.2021 123.301 85.3464C123.301 85.4906 123.415 85.6076 123.555 85.6076H125.472C125.613 85.6076 125.727 85.4906 125.727 85.3464C125.727 85.2021 125.613 85.0852 125.472 85.0852Z" fill="#969696" />
    <path d="M125.472 86.3394H123.555C123.415 86.3394 123.301 86.4563 123.301 86.6006C123.301 86.7448 123.415 86.8618 123.555 86.8618H125.472C125.613 86.8618 125.727 86.7448 125.727 86.6006C125.727 86.4563 125.613 86.3394 125.472 86.3394Z" fill="#969696" />
    <path d="M130.442 88.4834H123.444C123.303 88.4834 123.189 88.6003 123.189 88.7446C123.189 88.8888 123.303 89.0058 123.444 89.0058H130.442C130.583 89.0058 130.697 88.8888 130.697 88.7446C130.697 88.6003 130.583 88.4834 130.442 88.4834Z" fill="#969696" />
    <path d="M130.442 89.7151H123.444C123.303 89.7151 123.189 89.832 123.189 89.9763C123.189 90.1205 123.303 90.2375 123.444 90.2375H130.442C130.583 90.2375 130.697 90.1205 130.697 89.9763C130.697 89.832 130.583 89.7151 130.442 89.7151Z" fill="#969696" />
    <path d="M130.442 90.947H123.444C123.303 90.947 123.189 91.064 123.189 91.2082C123.189 91.3525 123.303 91.4694 123.444 91.4694H130.442C130.583 91.4694 130.697 91.3525 130.697 91.2082C130.697 91.064 130.583 90.947 130.442 90.947Z" fill="#969696" />
    <path d="M95.4457 62.6348H85.5613C83.767 62.6348 82.3125 64.1252 82.3125 65.9636V70.9042C82.3125 72.7427 83.767 74.2331 85.5613 74.2331H95.4457C97.2399 74.2331 98.6945 72.7427 98.6945 70.9042V65.9636C98.6945 64.1252 97.2399 62.6348 95.4457 62.6348Z" fill="#D8D8D8" />
    <path d="M88.1648 64.5017H85.7012V72.3662H88.1648V64.5017Z" fill="#FEFDFF" />
    <path d="M91.7371 69.7336H89.2734V72.369H91.7371V69.7336Z" fill="#FEFDFF" />
    <path d="M95.3055 67.5144H92.8418V72.3662H95.3055V67.5144Z" fill="#FEFDFF" />
    <path d="M16.701 43.3745C15.3395 43.3741 13.9912 43.6486 12.7332 44.1823C11.4752 44.7159 10.3321 45.4982 9.3692 46.4846C8.40632 47.471 7.6425 48.642 7.12137 49.9309C6.60025 51.2198 6.33203 52.6013 6.33203 53.9964H27.07C27.07 52.6013 26.8018 51.2198 26.2807 49.9309C25.7595 48.642 24.9957 47.471 24.0328 46.4846C23.07 45.4982 21.9269 44.7159 20.6689 44.1823C19.4108 43.6486 18.0626 43.3741 16.701 43.3745Z" fill="#036C86" />
    <path d="M16.7012 39.7794V1.24219" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M98.028 160.384C98.028 161.042 97.6517 165.189 98.4016 165.572C99.1515 165.955 107.395 166.188 107.769 165.802C108.143 165.416 108.367 164.612 108.04 164.382C107.712 164.151 104.52 163.5 104.087 163.192C103.654 162.884 103.055 161.25 103.275 160.384" fill="#1F161E" />
    <path d="M73.3804 106.171L78.0207 104.465C77.8203 95.7904 75.8413 76.4081 73.0311 73.5258C72.3705 72.8517 59.305 71.6755 56.974 71.6755C43.0639 71.6755 38.9379 73.3011 37.8902 74.3775C34.6414 77.7063 33.6289 93.1301 33.6289 98.5312C33.6289 103.932 48.5191 106.734 48.5191 106.734L65.9732 107.963C65.9732 107.963 69.8013 108.368 73.1123 108.568C73.1854 107.527 73.3804 106.171 73.3804 106.171Z" fill="#F3F3F3" />
    <path d="M87.207 111.2C88.4768 110.814 101.104 108.703 103.854 111.477C106.605 114.251 115.263 154.023 115.263 154.939H108.292L98.881 134.186" fill="#0186A6" />
    <path d="M41.2337 107.663C40.9169 109.286 39.0137 111.028 39.0137 119.869C39.0137 132.094 48.7871 132.352 52.2957 132.352C63.2279 132.352 80.9147 129.024 82.1087 128.413C82.9019 129.773 97.5024 160.315 97.5701 160.997H104.292C104.292 160.997 100.126 115.303 92.6076 110.121C90.1656 108.457 46.8405 106.676 41.2337 107.663Z" fill="#05B7E2" />
    <path d="M80.598 98.7253C78.8329 98.7253 77.3763 99.1553 73.3803 99.9598L73.1719 107.794C76.9621 106.229 77.842 104.054 78.9412 104.054C81.6864 104.054 83.1213 105.505 84.743 105.505C85.8746 105.505 87.9105 104.595 87.9105 103.358C87.9105 100.767 82.7856 98.7253 80.598 98.7253Z" fill="#F8CDBA" />
    <path d="M108.686 154.389C108.686 155.05 108.31 159.194 109.06 159.58C109.81 159.965 118.054 160.193 118.427 159.81C118.801 159.427 119.025 158.62 118.698 158.39C118.37 158.159 115.178 157.505 114.745 157.197C114.312 156.889 113.714 155.255 113.933 154.389" fill="#1F161E" />
    <path d="M59.436 73.5202C59.436 74.566 53.5098 74.5965 53.5098 73.5202V65.0149H59.436V73.5202Z" fill="#F8CDBA" />
    <path d="M57.5057 67.8195C56.9643 67.8195 53.5098 69.3841 53.5098 70.2579V65.6418C54.3707 65.6418 57.5057 67.8195 57.5057 67.8195Z" fill="#1F161E" />
    <path d="M58.6509 68.1803C62.6132 68.1803 65.8253 64.889 65.8253 60.829C65.8253 56.7691 62.6132 53.4778 58.6509 53.4778C54.6886 53.4778 51.4766 56.7691 51.4766 60.829C51.4766 64.889 54.6886 68.1803 58.6509 68.1803Z" fill="#F8CDBA" />
    <path d="M66.0554 55.0589C65.1674 56.5042 63.3101 57.1672 61.2986 57.1672C59.5849 57.1672 57.0887 56.6568 55.3127 57.6943C54.3516 58.2491 53.2498 59.5113 53.0278 62.0384C53.0278 62.4712 52.5486 64.1495 52.5486 64.6904C52.5486 65.0594 52.1425 65.3007 51.4656 65.3007C51.1164 65.3007 50.426 64.7209 50.426 63.836C50.426 62.8124 50.824 62.449 50.824 61.5807C50.824 60.7124 49.7871 59.8774 49.7871 57.464C49.7871 53.9881 51.953 51.8465 53.4853 51.8465C54.8823 51.8465 55.3209 52.1794 55.4968 52.3209C55.4968 49.1613 59.5578 47.7188 61.1687 47.7188C63.0096 47.7188 63.876 48.4927 63.876 49.4775C63.876 51.0476 63.145 52.3819 62.7741 52.6815C63.6136 51.9077 64.7041 51.4815 65.8334 51.4859C67.9424 51.4859 68.6517 52.9062 68.6517 53.8772C68.6517 55.9882 66.1799 57.9439 65.2594 57.9439C65.0278 57.4419 64.7562 56.9604 64.4472 56.5042" fill="#1F161E" />
    <path d="M53.2503 63.3674C54.0951 63.3674 54.78 62.6656 54.78 61.8C54.78 60.9344 54.0951 60.2327 53.2503 60.2327C52.4055 60.2327 51.7207 60.9344 51.7207 61.8C51.7207 62.6656 52.4055 63.3674 53.2503 63.3674Z" fill="#F8CDBA" />
    <path d="M65.8948 62.1771C66.5497 62.1771 67.0806 61.8939 67.0806 61.5446C67.0806 61.1953 66.5497 60.9121 65.8948 60.9121C65.2399 60.9121 64.709 61.1953 64.709 61.5446C64.709 61.8939 65.2399 62.1771 65.8948 62.1771Z" fill="#FA9E9F" />
    <path d="M58.4358 62.1771C59.0907 62.1771 59.6216 61.8939 59.6216 61.5446C59.6216 61.1953 59.0907 60.9121 58.4358 60.9121C57.7809 60.9121 57.25 61.1953 57.25 61.5446C57.25 61.8939 57.7809 62.1771 58.4358 62.1771Z" fill="#FA9E9F" />
    <path d="M62.998 60.2327C63.22 60.5572 64.3517 61.9193 63.1578 62.4714" stroke="#1F161E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M60.7669 64.0607C60.4096 64.0607 59.86 63.6058 59.5703 63.198" stroke="#1F161E" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M33.6289 97.6157C33.6289 110.401 33.7453 114.11 38.7538 114.11C50.2978 114.11 67.7491 104.401 70.8598 104.401C73.6078 104.401 75.0426 105.855 76.6643 105.855C77.7933 105.855 79.8292 104.942 79.8292 103.708C79.8292 101.114 74.7042 99.0721 72.5194 99.0721C67.6002 99.0721 47.9695 103.932 47.4903 103.932C47.0111 103.932 46.2044 103.719 46.2044 102.892C46.2044 101.391 47.1627 97.9236 47.2873 97.6213L33.6289 97.6157Z" fill="#F8CDBA" />
    <path d="M147.916 164.286C148.11 165.045 148.868 165.498 149.609 165.299C150.349 165.099 150.791 164.323 150.597 163.564L136.736 109.541C136.541 108.783 135.783 108.33 135.043 108.529C134.302 108.728 133.86 109.505 134.054 110.264L147.916 164.286Z" fill="#969696" />
    <path d="M133.917 110.266C134.111 109.507 133.669 108.731 132.928 108.531C132.188 108.332 131.43 108.785 131.235 109.544L117.374 163.566C117.18 164.325 117.622 165.102 118.362 165.301C119.103 165.501 119.861 165.047 120.056 164.289L133.917 110.266Z" fill="#969696" />
    <path d="M114.79 110.266C114.984 109.507 114.542 108.731 113.802 108.531C113.061 108.332 112.303 108.785 112.108 109.544L98.2474 163.566C98.0527 164.325 98.4951 165.102 99.2355 165.301C99.9759 165.5 100.734 165.047 100.929 164.288L114.79 110.266Z" fill="#D8D8D8" />
    <path d="M128.548 163.289C128.74 164.05 129.497 164.508 130.239 164.312C130.982 164.116 131.429 163.34 131.237 162.579L117.609 108.397C117.417 107.636 116.66 107.178 115.918 107.374C115.175 107.57 114.728 108.346 114.92 109.107L128.548 163.289Z" fill="#D8D8D8" />
    <path d="M36.7057 110.267C36.9003 109.509 36.4579 108.732 35.7175 108.533C34.9772 108.333 34.2192 108.786 34.0245 109.545L20.1634 163.568C19.9688 164.326 20.4112 165.103 21.1516 165.302C21.892 165.502 22.65 165.049 22.8446 164.29L36.7057 110.267Z" fill="#969696" />
    <path d="M50.4683 164.29C50.663 165.049 51.421 165.502 52.1614 165.303C52.9018 165.103 53.3442 164.326 53.1495 163.568L39.2884 109.545C39.0938 108.786 38.3358 108.333 37.5954 108.533C36.855 108.732 36.4126 109.509 36.6073 110.267L50.4683 164.29Z" fill="#969696" />
    <path d="M142.45 105.863H8.39243C7.7151 105.863 7.16602 106.426 7.16602 107.12V108.809C7.16602 109.503 7.7151 110.066 8.39243 110.066H142.45C143.128 110.066 143.677 109.503 143.677 108.809V107.12C143.677 106.426 143.128 105.863 142.45 105.863Z" fill="#D8D8D8" />
    <path d="M16.8951 110.268C17.0898 109.509 16.6474 108.733 15.907 108.533C15.1666 108.334 14.4086 108.787 14.214 109.546L0.352891 163.568C0.158241 164.327 0.600646 165.104 1.34103 165.303C2.08142 165.503 2.83941 165.049 3.03406 164.291L16.8951 110.268Z" fill="#D8D8D8" />
    <path d="M30.6578 164.29C30.8524 165.049 31.6104 165.502 32.3508 165.303C33.0912 165.103 33.5336 164.326 33.339 163.568L19.4779 109.545C19.2832 108.786 18.5252 108.333 17.7849 108.533C17.0445 108.732 16.6021 109.509 16.7967 110.267L30.6578 164.29Z" fill="#D8D8D8" />
    <path d="M107.528 105.863H82.4824L87.0199 89.8732C87.1332 89.4874 87.3653 89.1495 87.6815 88.91C87.9977 88.6706 88.381 88.5423 88.7742 88.5444H110.027C110.306 88.5398 110.582 88.6021 110.834 88.7263C111.085 88.8505 111.305 89.0332 111.475 89.2598C111.646 89.4864 111.762 89.7506 111.815 90.0315C111.868 90.3123 111.856 90.602 111.781 90.8774L107.528 105.863Z" fill="#7B7B7B" />
    <path d="M98.0792 98.7723C98.9255 98.7723 99.6115 98.0694 99.6115 97.2022C99.6115 96.3351 98.9255 95.6321 98.0792 95.6321C97.2329 95.6321 96.5469 96.3351 96.5469 97.2022C96.5469 98.0694 97.2329 98.7723 98.0792 98.7723Z" fill="white" />
    <path d="M85.4579 104.74H67.8848V105.863H85.4579V104.74Z" fill="#7B7B7B" />
  </svg>
);

export default Welcome;
