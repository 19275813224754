import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { useDispatch as useDispatchRedux } from 'react-redux';
import { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import logger from 'redux-logger';

import rootReducer from './rootReducer';
import authTokenMiddleware from './middleware/authMiddleware';

const createStore = (showLogger: any) =>
  configureStore({
    reducer: rootReducer,
    devTools: false,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(authTokenMiddleware);
      if (showLogger) {
        middleware.push(logger);
      }
      return middleware;
    },
  });

export const store = createStore(process.env.REACT_APP_SERVER_URL?.includes('dev') || process.env.REACT_APP_PROD !== 'true');

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatchRedux<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export type ThunkDispatch = ReduxThunkDispatch<RootState, undefined, AnyAction>;
export const useDispatch: () => ThunkDispatch = useDispatchRedux;
