import { Autocomplete } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../..';

type propsTypes = {
  onSelect: (value: any) => void;
  defaultValue: any;
  disabled?: boolean;
};

const CityPicker = ({ onSelect, defaultValue, disabled }: propsTypes) => {
  const googleWindow = window.google;
  const service = new googleWindow.maps.places.AutocompleteService();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState('');
  const [value, setValue] = React.useState<any>(null);
  const [options, setOptions] = React.useState<any[]>([]);

  const displaySuggestions = (predictions: any, status: any) => {
    if (
      status !== googleWindow.maps.places.PlacesServiceStatus.OK ||
      !predictions
    ) {
      return;
    }

    const tempOptions = predictions.filter(
      (prediction: any) =>
        prediction.types.includes('locality') ||
        prediction.structured_formatting.secondary_text === undefined,
    );
    setOptions(tempOptions);
  };

  useEffect(() => {
    setOptions(defaultValue ? [defaultValue] : []);
    setValue(defaultValue || null);
    setInputValue(defaultValue ? defaultValue.description : '');
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (inputValue !== '') {
        service.getPlacePredictions({ input: inputValue }, displaySuggestions);
      } else {
        setOptions([]);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [inputValue, value]);

  const handleValueChange = (event: any, newValue: any) => {
    setValue(newValue);
    onSelect(newValue);
  };

  return (
    <Autocomplete
      fullWidth
      disablePortal
      autoHighlight
      options={options}
      value={value}
      inputValue={inputValue}
      renderInput={params => <TextField {...params} label={t('sidebar.new_project_city')} placeholder={t('sidebar.new_project_city')} required={!disabled} />}
      getOptionLabel={(option: any) => (option ? option.description : '')}
      renderOption={(props, option: any) => <li {...props}>{option.description}</li>}
      onChange={handleValueChange}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      className="not-last"
      disabled={disabled}
    />
  );
};

export default CityPicker;
