import React from 'react';
import { Slider as MuiSlider, SliderProps } from '@mui/material';

const Slider = ({
  size,
  defaultValue,
  valueLabelDisplay,
  ...props
}: SliderProps) => (
  <MuiSlider
    size={size}
    defaultValue={defaultValue}
    valueLabelDisplay={valueLabelDisplay}
    {...props}
  />
);

export default Slider;
