import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import {
  subscriptionPlans,
  getStripClientSecret,
  stripeVerifyCoupon,
  stripeAddCoupon,
  subscriptionPriceDetails,
  getSubscriptionPlan,
} from '../../services/apiDefinition';

export const getPlans = createAsyncThunk('subscription/getPlans', async () => {
  try {
    const response = await axiosApiInstance.get(subscriptionPlans);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
});

type getStripClientSecretType = {
  priceId: string;
}

export const selectSubcriptionPlan = createAsyncThunk(
  'subscription/getStripClientSecret',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post<getStripClientSecretType>(
        getStripClientSecret,
        request,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const currentSubcriptionPlan = createAsyncThunk(
  'subscription/getSubscriptionPlan',
  async () => {
    try {
      const response = await axiosApiInstance.get(getSubscriptionPlan);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getSubscriptionPriceDetails = createAsyncThunk(
  'subscription/getPriceDetails',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post(
        subscriptionPriceDetails,
        request,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

type verifyStripeCouponType = {
  promotionCode: string;
  productId: string;
}

export const verifyStripeCoupon = createAsyncThunk(
  'subscription/verifyStripeCoupon',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post<verifyStripeCouponType>(
        stripeVerifyCoupon,
        request,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

type addStripeCouponType = {
  subscriptionId: string;
  promotionCode: string;
}

export const addStripeCoupon = createAsyncThunk(
  'subscription/addStripeCoupon',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post<addStripeCouponType>(
        stripeAddCoupon,
        request,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
