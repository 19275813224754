import React from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { grey } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';

type passwordProps = {
  handleClickShowPassword: () => void,
  showPassword: boolean,
}

const PasswordIcon = ({ handleClickShowPassword, showPassword }: passwordProps) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      edge="end"
    >
      {showPassword ? (
        <VisibilityOff sx={{ color: grey[500] }} />
      ) : (
        <Visibility sx={{ color: grey[500] }} />
      )}
    </IconButton>
  </InputAdornment>
);

export default PasswordIcon;
