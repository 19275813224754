import * as React from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel, OutlinedInput,
  TextField as MuiTextField,
  TextFieldProps,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormikInputProps } from '../../utils/types/inputTypes';

const TextField = ({
  variant = 'outlined',
  label,
  ...props
}: TextFieldProps) => (
  <MuiTextField label={label} variant={variant} {...props} />
);

// usage: https://formik.org/docs/api/field
const FormikInputField = ({
  field, form, label, ...props
}: FormikInputProps) => {
  const error = form.errors[field.name] && form.touched[field.name];
  const errmsg = form.errors[field.name];
  return (
    <TextField
      variant="outlined"
      {...field}
      {...props}
      id={field.name}
      label={label}
      fullWidth
      error={error}
      helperText={errmsg}
    />
  );
};

const VisibilityButton = styled(IconButton)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));
// usage: https://formik.org/docs/api/field
const FormikPasswordInputField = ({
  field, form, label, ...props
}: FormikInputProps) => {
  const [showApiKey, setShowApiKey] = React.useState(false);

  const handleClickShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const handleMouseDownApiKey = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const error = form.errors[field.name] && form.touched[field.name];
  const errmsg = form.errors[field.name];
  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <InputLabel htmlFor={field.name}>{label}</InputLabel>
      <OutlinedInput
        id={field.name}
        type={showApiKey ? 'text' : 'password'}
        fullWidth
        endAdornment={(
          <InputAdornment position="end">
            <VisibilityButton
              aria-label={`toggle ${field.name} visibility`}
              onClick={handleClickShowApiKey}
              onMouseDown={handleMouseDownApiKey}
              edge="end"
            >
              {showApiKey ? <VisibilityOff /> : <Visibility />}
            </VisibilityButton>
          </InputAdornment>
        )}
        label={label}
        error={error}
        {...props}
        {...field}
      />
      {
        error
        && (
        <FormHelperText id="component-helper-text" sx={{ color: error ? 'red' : 'initial' }}>
          {errmsg}
        </FormHelperText>
        )
      }
    </FormControl>
  );
};

export {
  TextField,
  FormikInputField,
  FormikPasswordInputField,
};
