import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { addProject } from '../../store/thunks/projectThunk';
import { AppDispatch, RootState } from '../../store';
import { handleMenuItemClick } from '../../store/slices/menuSlice';
import { useShallowEqualSelector } from '../../utils/helper';
import { handleModal } from '../../store/slices/welcomeSlice';
import { setCreateProject } from '../../store/slices/tourSlice';
import axiosApiInstance from '../../services/axios';
import { tourInfo } from '../../services/apiDefinition';
import { Mixpanel } from '../../utils/mixPanel';
import MixPanelConstants from '../../utils/mixpanelConstants';
import { LOCATIONINDEX, NULL_STRING } from '../../utils/constants';

const useSidebar = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { menuIndex, sidebarState } = useShallowEqualSelector(({ menu }: RootState) => menu);
  const { dashboardUrls, projectAPILoadingStatus, currentProject } = useShallowEqualSelector(({ selectedData }: RootState) => selectedData);
  const { projectAPILoadingStatus: projectLoading } = useShallowEqualSelector(
    ({ crudData }: RootState) => crudData,
  );
  const { openModal, recentProjects, companyData } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { createProject } = useShallowEqualSelector(
    ({ tour }: RootState) => tour,
  );

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    const index = LOCATIONINDEX[path as keyof typeof LOCATIONINDEX];
    if (index) {
      dispatch(handleMenuItemClick(index));
    }
  }, [location]);

  useEffect(() => {
    if (recentProjects.length === 0 || currentProject === '' || currentProject === NULL_STRING) {
      dispatch(handleMenuItemClick(0));
    }
  }, [recentProjects, currentProject]);

  const handleClickMenu = (index: Number, to: string) => {
    dispatch(handleMenuItemClick(index));
    navigate(to);
  };

  const submitProject = (obj: any, sample?: boolean) => {
    Mixpanel.track(MixPanelConstants.projectSave);
    // TODO: Optimize the add project call (redux thunk)
    dispatch(addProject({ obj: obj, dispatch: dispatch, sample: sample }));
  };

  const closeProjectModal = () => {
    if (!projectLoading) {
      dispatch(handleModal(false));
    }
  };
  const openProjectModal = () => {
    Mixpanel.track(MixPanelConstants.createProject);
    dispatch(handleModal(true));
  };

  const projectTourComplete = () => {
    dispatch(setCreateProject());
    axiosApiInstance.put(tourInfo, { tour: { createProject: true } });
  };

  return {
    menuIndex,
    handleClickMenu,
    submitProject,
    createProject,
    closeProjectModal,
    openProjectModal,
    projectTourComplete,
    openModal,
    recentProjects,
    sidebarState,
    dashboardUrls,
    projectAPILoadingStatus,
    companyData,
    projectLoading,
    currentProject,
  };
};

export default useSidebar;
