import React, { useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import Backdrop from '@mui/material/Backdrop';
import { CardActionArea, IconButton, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Close, FileUpload, Save } from '@mui/icons-material';
import Fade from '@mui/material/Fade';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../TextField';
import Button from '../../Button';
import {
  ReportModalContainer,
  ReportBlock,
  ReportInputBlock,
  ActionButtonsBox,
  ReportHeader,
  CustomCard,
  ImagePreview,
} from '../styles';
import useAppbar from '../useAppbar';
import { CardBody } from '../../../pages/projects/styles';

const ReportBugModal = ({ isModalVisible, onCloseModal }:
  { isModalVisible: boolean, onCloseModal: () => void }) => {
  const { t } = useTranslation();
  const hiddenImageInput = useRef<HTMLInputElement | null>(null);
  const {
    reportTitle,
    reportDesc,
    handleReportTitleChange,
    handleReportDescChange,
    submitReport,
    reportTitleError,
    reportDescError,
    resetReportModal,
    reportImageError,
    handleImageChange,
    reportImage,
    setReportImage,
    reportAPILoadingStatus,
  } = useAppbar();

  const handleClick = () => {
    hiddenImageInput.current?.click();
  };
  return (
    <Modal
      open={Boolean(isModalVisible)}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={Boolean(isModalVisible)}>
        <ReportModalContainer>
          <ReportHeader variant="h6">
            {t('reportBug.report_header')}
          </ReportHeader>
          <ReportBlock>
            <ReportInputBlock>
              <TextField
                defaultValue={reportTitle}
                placeholder={t('reportBug.title')}
                variant="outlined"
                fullWidth
                onChange={handleReportTitleChange}
                error={reportTitleError}
                helperText={
                  reportTitleError && t('reportBug.report_title_error')
                }
              />
            </ReportInputBlock>
            <ReportInputBlock>
              <TextField
                defaultValue={reportDesc}
                placeholder={t('reportBug.description')}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                onChange={handleReportDescChange}
                error={reportDescError}
                helperText={reportDescError && t('reportBug.report_desc_error')}
              />
            </ReportInputBlock>
            <ReportInputBlock>
              <Typography variant="subtitle1">
                {t('reportBug.attach_file')}
              </Typography>
              {reportImage === null ? (
                <CustomCard>
                  <CardActionArea onClick={handleClick}>
                    <CardBody
                      avatar={<FileUpload />}
                      title={
                        <Typography variant="subtitle1">
                          {t('reportBug.browse_files')}
                        </Typography>
                      }
                    />
                  </CardActionArea>
                </CustomCard>
              ) : (
                <ImagePreview>
                  <img src={URL.createObjectURL(reportImage)} alt="map" />
                  <Typography variant="caption">{reportImage.name}</Typography>
                  <IconButton onClick={() => setReportImage(null)}>
                    <Close />
                  </IconButton>
                </ImagePreview>
              )}
              <Typography
                variant="caption"
                className={reportImageError ? 'error' : ''}
              >
                {t('reportBug.file_type')}
              </Typography>
              <input
                accept="image/*"
                type="file"
                onChange={handleImageChange}
                hidden
                ref={hiddenImageInput}
              />
            </ReportInputBlock>
            <ActionButtonsBox>
              <Button
                size="medium"
                color="primary"
                variant="contained"
                className="action cancel"
                title={t('reportBug.report_cancel_button_text')}
                disabled={reportAPILoadingStatus}
                onClick={() => {
                  resetReportModal();
                  onCloseModal();
                }}
              />
              {reportAPILoadingStatus ? (
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<Save />}
                  variant="outlined"
                >
                  Wait
                </LoadingButton>
              ) : (
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  className="action"
                  title={t('reportBug.report_button_text')}
                  onClick={() => {
                    submitReport(onCloseModal);
                  }}
                />
              )}
            </ActionButtonsBox>
          </ReportBlock>
        </ReportModalContainer>
      </Fade>
    </Modal>
  );
};

export default ReportBugModal;
