import { createSlice } from '@reduxjs/toolkit';
import { tourReducer } from '../../utils/types/reducerStateTypes';
import { getTour } from '../thunks/tourThunk';

const initialState: tourReducer = {
  greeting: false,
  welcomeTour: false,
  areasTour: false,
  reactFlowTour: false,
  devicesTour: false,
  createProject: false,
  inventory: false,
  dashboard: false,
  orders: false,
  bannerClose: false,
  getStartedComplete: false,
  userSawProgress: false,
};

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    greetingComplete: state => {
      state.greeting = true;
    },
    welcomeComplete: (state, action) => {
      state.welcomeTour = action.payload;
    },
    areasComplete: (state, action) => {
      state.areasTour = action.payload;
    },
    reactFlowComplete: (state, action) => {
      state.reactFlowTour = action.payload;
    },
    devicesComplete: (state, action) => {
      state.devicesTour = action.payload;
    },
    setCreateProject: state => {
      state.createProject = true;
    },
    setInventory: state => {
      state.inventory = true;
    },
    setDashboard: state => {
      state.dashboard = true;
    },
    setOrders: state => {
      state.orders = true;
    },
    setBannerClose: state => {
      state.bannerClose = true;
    },
    setGetstaredComplete: state => {
      state.getStartedComplete = true;
    },
    setUserSawProgress: state => {
      state.userSawProgress = true;
    },
  },
  extraReducers: {
    [getTour.fulfilled.type]: (state, action) => {
      if (action.payload.tour !== null) {
        Object.keys(action.payload.tour).forEach(key => {
          state[key as keyof typeof state] = action.payload.tour[key];
        });
      }
    },
  },
});

export const {
  greetingComplete,
  welcomeComplete,
  areasComplete,
  reactFlowComplete,
  devicesComplete,
  setCreateProject,
  setInventory,
  setDashboard,
  setOrders,
  setBannerClose,
  setGetstaredComplete,
  setUserSawProgress,
} = tourSlice.actions;

export default tourSlice;
