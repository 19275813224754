import React, { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Alert } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Logo } from '../../../assets/images';
import { Button, TextField } from '../../../components';
import { LoginFormValues } from '../../../utils/types/formTypes';
import {
  ForgotButton,
  LoginInnerBlock,
  LoginLeftItemGrid,
  SignUpButton,
  SignUpText,
} from '../styles';
import PasswordIcon from './passwordIcon';
import useLogin from '../useLogin';
import { AppDispatch } from '../../../store';
import { sendVerificationEmail } from '../../../store/thunks/authThunk';

type LoginFormProps = {
  submitLogin: (data: LoginFormValues) => void;
  showPassword: boolean;
  handleClickShowPassword: () => void;
  openSignup: () => void;
};

let autoLogoutMessage: string | null;

const LoginForm = ({
  submitLogin,
  showPassword,
  handleClickShowPassword,
  openSignup,
}: LoginFormProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showResendEmail, setShowResendEmail] = React.useState(false);
  const [emailForResend, setEmailForResend] = React.useState<string | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const { loginAPIFailureMessage, loginAPILoadingStatus } = useLogin();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues>();
  const onSubmit = handleSubmit((data) => {
    setEmailForResend(data.username);
    submitLogin(data);
  });

  useEffect(() => {
    autoLogoutMessage = sessionStorage.getItem('autoLogoutMessage');
  }, []);

  useEffect(() => {
    register('username', { required: true });
    register('password', { required: true });
  }, [register]);

  useEffect(() => {
    if (loginAPIFailureMessage === 'Account not verified. Please verify your account.') {
      setShowResendEmail(true);
    } else {
      setShowResendEmail(false);
    }
  }, [loginAPIFailureMessage]);

  const handleSumitForm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      executeRecaptcha('enquiryFormSubmit').then(() => {
        onSubmit();
      });
    },
    [executeRecaptcha],
  );

  return (
    <LoginLeftItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <LoginInnerBlock>
        <Logo />
        <Box component="form" onSubmit={handleSumitForm} sx={{ mt: 1 }}>
          {autoLogoutMessage && (
            <Alert severity="error">{autoLogoutMessage}</Alert>
          )}
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t('login.email_field_label')}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e =>
              setValue('username', e.target.value.replace(/\s/g, ''))}
            error={!!errors?.username}
            helperText={
              errors?.username?.type === 'required'
                ? t('login.required_field_validation')
                : null
            }
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label={t('login.password_field_label')}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="password"
            onChange={e =>
              setValue('password', e.target.value.replace(/\s/g, ''))}
            InputProps={{
              endAdornment: (
                <PasswordIcon
                  handleClickShowPassword={handleClickShowPassword}
                  showPassword={showPassword}
                />
              ),
            }}
            error={!!errors?.password}
            helperText={
              errors?.password?.type === 'required'
                ? t('login.required_field_validation')
                : null
            }
          />
          {showResendEmail ? (
            <ForgotButton
              title={t('login.resend_verification')}
              onClick={() => {
                if (loginAPILoadingStatus) return;
                dispatch(sendVerificationEmail(emailForResend));
              }}
            >
              {t('login.resend_verification')}
            </ForgotButton>
          ) : (
            <ForgotButton
              title={t('login.forgot_password')}
              onClick={() => {
                if (loginAPILoadingStatus) return;
                navigate('/app/forgot-page');
              }}
            >
              {t('login.forgot_password')}
            </ForgotButton>
          )}
          <Button
            type="submit"
            title={t('login.login_button_text')}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            disabled={loginAPILoadingStatus}
          />
          <SignUpText variant="subtitle1">
            {t('login.sign_up_text')}
            <SignUpButton
              title={t('login.sign_up')}
              variant="text"
              color="primary"
              onClick={openSignup}
            />
          </SignUpText>
        </Box>
      </LoginInnerBlock>
    </LoginLeftItemGrid>
  );
};

export default LoginForm;
