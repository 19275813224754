import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { resetInflux, makeQuery, wmsInventory } from '../../services/apiDefinition';
import { getCurrentProject } from './selectedDataThunk';

type getDataType = {
  start?: string;
  stop?: string;
};
type deleteDataRtpe = {
  start: string;
  stop: string;
  key: string;
  dispatch: any;
};

type wmsInventoryType = {
  dispatch: any;
}

export const getInventoryData = createAsyncThunk(
  'sheet/getInventoryData',
  async ({ start, stop }: getDataType) => {
    try {
      const response = await axiosApiInstance.post(makeQuery, {
        start: start === undefined ? '0' : start,
        stop: stop === undefined ? 'now()' : stop,
        measurement: 'inventory',
      });

      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);

export const resetInventoryInfluxDB = createAsyncThunk(
  'sheet/resetInventoryInfluxDB',
  async ({ start, stop, key, dispatch }: deleteDataRtpe) => {
    try {
      const config = {
        params: {
          start: start,
          stop: stop,
          key: key,
        },
        withCredentials: true,
      };
      const res = await axiosApiInstance.delete(resetInflux, config);
      dispatch(getCurrentProject(dispatch));
      return res;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);

export const getWMSInventory = createAsyncThunk(
  'sheet/getWMSInventory',
  async ({ dispatch }: wmsInventoryType) => {
  // async () => {
    try {
      const response = await axiosApiInstance.get(wmsInventory);
      dispatch(getCurrentProject(dispatch));
      dispatch(getInventoryData({
        start: undefined,
        stop: undefined,
      }));
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
