import React from 'react';
import lottieJson from '../../../assets/images/with_sw.json';
import {
  ImageSubTitle,
  ImageTitle,
  ForgotInnerBlock2,
  ForgotRightItemGrid,
  StyledPlayer,
} from '../styles';

const ForgotImage = ({ t }: { t: any }) => (
  <ForgotRightItemGrid
    item
    justifyContent="center"
    display="flex"
    alignSelf="center"
  >
    <ForgotInnerBlock2>
      <StyledPlayer autoplay loop src={JSON.stringify(lottieJson)} />
      <ImageTitle variant="h4">{t('login.login_cover_title')}</ImageTitle>
      <ImageSubTitle variant="body1">
        {t('login.login_cover_subtitle1')}
      </ImageSubTitle>
      <ImageSubTitle variant="body1">
        {t('login.login_cover_subtitle2')}
      </ImageSubTitle>
    </ForgotInnerBlock2>
  </ForgotRightItemGrid>
);

export default ForgotImage;
