import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { PALLETOPTIONS } from '../../../utils/constants';

type propsTypes = {
  onSelect: (value: any) => void;
  defaultValue: any;
  disabled?: boolean;
};

const PalletPicker = ({ onSelect, defaultValue, disabled }: propsTypes) => {
  const { t } = useTranslation();
  const [pallets, setPallets] = React.useState<string[]>(
    defaultValue === undefined
      ? []
      : Array.isArray(defaultValue)
        ? defaultValue
        : [defaultValue],
  );

  const handleChange = (event: SelectChangeEvent<typeof pallets>) => {
    const {
      target: { value },
    } = event;
    setPallets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onSelect(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl fullWidth className="not-last">
      <InputLabel id="demo-multiple-chip-label">
        {t('sidebar.new_project_pallet')}
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={pallets}
        onChange={handleChange}
        input={
          <OutlinedInput
            id="select-multiple-chip"
            label={t('sidebar.new_project_pallet')}
          />
        }
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip
                key={value}
                label={value}
                color="primary"
                icon={<StackedBarChartIcon />}
              />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {PALLETOPTIONS.map(name => (
          <MenuItem key={name} value={name}>
            <ListItemIcon>
              <StackedBarChartIcon />
            </ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PalletPicker;
