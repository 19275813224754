import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { getProjects, verifySesssion, getAllInfo } from '../../services/apiDefinition';
import { projectType } from '../../utils/types/projectTypes';

type updateProjectType = {
  project: projectType;
  newName: string;
};

export const updateProject = createAsyncThunk(
  'welcome/updateProject',
  async ({ project, newName }: updateProjectType) => {
    try {
      await axiosApiInstance.put(
        getProjects + project.hashed_identifier,
        { project_name: newName },
        {
          withCredentials: true,
        },
      );
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getCompanyData = createAsyncThunk(
  'welcome/getCompanyData',
  async () => {
    try {
      const response = await axiosApiInstance.get(getAllInfo);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const verifyUser = createAsyncThunk('welcome/verifyUser', async () => {
  try {
    const response = await axiosApiInstance.get(verifySesssion, {
      withCredentials: true,
    });
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 599) {
      throw new Error(e.response.data.message);
    } else if (e.response.status >= 500 && e.response.status <= 504) {
      throw new Error(e.response.data.message);
    }
  }
});
