import {
  Box,
  styled,
  BoxProps,
  Button,
  ButtonProps,
  Fab,
  FabProps,
  Paper,
  Typography,
  AccordionSummary,
  Accordion,
  IconButton,
  IconButtonProps,
} from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';

const SidebarContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRight: '1px solid rgba(165, 165, 165, 0.12)',
  '::-webkit-scrollbar': {
    width: '6px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const LogoContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ProjectButton = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '40px 0 32px 0',
}));

const MenuContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const MenuItem = styled(Button)<ButtonProps>(({ theme }) => ({
  justifyContent: 'flex-start',
  height: '52px',
  textTransform: 'capitalize',
  alignItems: 'center',
  color: '#A1A9B1', // todo
  [theme.breakpoints.down('md')]: {
    '&#mapLayoutBtn': {
      display: 'none',
    },
    '&#devicesBtn': {
      display: 'none',
    },
    '&#optimisationBtn': {
      display: 'none',
    },
  },
}));

const AccordionMenu = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '& .MuiCollapse-root': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .MuiAccordionDetails-root': {
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
  },
}));

const AccordionSubmenuItem = styled(Button)<ButtonProps>(() => ({
  justifyContent: 'flex-start',
  height: '52px',
  textTransform: 'capitalize',
  alignItems: 'center',
  color: '#A1A9B1', // todo
  marginLeft: '48px',
  width: '-webkit-fill-available',
}));

const AccordionItem = styled(AccordionSummary)(({ theme }) => ({
  justifyContent: 'flex-start',
  height: '52px',
  textTransform: 'capitalize',
  alignItems: 'center',
  color: '#A1A9B1', // todo
  backgroundColor: theme.palette.background.paper,
  padding: '0 8px',
  '& .MuiSvgIcon-root': {
    color: '#A1A9B1',
  },
}));

const MenuText = styled(Typography)(() => ({
  paddingLeft: '24px',
}));

const MenuIcon = styled(Button)<ButtonProps>(({ theme }) => ({
  height: '48px',
  minWidth: '51px',
  fontSize: '24px',
  color: theme.palette.text.light,
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    '&#mapLayoutBtn': {
      display: 'none',
    },
    '&#devicesBtn': {
      display: 'none',
    },
    '&#optimisationBtn': {
      display: 'none',
    },
  },
}));

const ProjectIcon = styled(Fab)<FabProps>(() => ({
  borderRadius: '5px',
  zIndex: '0',
}));

const CreateProjectButton = styled(Button)(() => ({
  margin: '0 16px',
  fontSize: '12px',
  height: '42px',
}));

const ActionButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  paddingTop: '24px',
  '& .action': {
    width: '93px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    height: '42px',
  },
}));

const ActionBox = styled(Paper)(({ theme }) => ({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
  backgroundColor: theme.palette.background.paper,
  padding: '24px',
  '& .MuiTypography-subtitle2': {
    textAlign: 'center',
    paddingTop: '10px',
  },
  '& .Mui-disabled, .Mui-disabled .MuiOutlinedInput-notchedOutline legend span':
    {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.text.disabled,
    },
}));

const CreateProject = styled(Typography)(() => ({
  padding: '0 0 24px 0',
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
}));

const FormSubtitles = styled(Typography)(() => ({
  paddingTop: '24px',
}));

const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '24px',
  '& .not-last': {
    marginRight: '16px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.light,
  },
}));

const ModalTitleTypography = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
);

const CloseButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.common.white,
}));

export {
  SidebarContainer,
  LogoContainer,
  ProjectButton,
  MenuContainer,
  MenuItem,
  MenuText,
  MenuIcon,
  ProjectIcon,
  CreateProjectButton,
  ActionButtonsBox,
  ActionBox,
  CreateProject,
  AccordionItem,
  AccordionMenu,
  AccordionSubmenuItem,
  TextBox,
  FormSubtitles,
  ModalTitleTypography,
  CloseButton,
};
