import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { companyIntegrations, connectorConfig, getConnectors } from '../../services/apiDefinition';

export const getConnectorsData = createAsyncThunk(
  'integrations/getConnectorsData',
  async () => {
    try {
      const response = await axiosApiInstance.get(getConnectors);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getCompanyIntegrations = createAsyncThunk(
  'integrations/getCompanyIntegrations',
  async () => {
    try {
      const response = await axiosApiInstance.get(companyIntegrations);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const saveConnectorConfig = createAsyncThunk(
  'integrations/saveConnectorConfig',
  async ({ data, dispatch }: any) => {
    try {
      const response = await axiosApiInstance.post(connectorConfig, data);
      dispatch(getCompanyIntegrations());
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const deleteIntegration = createAsyncThunk(
  'integrations/deleteIntegration',
  async (dispatch: any) => {
    try {
      const response = await axiosApiInstance.delete(companyIntegrations);
      dispatch(getCompanyIntegrations());
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);
