import React from 'react';
import { Box, CardActionArea, Grid, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { CardBody, ProjectBox } from '../styles';
import useProjects from '../useProjects';
import CreateNewProject from '../../../components/SideBar/components/createNewProject';

const AddProjectButton = () => {
  const { t } = useTranslation();
  const { openProjectModal, openModal, closeProjectModal } = useProjects();
  return (
    <Grid item xs={12} md={6} lg={4}>
      <ProjectBox>
        <Box
          display="flex"
          justifyContent="space-between"
          height="100%"
          position="relative"
          id="addProjectCardBtn"
        >
          <CardActionArea onClick={openProjectModal} className="add-project-button">
            <CardBody
              avatar={<Add fontSize="large" />}
              title={
                <Typography variant="subtitle1">
                  {t('allProjects.addProject')}
                </Typography>
              }
            />
          </CardActionArea>
        </Box>
      </ProjectBox>
      {(window.innerWidth <= 599 && openModal) && (
        <CreateNewProject open={openModal} handleClose={closeProjectModal} />
      )}
    </Grid>
  );
};

export default AddProjectButton;
