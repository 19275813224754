import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosApiInstance from '../../services/axios';
import {
  userLogin,
  userSignup,
  termsAndCondtions,
  selfDelete,
  logout,
  verificationEmail,
  grafanaLogin,
  grafanaLogout,
} from '../../services/apiDefinition';
import {
  loginResponse,
  signupFaliureResponse,
  signupSuccessResponse,
} from '../../utils/types/apiTypes';
import getGrafanaCredentials from '../../utils/grafanaCredentials';

export const doLogin = createAsyncThunk(
  'auth/doLogin',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post<loginResponse>(
        userLogin,
        request,
        {
          withCredentials: true,
        },
      );
      if (process.env.REACT_APP_PROD === 'true') {
        localStorage.setItem('gf_key', response.data.gf_key);
        await axios.post(
          grafanaLogin,
          getGrafanaCredentials(response.data.gf_key),
          {
            baseURL: process.env.REACT_APP_SERVER_URL,
            headers: {
              Authorization: `Basic ${response.data.gf_key}`,
              'Content-type': 'application/json',
            },
          },
        );
      }
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        console.log(e.response);
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const doSignup = createAsyncThunk(
  'auth/doSignup',
  async (request: object) => {
    try {
      const response = await axiosApiInstance.post<
        signupSuccessResponse | signupFaliureResponse
      >(userSignup, request);

      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getTerms = createAsyncThunk(
  'auth/getTerms',
  async () => {
    try {
      const response = await axiosApiInstance.get(termsAndCondtions);

      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const deactivateAccount = createAsyncThunk(
  'auth/deactivateAccount',
  async () => {
    try {
      const response = await axiosApiInstance.delete(selfDelete);
      localStorage.clear();
      localStorage.removeItem('persist:root');
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const doLogout = createAsyncThunk(
  'auth/doLogout',
  async (key: string) => {
    try {
      if (process.env.REACT_APP_PROD === 'true' && key) {
        await axios.get(grafanaLogout, {
          baseURL: process.env.REACT_APP_SERVER_URL,
          headers: {
            Authorization: `Basic ${key}`,
            'Content-type': 'application/json',
          },
        });
      }
      const response = await axiosApiInstance.get(
        logout,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const sendVerificationEmail = createAsyncThunk(
  'auth/sendVerificationEmail',
  async (email: string | null) => {
    try {
      if (!email) {
        throw new Error('Email is required');
      }
      const response = await axiosApiInstance.post(
        verificationEmail,
        { email },
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
