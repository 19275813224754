import { createSlice } from '@reduxjs/toolkit';
import {
  API_FAILURE_STATUS,
  API_PENDING_STATUS,
  API_SUCCESS_STATUS,
} from '../../utils/constants';
import { integrationsReducer } from '../../utils/types/reducerStateTypes';
import {
  deleteIntegration,
  getCompanyIntegrations,
  getConnectorsData,
  saveConnectorConfig,
} from '../thunks/integrationsThunk';

const initialState: integrationsReducer = {
  tabIndex: 0,
  selectedConnectorConfig: null,
  connectors: [],
  connectorAPIExecutionStatus: API_PENDING_STATUS,
  connectorAPILoadingStatus: false,
  integration: null,
  integrationDataAPIExecutionStatus: API_PENDING_STATUS,
  integrationDataAPILoadingStatus: false,
  integrationDeleteAPILoadingStatus: false,
  integrationDeleteAPIExecutionStatus: API_PENDING_STATUS,
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    setSelectedConnectorConfig: (state, action) => {
      state.selectedConnectorConfig = action.payload;
    },
    resetAPIStatus: state => {
      state.integrationDataAPIExecutionStatus = API_PENDING_STATUS;
      state.integrationDeleteAPIExecutionStatus = API_PENDING_STATUS;
    },
  },
  extraReducers: {
    [getConnectorsData.pending.type]: (state) => {
      state.connectorAPILoadingStatus = true;
      state.connectorAPIExecutionStatus = API_PENDING_STATUS;
    },
    [getConnectorsData.fulfilled.type]: (state, action) => {
      state.connectorAPILoadingStatus = false;
      state.connectorAPIExecutionStatus = API_SUCCESS_STATUS;
      state.connectors = action?.payload?.connectors;
    },
    [getConnectorsData.rejected.type]: (state) => {
      state.connectorAPILoadingStatus = false;
      state.connectorAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [saveConnectorConfig.pending.type]: state => {
      state.integrationDataAPILoadingStatus = true;
      state.integrationDataAPIExecutionStatus = API_PENDING_STATUS;
    },
    [saveConnectorConfig.fulfilled.type]: state => {
      state.integrationDataAPILoadingStatus = false;
      state.integrationDataAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [saveConnectorConfig.rejected.type]: state => {
      state.integrationDataAPILoadingStatus = false;
      state.integrationDataAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [getCompanyIntegrations.pending.type]: state => {
      state.integrationDataAPILoadingStatus = true;
      state.integrationDataAPIExecutionStatus = API_PENDING_STATUS;
    },
    [getCompanyIntegrations.fulfilled.type]: (state, action) => {
      state.integrationDataAPILoadingStatus = false;
      state.integrationDataAPIExecutionStatus = API_SUCCESS_STATUS;
      state.integration = action.payload;
    },
    [getCompanyIntegrations.rejected.type]: state => {
      state.integrationDataAPILoadingStatus = false;
      state.integrationDataAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [deleteIntegration.pending.type]: state => {
      state.integrationDeleteAPILoadingStatus = true;
      state.integrationDeleteAPIExecutionStatus = API_PENDING_STATUS;
    },
    [deleteIntegration.fulfilled.type]: state => {
      state.integrationDeleteAPILoadingStatus = false;
      state.integrationDeleteAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [deleteIntegration.rejected.type]: state => {
      state.integrationDeleteAPILoadingStatus = false;
      state.integrationDeleteAPIExecutionStatus = API_FAILURE_STATUS;
    },
  },
});

export const { setTabIndex, setSelectedConnectorConfig, resetAPIStatus } =
  integrationsSlice.actions;

export default integrationsSlice;
