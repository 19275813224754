import { createTheme } from '@mui/material/styles';
import { lightColorPalette, darkColorPalette } from './colors';
import { typography } from './typography';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface TypeBackground {
    default: string;
    paper: string;
    dark: string;
    primaryLight: string;
    node: string;
    note: string;
    modal: string;
    overlay: string;
    table: string;
    available: string;
    noItems: string;
    restricted: string;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    light: string;
    icon: string;
    primaryLight: string;
    note: string;
    passwordIcn: string;
    deviceLight: string;
    button: string;
  }
}

const lightTheme = createTheme({
  palette: { mode: 'light', ...lightColorPalette },
  typography,
});

const darkTheme = createTheme({
  palette: { mode: 'dark', ...darkColorPalette },
  typography,
});

export { lightTheme, darkTheme };
