import React, { useEffect, useState } from 'react';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import lookup from 'country-code-lookup';
import { toast } from 'react-hot-toast';
import { AppDispatch, RootState } from '../../../store';
import renderCompanyDataOperation from '../../../store/operations/renderCompanyDataOperation';
import { resetProjectData } from '../../../store/slices/crudSlice';
import {
  API_FAILURE_STATUS,
  API_SUCCESS_STATUS,
} from '../../../utils/constants';
import { useShallowEqualSelector } from '../../../utils/helper';
import {
  ActionBox,
  ActionButtonsBox,
  CreateProject,
  TextBox,
  FormSubtitles,
} from '../styles';
import { Modal, TextField } from '../../../components';
import CityPicker from '../../../components/SideBar/components/cityPicker';
import PalletPicker from '../../../components/SideBar/components/palletPicker';
import useProjects from '../useProjects';
import { projectType } from '../../../utils/types/projectTypes';
import MixPanelConstants from '../../../utils/mixpanelConstants';
import { Mixpanel } from '../../../utils/mixPanel';

type propTypes = {
  open: boolean;
  handleClose: () => void;
  project: projectType;
};

type cityType = {
  description: string;
  types: string[];
  place_id: string;
  name: string;
  state: string;
  country: string;
  countryCode: string;
};

const EditProject = ({ open, handleClose, project }: propTypes) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const { updateProjectDetails } = useProjects();
  const {
    projectAPIExecutionStatus,
    projectAPIFailureMessage,
    projectAPILoadingStatus,
  } = useShallowEqualSelector(({ crudData }: RootState) => crudData);

  useEffect(() => {
    dispatch(resetProjectData());
  }, []);

  useEffect(() => {
    if (projectAPIExecutionStatus === API_SUCCESS_STATUS) {
      handleClose();
      dispatch(renderCompanyDataOperation());
      dispatch(resetProjectData());
    }
    if (projectAPIExecutionStatus === API_FAILURE_STATUS) {
      setErrorMsg(projectAPIFailureMessage);
    }
  }, [projectAPIExecutionStatus]);

  // form state
  const [projectName, setProjectName] = useState(project.project_name);
  const [city, setCity] = useState(project.details?.city || null);
  const [postalCode, setPostalCode] = useState(project.details?.postalCode || '');
  const [operators, setOperators] = useState<string | null>(project.details?.operators || null);
  const [shift, setShift] = useState<string | null>(project.details?.shifts || null);
  const [forklifts, setForklifts] = useState<string | null>(project.details?.forklifts || null);
  const [trolleys, setTrolleys] = useState<string | null>(project.details?.trolleys || null);
  const [palletType, setPalletType] = useState(project.details?.palletType || null);
  const [floorType, setFloorType] = useState(project.details?.floorType || '');
  const [skus, setSkus] = useState<string | null>(project.details?.skus || null);
  const [asrs, setAsrs] = useState<string | null>(project.details?.asrs || null);

  const handleCityChange = (value: any) => {
    const splitNames =
      value.structured_formatting.secondary_text !== undefined
        ? value.structured_formatting.secondary_text.split(', ')
        : undefined;
    const tempCity: cityType = {
      description: value.description,
      types: value.types,
      place_id: value.place_id,
      name: value.structured_formatting.main_text,
      state: !splitNames
        ? value.structured_formatting.main_text
        : splitNames.length === 2
          ? splitNames[0]
          : '',
      country: !splitNames
        ? value.structured_formatting.main_text
        : splitNames.length === 2
          ? splitNames[1]
          : splitNames[0],
      countryCode: '',
    };
    const code = lookup.byCountry(tempCity.country);
    tempCity.countryCode = code ? code.iso2 : '';
    setCity(tempCity);
  };
  const handlePalletTypeChange = (value: any) => {
    setPalletType(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg(null);
    if (
      projectName === '' ||
      Object.keys(city).length === 0 ||
      operators === null ||
      palletType === ''
    ) {
      setErrorMsg(t('sidebar.project_invalid'));
    } else if (projectName.length > 50) {
      toast.error(t('sidebar.project_length_invalid'));
    } else {
      let nameChanged = false;
      const details = {
        city: city,
        postalCode: postalCode,
        operators: operators,
        shift: shift,
        forklifts: forklifts,
        trolleys: trolleys,
        palletType: palletType,
        floorType: floorType,
        skus: skus,
        asrs: asrs,
      };
      if (projectName !== project.project_name) {
        nameChanged = true;
      }
      const tempProject = { ...project };
      tempProject.project_name = projectName;
      tempProject.details = details;
      Mixpanel.track(MixPanelConstants.projectEditSave);
      updateProjectDetails(tempProject, nameChanged);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ActionBox
        sx={{ width: `${window.innerWidth - window.innerWidth * 0.3}px` }}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <CreateProject variant="h6">
            {t('allProjects.edit_project_title')}
          </CreateProject>
          <TextField
            defaultValue={projectName}
            placeholder={t('sidebar.new_project_placeholder')}
            label={t('sidebar.new_project_placeholder')}
            variant="outlined"
            fullWidth
            required
            onChange={e => setProjectName(e.target.value)}
            InputProps={{ inputProps: { pattern: '(?!null$).*' } }}
            // error={error || projectAPIExecutionStatus === API_FAILURE_STATUS}
            // helperText={
            //   (error || projectAPIExecutionStatus === API_FAILURE_STATUS) &&
            //   (errorMsg || t('sidebar.project_name_invalid'))
            // }
            autoFocus
          />
          <TextBox>
            <CityPicker onSelect={handleCityChange} defaultValue={project.details?.city || undefined} />
            <TextField
              defaultValue={postalCode}
              placeholder={t('sidebar.new_project_postal_code')}
              label={t('sidebar.new_project_postal_code')}
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: { pattern: '[0-9]*', maxLength: 6, minLength: 4 },
              }}
              onChange={e => setPostalCode(e.target.value)}
            />
          </TextBox>
          <FormSubtitles variant="subtitle1">
            {t('sidebar.inventory_movement')}
          </FormSubtitles>
          <TextBox>
            <TextField
              defaultValue={operators}
              placeholder={t('sidebar.new_project_operators')}
              label={t('sidebar.new_project_operators')}
              variant="outlined"
              fullWidth
              className="not-last"
              required
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setOperators(e.target.value)}
            />
            <TextField
              defaultValue={shift}
              placeholder={t('sidebar.new_project_shift')}
              label={t('sidebar.new_project_shift')}
              variant="outlined"
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setShift(e.target.value)}
            />
          </TextBox>
          <TextBox>
            <TextField
              defaultValue={forklifts}
              placeholder={t('sidebar.new_project_forklift')}
              label={t('sidebar.new_project_forklift')}
              variant="outlined"
              fullWidth
              className="not-last"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setForklifts(e.target.value)}
            />
            <TextField
              defaultValue={trolleys}
              placeholder={t('sidebar.new_project_trolley')}
              label={t('sidebar.new_project_trolley')}
              variant="outlined"
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setTrolleys(e.target.value)}
            />
          </TextBox>
          <TextBox>
            <PalletPicker onSelect={handlePalletTypeChange} defaultValue={project.details?.palletType || undefined} />
            <TextField
              defaultValue={floorType}
              placeholder={t('sidebar.new_project_floor_type')}
              label={t('sidebar.new_project_floor_type')}
              variant="outlined"
              fullWidth
              onChange={e => setFloorType(e.target.value)}
            />
          </TextBox>
          <FormSubtitles variant="subtitle1">
            {t('sidebar.storage_systems')}
          </FormSubtitles>
          <TextBox>
            <TextField
              defaultValue={skus}
              placeholder={t('sidebar.new_project_skus')}
              label={t('sidebar.new_project_skus')}
              variant="outlined"
              fullWidth
              className="not-last"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setSkus(e.target.value)}
            />
            <TextField
              defaultValue={asrs}
              placeholder={t('sidebar.new_project_asrs')}
              label={t('sidebar.new_project_asrs')}
              variant="outlined"
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={e => setAsrs(e.target.value)}
            />
          </TextBox>
          {errorMsg && (
            <Typography variant="subtitle2" color="red">
              {errorMsg}
            </Typography>
          )}
          <ActionButtonsBox>
            <Button size="medium" onClick={handleClose} className="action">
              <Typography variant="body2">
                {t('sidebar.cancel_action_button')}
              </Typography>
            </Button>
            {projectAPILoadingStatus ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<Save />}
                variant="outlined"
              >
                Wait
              </LoadingButton>
            ) : (
              <Button
                size="medium"
                color="primary"
                variant="contained"
                className="action"
                type="submit"
              >
                <Typography variant="body2">
                  {t('sidebar.save_action_button')}
                </Typography>
              </Button>
            )}
          </ActionButtonsBox>
        </Box>
      </ActionBox>
    </Modal>
  );
};

export default EditProject;
