import { Drawer } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  handleMenuItemClick,
  handleSidebarState,
} from '../../store/slices/menuSlice';
import { LOCATIONINDEX } from '../../utils/constants';
import CreateProject from './components/createProject';
import Logo from './components/logo';
import Menu from './components/menu';
import styles from './sideBar.module.css';
import { SidebarContainer } from './styles';
import useSidebar from './useSidebar';

const SideBar = ({ mobileSideBar, setMobileSideBar }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { sidebarState } = useSidebar();
  const toggleSidebar = () => {
    const state = !sidebarState;
    dispatch(handleSidebarState(state));
  };

  useEffect(() => {
    const path = location.pathname;
    const index = LOCATIONINDEX[path as keyof typeof LOCATIONINDEX];
    if (index !== undefined && index !== 1) {
      dispatch(handleMenuItemClick(index));
    } else {
      dispatch(handleMenuItemClick(-1));
    }
    if (window.innerWidth < 900) {
      toggleSidebar();
    }
  }, []);

  if (window.innerWidth <= 599) {
    return (
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileSideBar}
        onClose={() => setMobileSideBar(false)}
        elevation={0}
        PaperProps={{
          sx: { minWidth: '265px', padding: '24px 16px' },
        }}
      >
        <Logo open toggleSidebar={() => setMobileSideBar(false)} />
        <CreateProject open />
        <Menu open toggleSidebar={() => setMobileSideBar(false)} />
      </Drawer>
    );
  }
  return (
    <SidebarContainer className={sidebarState ? styles.open : styles.close}>
      <Logo open={sidebarState} toggleSidebar={toggleSidebar} />
      <CreateProject open={sidebarState} />
      <Menu open={sidebarState} toggleSidebar={() => {}} />
    </SidebarContainer>
  );
};

export default SideBar;
