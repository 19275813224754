import React from 'react';
import {
  Divider,
  FormControl,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Skeleton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { CustomSelect, LastOption } from '../styles';
import useAppbar from '../useAppbar';
import { NULL_STRING } from '../../../utils/constants';

const ProjectList = () => {
  const { t } = useTranslation();
  const {
    handleClickItem,
    currentProject,
    switchCurrProject,
    projectsList,
    projectAPILoadingStatus,
  } = useAppbar();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    if (value !== 'all_projects' && value !== NULL_STRING) {
      switchCurrProject(value);
    }
  };

  return (
    <FormControl id="projectList">
      {projectAPILoadingStatus ? (
        <Skeleton variant="rectangular" width={100} height={20} />
      ) : (
        <CustomSelect
          value={currentProject}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
        >
          {projectsList.length !== 0 ? (
            projectsList.map((project: any) => (
              <MenuItem
                key={project.hashed_identifier}
                value={project.hashed_identifier}
              >
                {project.project_name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="null">No projects found</MenuItem>
          )}
          <Divider />
          <LastOption>
            <MenuItem
              value="all_projects"
              onClick={() => {
                handleClickItem('/app/all-projects');
              }}
            >
              {t('appbar.view_projects_button')}
            </MenuItem>
          </LastOption>
        </CustomSelect>
      )}
    </FormControl>
  );
};

export default ProjectList;
