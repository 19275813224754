import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {
  TermsModalContainer,
  TermsBlock,
} from '../styles';
import useSignup from '../useSignup';

const TermsModal = ({ isModalVisible, onCloseModal }:
  { isModalVisible: boolean, onCloseModal: () => void }) => {
  const { termsData } = useSignup();
  return (
    <Modal
      open={Boolean(isModalVisible)}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={Boolean(isModalVisible)}>
        <TermsModalContainer>
          <TermsBlock>
            <iframe srcDoc={termsData} title="iframe" style={{ height: '77vh', width: '100%', border: 'none' }} />
          </TermsBlock>
        </TermsModalContainer>
      </Fade>
    </Modal>
  );
};

export default TermsModal;
