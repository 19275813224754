import { Box, styled } from '@mui/material';

const CircleLoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  margin: 'auto',
  backgroundColor: theme.palette.background.paper,
  '& .MuiCircularProgress-root': {
    margin: 'auto',
  },
}));

export { CircleLoadingContainer };
