import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import Box, { BoxProps } from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Button, Slider } from '../../components';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
}));

const WelcomeContainer = styled(Box)<BoxProps>(() => ({
  background: 'linear-gradient(274.72deg, #23C673 -7.54%, #249ACC 43.58%, #009DC2 130.71%)',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const WelcomeInnerBlock = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
}));

const WelcomeImgBlock = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  paddingRight: 12,
  [theme.breakpoints.down('md')]: {
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const CloseIconBlock = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  height: 'fit-content',
  marginTop: '12px',
}));

const WelcomeTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
  [theme.breakpoints.down('md')]: {
    fontSize: '17px',
  },
}));

const WelcomeSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  padding: '10px 0',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}));

const UpgradePlanButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'capitalize',
  width: 150,
  justifyContent: 'flex-start',
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '&.Mui-disabled': {
    color: theme.palette.primary.main,
  },
  borderRadius: 0,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.common.white,
    opacity: 0.60,
  },
}));

const GetStartedContainer = styled(Box)<BoxProps>(({ theme }) => ({
  padding: 20,
  backgroundColor: theme.palette.background.paper,
  marginTop: 25,
  borderRadius: 4,
}));

const GetStartedTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
  marginBottom: 20,
}));

const StepAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const GetStartedInnerContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const GetStartedTextContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const AddDeviceButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  textTransform: 'capitalize',
  width: '100%',
  justifyContent: 'flex-end',
}));

const GetStartedInnerText = styled(Box)<BoxProps>(() => ({
  paddingLeft: 10,
}));

const GetStartedHeading = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
}));

const GetStartedSubHeading = styled(Typography)<TypographyProps>(() => ({
  color: grey[400],
}));

const CounterText = styled(Typography)<TypographyProps>(() => ({
  color: grey[400],
  display: 'flex',
  alignItems: 'center',
  padding: '0 15px',
}));

const SliderContainer = styled(Box)<BoxProps>(() => ({
  flexDirection: 'row',
  display: 'flex',
  padding: '20px 0 0 0',
}));

const UserGuideTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.common.white,
  margin: '25px 0',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

const UserGuideItemBlock = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 4,
}));

const LearnMoreButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.light,
  textTransform: 'none',
  width: 150,
  justifyContent: 'flex-start',
  paddingLeft: 0,
  marginTop: 15,
}));

const RecentProjectContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const RecentProjectInnerContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
}));

const RecentFolderIcon = styled(FolderIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2.5rem',
  marginRight: 10,
}));

const RecentDotIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const ProjectCounters = styled(Typography)<TypographyProps>(() => ({
  color: grey[400],
  padding: '50px 0 0 0',
}));

const LoaderContainer = styled(Box)(() => ({
  background: '#1C1D22',
  borderRadius: 6,
  display: 'flex',
  justifyContent: 'space-between',
  // width: '490px',
  height: '144px',
  // marginTop: '24px',
  // marginLeft: '24px',
}));

const LoaderDataContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  margin: '16px',
  gap: '10px',
}));

const LoaderTextContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
}));

const TypoProjectButton = styled(Typography)<TypographyProps>(() => ({
  whiteSpace: 'nowrap',
}));

export {
  Main,
  WelcomeContainer,
  WelcomeTitle,
  WelcomeInnerBlock,
  WelcomeImgBlock,
  CloseIconBlock,
  WelcomeSubTitle,
  UpgradePlanButton,
  CustomSlider,
  GetStartedContainer,
  GetStartedTitle,
  StepAvatar,
  GetStartedTextContainer,
  GetStartedInnerContainer,
  AddDeviceButton,
  GetStartedHeading,
  GetStartedInnerText,
  GetStartedSubHeading,
  SliderContainer,
  CounterText,
  UserGuideTitle,
  UserGuideItemBlock,
  LearnMoreButton,
  RecentProjectContainer,
  RecentFolderIcon,
  RecentDotIcon,
  RecentProjectInnerContainer,
  ProjectCounters,
  LoaderContainer,
  LoaderDataContainer,
  LoaderTextContainer,
  TypoProjectButton,
};
