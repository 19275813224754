import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  SignupInnerBlock,
  SignupLeftItemGrid,
  LoginButton,
  LoginText,
  TextContainer,
  CheckBoxLabel,
  ErrorMsg,
  TermLink,
} from '../styles';
import { Button, TextField } from '../../../components';
import { Logo } from '../../../assets/images';
import { SignupFormValues } from '../../../utils/types/formTypes';
import useSignup from '../useSignup';
import TermsModal from './termsModal';
import {
  API_SUCCESS_STATUS,
} from '../../../utils/constants';
import PasswordIcon from './passwordIcon';

type paramsType = {
  token: string;
  email: string;
  company: string;
};

type SignupFormProps = {
  getEmail: (email: string | null) => void;
};

const SignupForm = ({ getEmail }: SignupFormProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const {
    openLogin,
    submitSignup,
    getTermsAndConditions,
    termsAPILoadingStatus,
    signUpAPILoadingStatus,
  } = useSignup();
  const [checked, setChecked] = React.useState(false);
  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [params, setParams] = useState<paramsType | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignupFormValues>();

  useEffect(() => {
    if (searchParams.get('token') && searchParams.get('combase')) {
      const token = searchParams.getAll('token')[0]!;
      const email_company = atob(searchParams.get('combase')!);
      const email = email_company.split(':')[0];
      const company = email_company.split(':')[1];
      setParams({ token, email, company });
      setValue('email', email);
      setValue('companyName', company);
    }
  }, []);

  const onSubmit = handleSubmit(data => {
    console.log(data, 'data');
    console.log(checked, 'checked');
    if (checked) {
      const tempData = { ...data };
      if (params && params.token) {
        tempData['invite_token' as keyof typeof data] = params.token;
      }
      getEmail(tempData.email);
      submitSignup(tempData);
    } else {
      setError(true);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setChecked(event.target.checked);
  };

  const onTermsClick = () => {
    getTermsAndConditions();
  };

  const handleSumitForm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      executeRecaptcha('enquiryFormSubmit').then(() => {
        onSubmit();
      });
    },
    [executeRecaptcha, checked],
  );

  useEffect(() => {
    if (termsAPILoadingStatus === API_SUCCESS_STATUS) {
      setTermsOpen(true);
    }
  }, [termsAPILoadingStatus]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SignupLeftItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <SignupInnerBlock>
        <Logo />
        <TextContainer component="form" onSubmit={handleSumitForm}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label={t('signup.email_field_label')}
            autoComplete="email"
            autoFocus
            {...register('email', {
              required: true,
              pattern:
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            })}
            onChange={e => setValue('email', e.target.value.replace(/\s/g, ''))}
            error={!!errors?.email}
            helperText={errors.email ? t('signup.email_invalid_message') : null}
            inputProps={
              { readOnly: params }
            }
            value={params?.email}
          />
          <TextField
            margin="normal"
            fullWidth
            id="fullName"
            label={t('signup.first_name_field_label')}
            {...register('fullName', {
              required: true,
            })}
            onChange={e =>
              setValue('fullName', e.target.value)}
            error={!!errors?.fullName}
            helperText={errors.fullName ? t('signup.first_name_invalid_message') : null}
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <PasswordIcon
                  handleClickShowPassword={handleClickShowPassword}
                  showPassword={showPassword}
                />
              ),
            }}
            label={t('signup.password_field_label')}
            {...register('password', {
              required: true,
              pattern:
              /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/,
            })}
            onChange={e =>
              setValue('password', e.target.value)}
            error={!!errors?.password}
            helperText={errors.password ? t('signup.password_invalid_message') : null}
          />
          <TextField
            margin="normal"
            fullWidth
            id="companyName"
            label={t('signup.company_field_label')}
            {...register('companyName', {
              required: true,
            })}
            onChange={e =>
              setValue('companyName', e.target.value.replace(/\s/g, ''))}
            error={!!errors?.companyName}
            helperText={errors.companyName ? t('signup.company_name_invalid_message') : null}
            inputProps={
              { readOnly: params }
            }
            value={params?.company}
          />
          <CheckBoxLabel
            control={
              <Checkbox
                id="agreeTerms"
                name="agreeTerms"
                checked={checked}
                onChange={handleChange}
              />
            }
            label={t('signup.agree_field_label')}
          />

          <TermLink
            variant="text"
            title={t('signup.terms_and_conditions')}
            onClick={onTermsClick}
          />

          {isError && <ErrorMsg>{t('signup.agree_invalid_message')}</ErrorMsg>}
          <Button
            type="submit"
            title={signUpAPILoadingStatus ? 'Loading...' : t('signup.signup_button_text')}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            disabled={signUpAPILoadingStatus}
          />
          <LoginText variant="subtitle1">
            {t('signup.login_text')}
            <LoginButton
              title={t('signup.log_in')}
              variant="text"
              color="primary"
              onClick={openLogin}
              disabled={signUpAPILoadingStatus}
            />
          </LoginText>
        </TextContainer>
      </SignupInnerBlock>
      <TermsModal
        isModalVisible={isTermsOpen}
        onCloseModal={() => setTermsOpen(false)}
      />
    </SignupLeftItemGrid>
  );
};

export default SignupForm;
