import { createSlice } from '@reduxjs/toolkit';
import { aiTabReducer } from '../../utils/types/reducerStateTypes';
import { getAiData, startTrainModel } from '../thunks/aiTabThunk';

const initialState: aiTabReducer = {
  sideTabOpen: true,
  rackData: {
    mode: 'slot',
    slotNumber: '',
    data: {},
  },
  timeRangeCount: 1,
  sideTabIndex: 0,
  pubnubMessages: {},
  trainingLoading: false,
  trainingStarted: false,
  trainingFaliure: false,
  orignalData: {},
  optimizedData: {},
  dataLoading: false,
  dataFaliure: false,
  optimizedMapData: {},
};

const aiTabSlice = createSlice({
  name: 'aiTab',
  initialState,
  reducers: {
    setSideTabOpen: (state, action) => {
      state.sideTabOpen = action.payload;
    },
    setRackData: (state, action) => {
      state.rackData = action.payload;
    },
    setTimeRangeCount: (state, action) => {
      state.timeRangeCount = action.payload;
    },
    setSideTabIndex: (state, action) => {
      state.sideTabIndex = action.payload;
    },
    updatePubnubMessages: (state, action) => {
      state.pubnubMessages = action.payload;
    },
    resetTraining: state => {
      state.trainingLoading = false;
      state.trainingStarted = false;
      state.trainingFaliure = false;
    },
    resetOptimizedData: state => {
      state.optimizedData = {};
    },
  },
  extraReducers: {
    [startTrainModel.pending.type]: state => {
      state.trainingLoading = true;
      state.trainingStarted = false;
      state.trainingFaliure = false;
    },
    [startTrainModel.fulfilled.type]: state => {
      state.trainingLoading = false;
      state.trainingStarted = true;
    },
    [startTrainModel.rejected.type]: state => {
      state.trainingLoading = false;
      state.trainingFaliure = true;
    },

    [getAiData.pending.type]: state => {
      state.dataLoading = true;
      state.dataFaliure = false;
    },
    [getAiData.fulfilled.type]: (state, action) => {
      state.dataLoading = false;
      const [data, key] = action.payload;
      if (key === 'original_results_json') {
        state.orignalData = data;
      } else if (key === 'optimized_map_layout_json') {
        state.optimizedMapData = data;
      } else {
        state.optimizedData = data;
      }
    },
    [getAiData.rejected.type]: state => {
      state.dataLoading = false;
      state.dataFaliure = true;
    },
  },
});

export const {
  setSideTabOpen,
  setRackData,
  setTimeRangeCount,
  setSideTabIndex,
  updatePubnubMessages,
  resetTraining,
  resetOptimizedData,
} = aiTabSlice.actions;

export default aiTabSlice;
