import React, { useState } from 'react';
import toast from 'react-hot-toast';
// import { useDispatch } from 'react-redux';

// import { AppDispatch } from '../../store';
// import { doLogin } from '../../store/thunks/authThunk';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import axiosApiInstance from '../../services/axios';
import { resetPassowrd } from '../../services/apiDefinition';

const initialFormData = Object.freeze({
  username: '',
  password: '',
});

const useForgot = () => {
  // const dispatch: AppDispatch = useDispatch();
  const {
    loginAPIExecutionStatus,
    loginAPISuccessMessage,
    loginAPIFailureMessage,
  } = useShallowEqualSelector(({ auth }: RootState) => auth);
  const [formData, updateFormData] = useState(initialFormData);
  // const [isEmailError, setEmailError] = useState(false);
  // const [isPasswordError, setPasswordError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  // const submitLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   const { username, password } = formData;
  //   if (!username) {
  //     setEmailError(true);
  //     return;
  //   }
  //   if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/).test(username)) {
  //     setEmailError(true);
  //     return;
  //   }
  //   if (!password) {
  //     setPasswordError(true);
  //     return;
  //   }
  //   e.preventDefault();
  //   dispatch(doLogin(formData));
  // };

  const resetUserPassword = async (email: string) => {
    try {
      await axiosApiInstance.post(resetPassowrd, { email: email });
      toast.success('Password sent to your email successfully');
    } catch (error: any) {
      toast.error('Something went wrong');
    }
  };

  return {
    loginAPIExecutionStatus,
    loginAPISuccessMessage,
    loginAPIFailureMessage,
    handleChange,
    // submitLogin,
    // isEmailError,
    // isPasswordError,
    resetUserPassword,
  };
};

export default useForgot;
