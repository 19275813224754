import React from 'react';
// import CloseIcon from '@mui/icons-material/Close';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import {
  WelcomeContainer,
  WelcomeTitle,
  WelcomeInnerBlock,
  WelcomeImgBlock,
  // CloseIconBlock,
  WelcomeSubTitle,
  // UpgradePlanButton,
} from '../styles';
import { Welcome } from '../../../assets/images';

type props = {
  name: string;
};

const WelcomeBlock = ({ name }: props) => {
  const { t } = useTranslation();
  return (
    <WelcomeContainer>
      <WelcomeInnerBlock>
        <WelcomeTitle variant="h5">
          {`${t('welcome.welcome_title')} ${name}`}
        </WelcomeTitle>
        <WelcomeSubTitle variant="subtitle1">
          {t('welcome.welcome_subtitle')}
        </WelcomeSubTitle>
        {/* <UpgradePlanButton
          title={t('welcome.upgrade_plan')}
          variant="text"
          endIcon={<ArrowForwardIcon />}
        /> */}
      </WelcomeInnerBlock>
      <WelcomeImgBlock>
        <Welcome />
        {/* <CloseIconBlock onClick={closeBanner}>
          <CloseIcon />
        </CloseIconBlock> */}
      </WelcomeImgBlock>
    </WelcomeContainer>
  );
};

export default WelcomeBlock;
