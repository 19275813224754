import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Hidden } from '@mui/material';
import { ForgotContainerGrid, ForgotContainerInnerGrid } from './styles';
import useForgot from './useForgot';
import { API_SUCCESS_STATUS, API_FAILURE_STATUS } from '../../utils/constants';
import ForgotForm from './components/ForgotForm';
import ForgotImage from './components/ForgotPasswordImageContainer';
import SendEmail from './components/SendEmail';

const ForgotPage = () => {
  const { t } = useTranslation();
  const [send, setsend] = useState(false);
  const {
    loginAPIExecutionStatus,
    loginAPISuccessMessage,
    loginAPIFailureMessage,
    resetUserPassword,
  } = useForgot();

  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/app/welcome', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (loginAPIExecutionStatus === API_SUCCESS_STATUS) {
      toast.success(loginAPISuccessMessage);
    } else if (loginAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(loginAPIFailureMessage);
    }
  }, [loginAPIExecutionStatus]);

  const getEmail = (emailValue: string) => {
    setEmail(emailValue);
    setsend(true);
    resetUserPassword(emailValue);
  };

  return (
    <ForgotContainerGrid container>
      <ForgotContainerInnerGrid container item md={6}>
        {(
          !send
            ? (
              <ForgotForm
                t={t}
                navigate={navigate}
                getEmail={getEmail}
              />
            )
            : (<SendEmail t={t} emailValue={email} resetUserPassword={resetUserPassword} />)
        )}
      </ForgotContainerInnerGrid>
      <Hidden mdDown>
        <ForgotContainerInnerGrid container item md={6}>
          <ForgotImage t={t} />
        </ForgotContainerInnerGrid>
      </Hidden>
    </ForgotContainerGrid>
  );
};

export default ForgotPage;
