import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';
import { ProjectButton, ProjectIcon, CreateProjectButton } from '../styles';
import CreateNewProject from './createNewProject';
import { useShallowEqualSelector } from '../../../utils/helper';
import { AppDispatch, RootState, store } from '../../../store';
import { API_SUCCESS_STATUS } from '../../../utils/constants';
import { setProjects, projectsSelectors } from '../../../store/slices/projectsSlice';
import { resetProjectData } from '../../../store/slices/crudSlice';
import { setRecentProjects } from '../../../store/slices/welcomeSlice';
import switchCurrentProjectOperation from '../../../store/operations/switchCurrentProjectOperation';
import useSidebar from '../useSidebar';

type propsType = {
  open: boolean;
};

const CreateProject = ({ open }: propsType) => {
  const dispatch: AppDispatch = useDispatch();
  const { openModal, closeProjectModal, openProjectModal } = useSidebar();
  const { t } = useTranslation();

  const {
    projectAPIExecutionStatus,
    projectDeleted,
  } = useShallowEqualSelector(({ crudData }: RootState) => crudData);

  const projectsData = projectsSelectors.selectAll(store.getState());

  useEffect(() => {
    const isProjectDeleted = Boolean(projectDeleted);
    if (projectAPIExecutionStatus === API_SUCCESS_STATUS) {
      if (isProjectDeleted) {
        console.log(projectsData);
        const filteredData: any = projectsData.filter((i: any) => i.id !== projectDeleted);
        console.log(filteredData);
        dispatch(setProjects(filteredData));
        dispatch(setRecentProjects(filteredData));
        if (filteredData.length > 0) {
          dispatch(switchCurrentProjectOperation(filteredData[0].id));
        } else {
          dispatch(switchCurrentProjectOperation(null));
        }
      }
      dispatch(resetProjectData());
    }
  }, [projectAPIExecutionStatus]);

  return (
    <>
      <ProjectButton onClick={openProjectModal} id="createProjectBtn">
        {open ? (
          <CreateProjectButton variant="contained" startIcon={<AddIcon />}>
            {t('sidebar.create_project_button')}
          </CreateProjectButton>
        ) : (
          <ProjectIcon size="medium" color="primary">
            <AddIcon />
          </ProjectIcon>
        )}
      </ProjectButton>

      {openModal && (
        <CreateNewProject open={openModal} handleClose={closeProjectModal} />
      )}
    </>
  );
};

export default CreateProject;
