import { batch } from 'react-redux';
import { ThunkDispatch, RootState } from '../index';
import { setProjects } from '../slices/projectsSlice';
import { setAreas } from '../slices/areasSlice';
import { setDevices } from '../slices/devicesSlice';
// import switchCurrentProjectOperation from './switchCurrentProjectOperation';
import { companyDataLoaded, setRecentProjects } from '../slices/welcomeSlice';
import { getCompanyData } from '../thunks/welcomeThunk';
// import { getDevices } from '../thunks/devicesThunk';
import { getCurrentProject } from '../thunks/selectedDataThunk';

// eslint-disable-next-line max-len
const renderCompanyDataOperation = () => async (dispatch: ThunkDispatch, getState: () => RootState) => {
  await dispatch(getCompanyData());
  const state = getState();
  let areas: any = [];
  let devices: any = [];
  const { projects } = state.projects.companyData;
  const projectsData = projects.map((project: any) => {
    const { warehouse_project, ...restProjectData } = project;
    areas = [...areas, ...warehouse_project];
    const warehouses = warehouse_project.map(
      ({ uuid }: { uuid: string }) => uuid,
    );
    return {
      id: restProjectData.hashed_identifier,
      areas: warehouses,
      ...restProjectData,
    };
  });

  let areasData = areas.map((area: any) => {
    const { connectednodes_area, ...restArea } = area;
    devices = [...devices, ...connectednodes_area];
    const extractedDevices = connectednodes_area.map(
      ({ ref_id }: { ref_id: string }) => ref_id,
    );
    return { ...restArea, devices: extractedDevices };
  });

  areasData = areasData.map((area: any) => ({
    ...area,
    id: area.uuid,
    areaId: area.id,
  }));

  const devicesData = devices.map((device: any) => ({
    ...device,
    id: device.ref_id,
    deviceId: device.id,
  }));
  batch(() => {
    dispatch(setProjects(projectsData));
    dispatch(setRecentProjects(projectsData));
    dispatch(getCurrentProject({ dispatch }));
    // if (projectsData.length > 0) {
    //   dispatch(switchCurrentProjectOperation(projectsData[0].id));
    // } else {
    //   dispatch(switchCurrentProjectOperation('null'));
    // }
    dispatch(setAreas(areasData));
    dispatch(setDevices(devicesData));
    dispatch(companyDataLoaded(true));
    // dispatch(getDevices());
  });
};

export default renderCompanyDataOperation;
