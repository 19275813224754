import React, { useRef, useEffect, lazy, Suspense } from 'react';
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import {
  LoginPage,
  WelcomePage,
  SignupPage,
  ForgotPage,
  PasswordPage,
} from '../pages';
import ProtectedRoute from './ProtectedRoute';
import usePubnubWatcher from '../store/watchers/usePubnubWatcher';
import { initiMixPanel } from '../utils/mixPanel';
import { useShallowEqualSelector } from '../utils/helper';
import { CircularLoading } from '../components';
import { doLogout } from '../store/thunks/authThunk';
import Navigation from '../utils/hoc/Navigation';

const DevicesPage = lazy(() =>
  Promise.all([
    import('../pages/devices'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const SettingsPage = lazy(() =>
  Promise.all([
    import('../pages/settings'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const ProjectsPage = lazy(() =>
  Promise.all([
    import('../pages/projects'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const MaplayoutPage = lazy(() =>
  Promise.all([
    import('../pages/maplayout'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const SubscriptionPage = lazy(() =>
  Promise.all([
    import('../pages/subscription'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const PaymentPage = lazy(() =>
  Promise.all([
    import('../pages/payment'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const InventoryPage = lazy(() =>
  Promise.all([
    import('../pages/inventory'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const DashboardPage = lazy(() =>
  Promise.all([
    import('../pages/dashboard'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const OrdersPage = lazy(() =>
  Promise.all([
    import('../pages/orders'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
// const AIPage = lazy(() =>
//   Promise.all([
//     import('../pages/AITab'),
//     // eslint-disable-next-line
//     new Promise(resolve => setTimeout(resolve, 1000)),
//   ]).then(([moduleExports]) => moduleExports));
const Optimizer = lazy(() =>
  Promise.all([
    import('../pages/optimizerTab'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const IntegrationsPage = lazy(() =>
  Promise.all([
    import('../pages/integrations'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const ChangePlan = lazy(() =>
  Promise.all([
    import('../pages/changePlan'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const NotificationsPage = lazy(() =>
  Promise.all([
    import('../pages/notifications'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const PageNotFound = lazy(() =>
  Promise.all([
    import('../pages/404Error'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));
const ErrorPage = lazy(() =>
  Promise.all([
    import('../pages/500Error'),
    // eslint-disable-next-line
    new Promise(resolve => setTimeout(resolve, 1000)),
  ]).then(([moduleExports]) => moduleExports));

const Routing = () => {
  initiMixPanel(process.env.REACT_APP_MIXPANEL_TOKEN);
  const dispatch = useDispatch();
  usePubnubWatcher();
  const { userData } = useSelector(({ auth }) => auth);
  const { logoutData } = useShallowEqualSelector(
    ({ auth }) => auth,
  );
  const tawkMessengerRef = useRef();
  const isAuthenticated = localStorage.getItem('authToken');
  useEffect(() => {
    if (logoutData && tawkMessengerRef.current) {
      tawkMessengerRef.current.hideWidget();
    }
  }, [logoutData]);
  useEffect(() => {
    if (isAuthenticated && tawkMessengerRef.current) {
      // tawkMessengerRef.current.showWidget();
    }
  }, [isAuthenticated]);

  const onIdle = () => {
    if (userData?.username) {
      dispatch(doLogout(localStorage.getItem('gf_key')));
      sessionStorage.setItem('autoLogoutMessage', 'Your session has been logged out due to inactivity.');
    }
  };

  useIdleTimer({
    onIdle,
    timeout: process.env.REACT_APP_IDLE_AUTO_LOGOUT_DURATION,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  const tawkBeforeLoad = () => {
    if (!isAuthenticated) {
      tawkMessengerRef.current.hideWidget();
    }
  };
  return (
    <>
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
        customStyle={{ zIndex: 999 }}
        ref={tawkMessengerRef}
        onBeforeLoad={tawkBeforeLoad}
      />
      <BrowserRouter>
        <Navigation>
          <Routes>
            <Route exact path="/" element={<Navigate to="/app" replace />} />
            <Route exact path="/app" element={<LoginPage />} />
            <Route exact path="/app/login" element={<LoginPage />} />
            <Route exact path="/app/signup" element={<SignupPage />} />
            <Route
              exact
              path="/app/welcome"
              element={
                <ProtectedRoute>
                  <WelcomePage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/subscription"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <SubscriptionPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/payment"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <PaymentPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/change-plan"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <ChangePlan />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/all-projects"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <ProjectsPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/settings"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <SettingsPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/map-layout"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <MaplayoutPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route exact path="/app/forgot-page" element={<ForgotPage />} />
            <Route exact path="/app/new-password" element={<PasswordPage />} />
            {/* <Route
            exact
            path="/app/system-builder"
            element={
              <ProtectedRoute>
                <SystemBuilder />
              </ProtectedRoute>
            }
          /> */}
            <Route
              exact
              path="/app/devices/"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <DevicesPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/inventory"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <InventoryPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/orders"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <OrdersPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/dashboard"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <DashboardPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/notifications"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <NotificationsPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            {/* <Route
            exact
            path="/app/optimisation"
            element={
              <ProtectedRoute>
                <Suspense fallback={<CircularLoading />}>
                  <AIPage />
                </Suspense>
              </ProtectedRoute>
            }
          /> */}
            <Route
              exact
              path="/app/optimisation"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <Optimizer />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/app/integrations"
              element={
                <ProtectedRoute>
                  <Suspense fallback={<CircularLoading />}>
                    <IntegrationsPage />
                  </Suspense>
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<CircularLoading />}>
                  <PageNotFound />
                </Suspense>
              }
            />

            <Route
              path="/app/error"
              element={
                <Suspense fallback={<CircularLoading />}>
                  <ErrorPage />
                </Suspense>
              }
            />
          </Routes>
        </Navigation>
      </BrowserRouter>
    </>
  );
};

export default Routing;
