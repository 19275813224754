import React, { useState, useEffect, useRef } from 'react';
import { Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useLogin from './useLogin';
import { API_SUCCESS_STATUS, API_FAILURE_STATUS } from '../../utils/constants';
import LoginForm from './components/loginForm';
import LoginImage from './components/loginImageContainer';
import { AppDispatch } from '../../store';
import renderCompanyDataOperation from '../../store/operations/renderCompanyDataOperation';
import { getTour } from '../../store/thunks/tourThunk';
import { getCompanyIntegrations, getConnectorsData } from '../../store/thunks/integrationsThunk';
import { setLoginRedirect, resetResendVerificationEmail } from '../../store/slices/authSlice';
import { CircularLoading } from '../../components';
import { LoginContainerGrid, LoginContainerForm } from './styles';

const LoginPage = () => {
  const {
    loginAPIExecutionStatus,
    loginAPIFailureMessage,
    submitLogin,
    openSignup,
    userData,
    logoutData,
    loginAPILoadingStatus,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
  } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const loginToastId = useRef('');
  const resendToastId = useRef('');
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/app/welcome', { replace: true });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    toast.dismiss(loginToastId.current);
    if (loginAPILoadingStatus) {
      loginToastId.current = toast.loading(t('login.login_loading'));
    } else if (loginAPIExecutionStatus === API_SUCCESS_STATUS) {
      if (userData?.jwt_token) {
        dispatch(renderCompanyDataOperation());
        dispatch(getTour());
        dispatch(getConnectorsData());
        dispatch(getCompanyIntegrations());
      }
      navigate('/app/welcome', { replace: true });
      // if (userData?.payment_method_status === 'IN_PROGRESS' || userData?.payment_method_status === 'ADDED') {
      //   navigate('/app/welcome', { replace: true });
      // } else {
      //   navigate('/app/subscription', { replace: true });
      // }
      toast.dismiss(loginToastId.current);
    } else if (loginAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(loginAPIFailureMessage, { id: loginToastId.current });
    }
  }, [loginAPIExecutionStatus, loginAPILoadingStatus]);

  useEffect(() => {
    toast.dismiss(resendToastId.current);
    if (resendVerificationEmailAPILoadingStatus) {
      resendToastId.current = toast.loading(t('signup.sending_email'));
    } else if (resendVerificationEmailAPIExecutionStatus === API_SUCCESS_STATUS) {
      toast.success(t('signup.email_sent'), { id: resendToastId.current });
      // execute this after 2 seconds
      setTimeout(() => {
        dispatch(resetResendVerificationEmail());
      }, 2000);
    } else if (resendVerificationEmailAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(resendVerificationEmailAPIFailureMessage, { id: resendToastId.current });
    }
  }, [resendVerificationEmailAPIExecutionStatus, resendVerificationEmailAPILoadingStatus]);

  useEffect(() => {
    if (logoutData) {
      dispatch(setLoginRedirect());
    }
  }, [logoutData]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (isLoading) {
    return (
      <>
        <CircularLoading />
      </>
    );
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
      <LoginContainerGrid container>
        <LoginContainerForm container item md={6}>
          <LoginForm
            submitLogin={submitLogin}
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            openSignup={openSignup}
          />
        </LoginContainerForm>
        <Hidden mdDown>
          <LoginContainerForm container item md={6}>
            <LoginImage />
          </LoginContainerForm>
        </Hidden>
      </LoginContainerGrid>
    </GoogleReCaptchaProvider>
  );
};

export default LoginPage;
