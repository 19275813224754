import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Player } from '@lottiefiles/react-lottie-player';
import { Button } from '../../components';

const LoginContainerGrid = styled(Grid)<GridProps>(() => ({
  height: '100vh',
  display: 'flex',
}));

const LoginContainerForm = styled(Grid)<GridProps>(() => ({
  height: '100vh',
}));

const LoginLeftItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.paper,
  width: 'inherit',
  padding: '0rem 2rem',
}));

const LoginRightItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.dark,
  width: 'inherit',
}));

const LoginInnerBlock = styled(Box)<BoxProps>(() => ({
  height: 'inherit',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiAlert-root': {
    margin: '24px 0 16px 0',
  },
}));

const LoginContainer = styled(Container)<ContainerProps>(() => ({
  root: {
    maxWidth: 'inherit !important',
  },
}));

const ForgotButton = styled(Typography)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  width: 'fit-content',
  justifyContent: 'flex-end',
  fontWeight: 600,
  fontSize: '14px',
  color: 'rgb(2, 126, 222)',
  cursor: 'pointer',
  userSelect: 'none',
  marginLeft: 'auto',
  padding: '6px 8px',
}));

const SignUpButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  fontWeight: 600,
  padding: 0,
}));

const SignUpText = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  color: theme.palette.common.white,
}));

const AutoLogoutText = styled(Typography)<TypographyProps>(() => ({
  display: 'flex',
  color: 'red',
}));

const CoverTitle = styled(Typography)<TypographyProps>(() => ({
  background: 'linear-gradient(89.68deg, #3BA6F9 10.34%, #36ADF3 18.23%, #00F6C0 94.46%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 600,
  textAlign: 'center',
  margin: 5,
}));

const CoverSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const StyledPlayer = styled(Player)(() => ({
  height: '40vh', width: '40vw',
}));

export {
  LoginContainerGrid,
  LoginInnerBlock,
  LoginContainer,
  LoginLeftItemGrid,
  LoginRightItemGrid,
  ForgotButton,
  SignUpButton,
  SignUpText,
  CoverTitle,
  CoverSubTitle,
  StyledPlayer,
  AutoLogoutText,
  LoginContainerForm,
};
