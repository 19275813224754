import { Grid, Skeleton } from '@mui/material';
import React from 'react';
import {
  LoaderDataContainer,
  LoaderContainer,
  LoaderTextContainer,
} from '../styles';

const ProjectSkeleton = () => (
  <Grid item xs={12} md={6} lg={4}>
    <LoaderContainer>
      <LoaderDataContainer>
        <Skeleton variant="circular" width={45} height={40} />
        <LoaderTextContainer>
          <Skeleton
            sx={{
              fontSize: '20px',
              width: '100%',
            }}
            variant="text"
          />
          <Skeleton sx={{ fontSize: '20px' }} variant="text" width={100} />
        </LoaderTextContainer>
      </LoaderDataContainer>
      <Skeleton
        sx={{
          margin: '16px 16px 16px 0px',
        }}
        variant="circular"
        width={42}
        height={35}
      />
    </LoaderContainer>
  </Grid>
);

export default ProjectSkeleton;
