import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tourInfo } from '../../services/apiDefinition';
import axiosApiInstance from '../../services/axios';
import { AppDispatch } from '../../store';
import { RootState } from '../../store/rootReducer';
import { completeLogin } from '../../store/slices/authSlice';
import { toggleInventoryImportModal } from '../../store/slices/inventorySlice';
import { toggleOrdersImportModal } from '../../store/slices/ordersSlice';
import { setBannerClose, setDashboard, setGetstaredComplete, setInventory, setOrders, setUserSawProgress } from '../../store/slices/tourSlice';
import { handleModal } from '../../store/slices/welcomeSlice';
import { doLogout } from '../../store/thunks/authThunk';
import { getUserDetails, getUserInvites, getUsers } from '../../store/thunks/settingsThunk';
import { verifyUser } from '../../store/thunks/welcomeThunk';
import { useShallowEqualSelector } from '../../utils/helper';
import { API_FAILURE_STATUS } from '../../utils/constants';

const useProjects = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { reading: ordersReading } = useShallowEqualSelector(
    ({ orders }: RootState) => orders,
  );
  const { userData: authUserData } = useShallowEqualSelector(({ auth }: RootState) => auth);
  const { reading: inventoryReading } =
    useShallowEqualSelector(({ inventory }: RootState) => inventory);
  const {
    recentProjects,
    userStatus,
    companyDataAPIExecutionStatus,
    companyDataAPILoadingStatus,
  } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { userData } = useShallowEqualSelector(
    ({ settings }: RootState) => settings,
  );
  const { openModal } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { createProject, inventory, dashboard, orders, bannerClose, getStartedComplete, userSawProgress } = useShallowEqualSelector(
    ({ tour }: RootState) => tour,
  );
  const { userData: authData } = useShallowEqualSelector(
    ({ auth }: RootState) => auth,
  );
  const { currentProjectData } = useShallowEqualSelector(({ selectedData }: RootState) => selectedData);

  // useEffect(() => {
  //   dispatch(getCardDetails());
  // }, []);

  useEffect(() => {
    if (userStatus.username !== '' && userStatus.username !== undefined) {
      dispatch(getUserDetails());
      dispatch(getUsers());
      dispatch(getUserInvites());
    }
  }, [userStatus]);

  const verifyDetails = () => {
    dispatch(verifyUser());
  };

  const loginComplete = () => {
    dispatch(completeLogin());
  };

  const doLogoutOperation = () => {
    dispatch(doLogout(localStorage.getItem('gf_key')!));
  };

  useEffect(() => {
    if (!companyDataAPILoadingStatus && companyDataAPIExecutionStatus === API_FAILURE_STATUS) {
      navigate('/app/error');
    }
  }, [companyDataAPIExecutionStatus, companyDataAPILoadingStatus]);

  const openProjectModal = () => {
    dispatch(handleModal(true));
  };

  const openInventoryImportModal = () => {
    navigate('/app/inventory');
    dispatch(toggleInventoryImportModal(true));
    if (!inventory) {
      dispatch(setInventory());
      axiosApiInstance.put(tourInfo, { tour: { inventory: true } });
    }
  };
  const openOrdersImportModal = () => {
    navigate('/app/orders');
    dispatch(toggleOrdersImportModal(true));
    if (!orders) {
      dispatch(setOrders());
      axiosApiInstance.put(tourInfo, { tour: { orders: true } });
    }
  };
  const openDashboard = () => {
    navigate('/app/dashboard');
    dispatch(setDashboard());
    axiosApiInstance.put(tourInfo, { tour: { dashboard: true } });
  };
  const closeBanner = () => {
    dispatch(setBannerClose());
    axiosApiInstance.put(tourInfo, { tour: { bannerClose: true } });
  };
  const closeGetStarted = () => {
    dispatch(setGetstaredComplete());
    axiosApiInstance.put(tourInfo, { tour: { getStartedComplete: true } });
  };
  const handleUserSawProgress = () => {
    dispatch(setUserSawProgress());
    axiosApiInstance.put(tourInfo, { tour: { userSawProgress: true } });
  };

  return {
    recentProjects,
    userStatus,
    verifyDetails,
    loginComplete,
    userData,
    createProject,
    openProjectModal,
    openModal,
    openInventoryImportModal,
    openOrdersImportModal,
    inventory,
    dashboard,
    openDashboard,
    orders,
    authData,
    bannerClose,
    closeBanner,
    closeGetStarted,
    getStartedComplete,
    userSawProgress,
    handleUserSawProgress,
    ordersReading,
    inventoryReading,
    authUserData,
    currentProjectData,
    doLogoutOperation,
  };
};

export default useProjects;
