import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CoverSubTitle,
  CoverTitle,
  SignupInnerBlock,
  SignupRightItemGrid,
} from '../styles';
import lottieJson from '../../../assets/images/with_sw.json';
import { StyledPlayer } from '../../login/styles';

const SignupImage = () => {
  const { t } = useTranslation();
  return (
    <SignupRightItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <SignupInnerBlock>
        <StyledPlayer autoplay loop src={JSON.stringify(lottieJson)} />
        <CoverTitle variant="h4">{t('signup.signup_cover_title')}</CoverTitle>
        <CoverSubTitle variant="h6">
          {t('signup.signup_cover_subtitle1')}
        </CoverSubTitle>
        <CoverSubTitle variant="h6">
          {t('signup.signup_cover_subtitle2')}
        </CoverSubTitle>
      </SignupInnerBlock>
    </SignupRightItemGrid>
  );
};

export default SignupImage;
