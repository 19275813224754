import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { selectedDataReducer } from '../../utils/types/reducerStateTypes';
import { getProjectDetails } from '../thunks/projectThunk';
import {
  getCurrentProject,
  getDashboardUrls,
  updateCurrentProject,
  updateUITheme,
} from '../thunks/selectedDataThunk';
import { NULL_STRING } from '../../utils/constants';

export const selectedDataAdapter = createEntityAdapter();

const initialState: selectedDataReducer = {
  projectAPIFaliureStatus: false,
  projectAPILoadingStatus: false,
  currentProject: NULL_STRING,
  currentAreaId: '',
  currentProjectData: null,
  projectDataAPILoadingStatus: false,
  projectDataAPIFaliureStatus: false,
  dashboardUrls: {},
  currentDashboardUrl: '',
  dashboardTabIndex: 0,
  uiTheme: 'light',
};

const selectedDataSlice = createSlice({
  name: 'selectedData',
  initialState,
  reducers: {
    // switchProject: (state, action) => {
    //   state.currentProject = action.payload;
    // },
    switchArea: (state, action) => {
      state.currentAreaId = action.payload;
    },
    switchDashboardUrl: (state, action) => {
      state.currentDashboardUrl = action.payload.split('?')[0];
    },
    switchDashboardTabIndex: (state, action) => {
      state.dashboardTabIndex = action.payload;
    },
  },
  extraReducers: {
    [getCurrentProject.pending.type]: state => {
      state.projectAPILoadingStatus = true;
      state.projectAPIFaliureStatus = false;
    },
    [getCurrentProject.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIFaliureStatus = false;
      if (action.payload.state === null) {
        state.currentProject = NULL_STRING;
      } else if (!action.payload.state.current_project) {
        state.currentProject = NULL_STRING;
      } else {
        state.currentProject = action.payload.state.current_project;
      }

      if (
        action.payload.state !== null &&
        action.payload.state.ui_theme !== undefined
      ) {
        state.uiTheme = action.payload.state.ui_theme;
      }
    },
    [getCurrentProject.rejected.type]: state => {
      state.projectAPILoadingStatus = false;
      state.projectAPIFaliureStatus = true;
    },

    [updateCurrentProject.pending.type]: state => {
      state.projectAPILoadingStatus = true;
      state.projectAPIFaliureStatus = false;
    },
    [updateCurrentProject.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.projectAPIFaliureStatus = false;
      if (action.payload.state === null) {
        state.currentProject = NULL_STRING;
      } else if (
        action.payload.state.current_project !== undefined &&
        action.payload.state.current_project === null
      ) {
        state.currentProject = NULL_STRING;
      } else {
        state.currentProject = action.payload.state.current_project;
      }
    },
    [updateCurrentProject.rejected.type]: state => {
      state.projectAPILoadingStatus = false;
      state.projectAPIFaliureStatus = true;
    },

    [getProjectDetails.pending.type]: state => {
      state.projectDataAPILoadingStatus = true;
      state.projectDataAPIFaliureStatus = false;
    },
    [getProjectDetails.fulfilled.type]: (state, action) => {
      state.projectDataAPILoadingStatus = false;
      state.currentProjectData = action.payload;
    },
    [getProjectDetails.rejected.type]: state => {
      state.projectDataAPILoadingStatus = false;
      state.projectDataAPIFaliureStatus = true;
    },

    [getDashboardUrls.pending.type]: state => {
      state.projectAPILoadingStatus = true;
      state.projectAPIFaliureStatus = false;
    },
    [getDashboardUrls.fulfilled.type]: (state, action) => {
      state.projectAPILoadingStatus = false;
      state.dashboardUrls = action.payload;
      state.currentDashboardUrl = action.payload.dashboards[0].url.split('?')[0];
      state.dashboardTabIndex = 0;
    },
    [getDashboardUrls.rejected.type]: state => {
      state.projectAPILoadingStatus = false;
      state.projectAPIFaliureStatus = true;
    },

    [updateUITheme.fulfilled.type]: (state, action) => {
      state.uiTheme = action.payload.state.ui_theme;
    },
  },
});

export const { switchArea, switchDashboardUrl, switchDashboardTabIndex } = selectedDataSlice.actions;

export default selectedDataSlice;
