import React, { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  SendButton,
  PasswordInnerBlock,
  PasswordLeftItemGrid,
  PasswordTitle,
  PasswordSubTitle,
  BackToLoginButton,
  FormContainer,
  TextFieldContainer,
} from '../styles';
import { FormikPasswordInputField } from '../../../components';
import { LoginFormValues } from '../../../utils/types/formTypes';

const CreatePasswordSchema = Yup.object().shape({
  oldpassword: Yup.string().min(6).max(28).required('Required'),
  newpassword: Yup.string().min(6).max(28).required('Required'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
    .required('Required'),
});

const PasswordForm = () => {
  const { t } = useTranslation();
  const {
    register,
  } = useForm<LoginFormValues>();

  useEffect(() => {
    register('username', { required: true });
    register('password', { required: true });
  }, [register]);

  return (
    <PasswordLeftItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <PasswordInnerBlock>
        <PasswordTitle variant="h4">{t('password.cover_title')}</PasswordTitle>
        <PasswordSubTitle variant="body1">
          {t('password.cover_subtitle')}
        </PasswordSubTitle>

        <Formik
          initialValues={{
            newpassword: '',
            confirmpassword: '',
          }}
          validationSchema={CreatePasswordSchema}
          onSubmit={() => {}}
        >
          {() => (
            <FormContainer>
              <TextFieldContainer>
                <Field
                  name="newpassword"
                  label={t('password.new_password_text')}
                  component={FormikPasswordInputField}
                />
                <Field
                  name="confirmpassword"
                  label={t('password.confirm_password_text')}
                  component={FormikPasswordInputField}
                />
              </TextFieldContainer>
              <TextFieldContainer>
                <SendButton
                  title={t('password.create_button')}
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                />
                <BackToLoginButton
                  startIcon={<ArrowBackIcon />}
                  title={t('forgot.back_to_login_button')}
                  variant="text"
                  fullWidth
                />
              </TextFieldContainer>
            </FormContainer>
          )}
        </Formik>
      </PasswordInnerBlock>
    </PasswordLeftItemGrid>
  );
};

export default PasswordForm;
