import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

export const areasAdapter = createEntityAdapter();

const areasSlice = createSlice({
  name: 'areasData',
  initialState: areasAdapter.getInitialState(),
  reducers: {
    setAreas: areasAdapter.setAll,
  },
});

export const areasSelectors = areasAdapter.getSelectors(
  (state: RootState) => state.areasData,
);

export const { setAreas } = areasSlice.actions;

export default areasSlice;
