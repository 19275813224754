import { Box, keyframes, styled } from '@mui/material';

const GreetingsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '600px',
  height: '460px',
}));

const GreetingsHead = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  paddingBottom: '10px',
  '& .MuiIconButton-root': {
    height: 'fit-content',
    width: 'fit-content',
    marginLeft: 'auto',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '& svg': {
    margin: 'auto',
  },
  background:
    'linear-gradient(274.72deg, #23C673 -7.54%, #249ACC 43.58%, #009DC2 130.71%)',
}));

const GreetingsBody = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.background.dark,
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiTypography-subtitle1': {
    fontWeight: '600',
    paddingBottom: '10px',
  },
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '& .MuiButton-root': {
    textTransform: 'capitalize',
  },
  '& .MuiButton-text': {
    color: theme.palette.text.light,
  },
  '& .end-actions': {
    marginLeft: 'auto',
  },
}));

const pulse = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const TooltipBody = styled(Box)(({ theme }) => ({
  animation: `${pulse} 1s ease-in-out`,
  width: '393px',
  height: '209px',
  display: 'flex',
  flexDirection: 'column',
  padding: '15px',
  backgroundColor: theme.palette.background.dark,
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .MuiTypography-subtitle1': {
    fontWeight: '600',
    paddingBottom: '10px',
  },
  '& .MuiTypography-caption': {
    fontWeight: '600',
    color: theme.palette.primary.light,
  },
  '& .MuiIconButton-root': {
    color: theme.palette.text.primary,
    width: 'fit-content',
    marginLeft: 'auto',
  },
  '& .tourActions': {
    marginTop: 'auto',
  },
  overflow: 'hidden',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export {
  GreetingsContainer,
  GreetingsHead,
  GreetingsBody,
  ActionsContainer,
  TooltipBody,
};
