import React from 'react';
import { Button, Typography } from '@mui/material';
import Modal from '../../../components/Modal';
import { NewModal, ButtonBox } from '../styles';

type propTypes = {
  open: boolean;
  onClose: () => void;
  messageText: string;
};

const DeleteConfirmation = ({ open, onClose, messageText }: propTypes) => {
  console.log('data');
  return (
    <Modal open={open} onClose={onClose}>
      <NewModal>
        <Typography>{messageText}</Typography>
        <ButtonBox>
          <Button onClick={onClose}>Okay</Button>
        </ButtonBox>
      </NewModal>
    </Modal>
  );
};

export default DeleteConfirmation;
