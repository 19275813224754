import React, { useEffect, useState } from 'react';
import NavigationBar from '../../components/AppBar';
import {
  AppbarMainContainer,
  BodyContainer,
  MainContainer,
} from '../../components/Containers';
import SideBar from '../../components/SideBar/sideBar';
import { useShallowEqualSelector } from '../helper';
import { RootState } from '../../store';
import { API_FAILURE_STATUS } from '../constants';

const Navigation = ({ children }: any) => {
  const [toShowNavigate, setToShowNavigate] = useState(false);
  const isAuthenticated = localStorage.getItem('authToken');

  const [mobileSideBar, setMobileSideBar] = useState(false);
  const {
    companyDataAPIExecutionStatus,
    companyDataAPILoadingStatus,
    userStatus,
  } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setToShowNavigate(false);
    } else {
      setToShowNavigate(true);
    }
  }, [isAuthenticated]);
  const isToHideNav = (!toShowNavigate ||
    (!companyDataAPILoadingStatus && companyDataAPIExecutionStatus === API_FAILURE_STATUS) ||
    (!userStatus.loginLoading && (!userStatus.verified || userStatus.loginFaliure)));
  if (isToHideNav) {
    return <>{children}</>;
  }

  return (
    <BodyContainer>
      <SideBar mobileSideBar={mobileSideBar} setMobileSideBar={setMobileSideBar} />
      <AppbarMainContainer>
        <NavigationBar mobileSideBar={mobileSideBar} setMobileSideBar={setMobileSideBar} />
        <MainContainer>{children}</MainContainer>
      </AppbarMainContainer>
    </BodyContainer>
  );
};

export default Navigation;
