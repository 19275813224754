import React, { createContext, useCallback, useState } from 'react';
import { fabric } from 'fabric';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { POLYGON, WALLCOLOR, FORBIDDEN, DISPATCH, STARTING, RACKCOLOR, RACKOUTLINE, DISPATCHCOLOR, STARTINGCOLOR, DISPATCHOUTLINE, STARTINGOUTLINE, FORBIDDENCOLOR, FORBIDDENOUTLINE, RACK } from '../../../utils/constants';
import { AppDispatch } from '../../../store';
import { resetClickedArea, setClickedArea } from '../../../store/slices/optimizerTabSlice';

type canvasOptionsType = {
  width: number;
  height: number;
};
type imageDimensionsType = {
  width: number | null;
  height: number | null;
};
type FabricContextType = {
  canvas: fabric.Canvas | null;
  initCanvas: (el: HTMLCanvasElement | null, options: canvasOptionsType, mapImg: string, jsonData: any) => Promise<(() => void) | undefined>;
  activeObject: fabric.Object | null;
  setActiveObject: React.Dispatch<React.SetStateAction<fabric.Object | null>>;
  resetZoom: () => void;
};

const initialContext: FabricContextType = {} as FabricContextType;

export const OptimizerContext = createContext<FabricContextType>(initialContext);

export const OptimizerContextProvider = React.memo(({ children }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);
  let isDragging: boolean = false;
  let lastPosX: any = null;
  let lastPosY: any = null;

  const imgDimensions: imageDimensionsType = { width: null, height: null };

  const constructWallFromJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    // scale points using ratio
    const newPoints = json.walls.vertices.map((point: any) => ({ x: point.x * ratio, y: point.y * ratio }));
    // construct polygon
    const polygon = new fabric.Polygon(newPoints, {
      stroke: WALLCOLOR,
      strokeWidth: 5,
      fill: 'transparent',
      transparentCorners: false,
      selectable: false,
      hoverCursor: 'default',
    });
    polygon['id' as keyof typeof polygon] = new Date().getTime();
    c.add(polygon);
    c.getObjects().forEach(object => {
      if (object.type === POLYGON) {
        c.sendToBack(object);
      }
    });
  };

  const constructForbiddenAreasJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    json.forbidden_zones.forEach((forbiddenArea: any) => {
      const rect = new fabric.Rect({
        left: forbiddenArea.virtual_properties.top_left_x * ratio,
        top: forbiddenArea.virtual_properties.top_left_y * ratio,
        width: forbiddenArea.virtual_properties.horizontal_length * ratio,
        height: forbiddenArea.virtual_properties.vertical_length * ratio,
        fill: FORBIDDENCOLOR,
        stroke: FORBIDDENOUTLINE,
        strokeWidth: 2,
        opacity: 0.8,
        angle: forbiddenArea.virtual_properties.angle,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
      });
      rect['id' as keyof typeof rect] = new Date().getTime();
      rect['group_name' as keyof typeof rect] = forbiddenArea.name;
      rect['area_type' as keyof typeof rect] = FORBIDDEN;
      c.add(rect);
    });
  };

  const constructPickerPointsFromJson = async (c: fabric.Canvas, json: any, ratio: number, key: string) => {
    json[key].forEach((dispatchPoint: any) => {
      const rect = new fabric.Rect({
        left: dispatchPoint.virtual_properties.top_left_x * ratio,
        top: dispatchPoint.virtual_properties.top_left_y * ratio,
        width: dispatchPoint.virtual_properties.horizontal_length * ratio,
        height: dispatchPoint.virtual_properties.vertical_length * ratio,
        fill: key === 'picker_starting_point' ? STARTINGCOLOR : DISPATCHCOLOR,
        stroke: key === 'picker_starting_point' ? STARTINGOUTLINE : DISPATCHOUTLINE,
        strokeWidth: 2,
        opacity: 0.8,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
      });
      rect['id' as keyof typeof rect] = new Date().getTime();
      rect['group_name' as keyof typeof rect] = dispatchPoint.name;
      rect['area_type' as keyof typeof rect] = key === 'picker_starting_point' ? STARTING : DISPATCH;
      c.add(rect);
    });
  };

  const constructSlotsFromJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    json.racks.forEach((rack: any) => {
      rack.bays.forEach((bay: any) => {
        const rect = new fabric.Rect({
          left: bay.virtual_properties.top_left_x * ratio,
          top: bay.virtual_properties.top_left_y * ratio,
          width: bay.virtual_properties.horizontal_length * ratio,
          height: bay.virtual_properties.vertical_length * ratio,
          fill: RACKCOLOR,
          stroke: RACKOUTLINE,
          strokeWidth: 2,
          angle: bay.virtual_properties.angle_deg,
        });
        // lock rect movement & scaling
        rect.lockMovementX = true;
        rect.lockMovementY = true;
        rect.lockScalingX = true;
        rect.lockScalingY = true;
        rect.lockRotation = true;
        rect.hasControls = false;
        rect.hasBorders = true;
        rect.padding = 2;
        rect.borderColor = 'red';
        rect['group_name' as keyof typeof rect] = rack.rack_label;
        rect['bay_name' as keyof typeof rect] = bay.bay_label;
        rect['details' as keyof typeof rect] = rack.details;
        rect['area_type' as keyof typeof rect] = 'Rack';
        rect['row_num' as keyof typeof rect] = bay.row_column[0];
        rect['col_num' as keyof typeof rect] = bay.row_column[1];
        rect['group_properties' as keyof typeof rect] = {
          width: rack.virtual_properties.horizontal_length * ratio,
          height: rack.virtual_properties.vertical_length * ratio,
          left: rack.virtual_properties.top_left_x * ratio,
          top: rack.virtual_properties.top_left_y * ratio,
          angle: rack.virtual_properties.angle,
        };
        rect['levels' as keyof typeof rect] = bay.levels;
        c.add(rect);
      });
    });
  };

  const parseToFabricJSON = async (c: fabric.Canvas, json: any, ratio: number) => {
    dispatch(resetClickedArea());
    if (json.walls && json.walls.vertices && json.walls.vertices.length > 0) {
      constructWallFromJson(c, json, ratio);
    }
    if (json.racks && json.racks.length > 0) {
      constructSlotsFromJson(c, json, ratio);
    }
    if (json.forbidden_zones && json.forbidden_zones.length > 0) {
      constructForbiddenAreasJson(c, json, ratio);
    }
    if (json.picker_dispatch_point && json.picker_dispatch_point.length > 0) {
      constructPickerPointsFromJson(c, json, ratio, 'picker_dispatch_point');
    }
    if (json.picker_starting_point && json.picker_starting_point.length > 0) {
      constructPickerPointsFromJson(c, json, ratio, 'picker_starting_point');
    }
  };

  const getAreaData = async (activeObj: any) => {
    if (activeObj) {
      if (activeObj['details' as keyof typeof activeObj]) {
        const rackObj = {
          areaType: activeObj['area_type' as keyof typeof activeObj],
          label: activeObj['bay_name' as keyof typeof activeObj],
          data: activeObj['details' as keyof typeof activeObj],
          items: activeObj['items' as keyof typeof activeObj],
        };
        dispatch(setClickedArea(rackObj));
      } else if (activeObj['area_type' as keyof typeof activeObj] && activeObj['area_type' as keyof typeof activeObj] !== RACK) {
        const rackObj = {
          areaType: activeObj['area_type' as keyof typeof activeObj],
          label: activeObj['group_name' as keyof typeof activeObj],
        };
        dispatch(setClickedArea(rackObj));
      }
    }
  };

  const initCanvas = useCallback(async (
    el: HTMLCanvasElement | null,
    options: canvasOptionsType,
    mapImg: string,
    jsonData: any,
  ) => {
    if (!el || mapImg === '') {
      return;
    }
    const canvasOptions = {
      preserveObjectStacking: true,
      selection: true,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      ...options,
    };
    const maxWidth = $('#optimizer-map-container').width()!;
    const maxHeight = $('#optimizer-map-container').height()!;
    // calculate the width and height, constraining the proportions
    const c = new fabric.Canvas(el, canvasOptions);

    const cleanup = () => {
      c.dispose();
      c.off('mouse:down');
      c.off('mouse:move');
      c.off('mouse:up');
      c.off('mouse:wheel');
      c.off('selection:created');
    };

    await fabric.Image.fromURL(mapImg, img => {
      imgDimensions.width = img.width!;
      imgDimensions.height = img.height!;
      const ratio = Math.min(maxWidth / img.width!, maxHeight / img.height!);
      img.scaleX = ratio;
      img.scaleY = ratio;
      // resize the canvas to the new image size
      c.setWidth(img.width! * ratio);
      c.setHeight(img.height! * ratio);
      c.setBackgroundImage(img, c.renderAll.bind(c));
      parseToFabricJSON(c, jsonData, ratio);
    });

    c.on('mouse:wheel', opt => {
      const delta = opt.e.deltaY;
      let zoomValue = c.getZoom();
      zoomValue *= 0.999 ** delta;
      if (zoomValue > 20) zoomValue = 20;
      if (zoomValue < 0.01) zoomValue = 0.01;
      if (zoomValue <= 2 && zoomValue >= 0.1) {
        c.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomValue);
      }
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    c.on('mouse:down', o => {
      const evt = o.e;
      if (o.button === 1) {
        dispatch(resetClickedArea());
        getAreaData(c.getActiveObject());
      }
      if (evt.altKey === true) {
        isDragging = true;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      }
    });
    c.on('mouse:move', (opt) => {
      if (isDragging) {
        const { e } = opt;
        const vpt: any = c.viewportTransform;
        vpt[4] += e.clientX - lastPosX;
        vpt[5] += e.clientY - lastPosY;
        c.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
      }
    });
    c.on('mouse:up', () => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      // @ts-ignore
      c.setViewportTransform(c?.viewportTransform);
      isDragging = false;
    });
    c.on('selection:created', o => {
      if (!o) {
        return;
      }
      const activeObj = c!.getActiveObject();
      if (activeObj) {
        // if active object is group disable controls, movement and rotation
        activeObj.set({
          hasControls: false,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
        });
      }
      c!.renderAll();
    });

    setCanvas(c);

    return cleanup;
  }, [canvas, setCanvas]);

  const resetZoom = () => {
    canvas?.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  return (
    <OptimizerContext.Provider
      // eslint-disable-next-line
      value={{
        canvas,
        initCanvas,
        activeObject,
        setActiveObject,
        resetZoom,
      }}
    >
      {children}
    </OptimizerContext.Provider>
  );
});
