import { createSlice } from '@reduxjs/toolkit';
import { getCompanyData, verifyUser } from '../thunks/welcomeThunk';
import {
  API_PENDING_STATUS,
  API_SUCCESS_STATUS,
  API_FAILURE_STATUS,
  NULL_STRING,
} from '../../utils/constants';
import { recentProjectsReducer } from '../../utils/types/reducerStateTypes';

const initialState: recentProjectsReducer = {
  recentProjects: [],
  currentProject: NULL_STRING,
  companyDataAPIExecutionStatus: API_PENDING_STATUS,
  companyDataAPILoadingStatus: false,
  companyDataLoaded: false,
  companyData: undefined,
  openModal: false,
  userStatus: {
    loginLoading: true,
    loginFaliure: false,
    name: '',
    role: '',
    verified: false,
    username: '',
    card: '',
    brand: '',
    month: '',
    year: '',
    cardholderName: '',
  },
};

const welcomeSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    switchProject: (state, action) => {
      state.currentProject = action.payload;
    },
    companyDataLoaded: (state, action) => {
      state.companyDataLoaded = action.payload;
    },
    setRecentProjects: (state, action) => {
      state.recentProjects = action.payload;
    },
    handleModal: (state, action) => {
      state.openModal = action.payload;
    },
  },
  extraReducers: {
    [getCompanyData.pending.type]: state => {
      state.companyDataAPILoadingStatus = true;
    },
    [getCompanyData.fulfilled.type]: (state, action) => {
      state.companyDataAPILoadingStatus = false;
      state.companyDataAPIExecutionStatus = API_SUCCESS_STATUS;
      state.companyData = action.payload;
    },
    [getCompanyData.rejected.type]: state => {
      state.companyDataAPILoadingStatus = false;
      state.companyDataAPIExecutionStatus = API_FAILURE_STATUS;
    },

    [verifyUser.pending.type]: state => {
      state.userStatus.loginFaliure = false;
      state.userStatus.loginLoading = true;
    },
    [verifyUser.fulfilled.type]: (state, action) => {
      state.userStatus.loginLoading = false;
      state.userStatus.name = action.payload.fullname;
      state.userStatus.role = action.payload.role;
      state.userStatus.verified = action.payload.verified;
      state.userStatus.username = action.payload.username;
      state.userStatus.card = action.payload.card_last_four_digits;
      state.userStatus.brand = action.payload.brand;
      state.userStatus.month = action.payload.card_exp_month;
      state.userStatus.year = action.payload.card_exp_year;
      state.userStatus.cardholderName = action.payload.card_holder_name;
    },
    [verifyUser.rejected.type]: state => {
      state.userStatus.loginLoading = false;
      state.userStatus.loginFaliure = true;
    },
  },
});

export const {
  switchProject,
  companyDataLoaded,
  setRecentProjects,
  handleModal,
} = welcomeSlice.actions;

export default welcomeSlice.reducer;
