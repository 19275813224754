import React from 'react';
import { useTranslation } from 'react-i18next';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '../../Button';
import {
  ReportModalContainer,
  SignUpSuccessMessage,
  SignUpSuccessDesc,
  SignUpIcon,
  SignupIconBlock,
  SuccessButtonsBox,
} from '../styles';

const ReportSuccess = ({ isModalVisible, onCloseModal }:
    { isModalVisible: Boolean, onCloseModal: () => void }) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={Boolean(isModalVisible)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={Boolean(isModalVisible)}>
        <ReportModalContainer>
          <SignupIconBlock>
            <SignUpIcon fontSize="large" />
          </SignupIconBlock>
          <SignUpSuccessMessage>{t('reportBug.report_successful')}</SignUpSuccessMessage>
          <SignUpSuccessDesc>
            {t('reportBug.report_successful_desc')}
          </SignUpSuccessDesc>
          <SuccessButtonsBox>
            <Button
              size="medium"
              color="primary"
              variant="contained"
              className="action"
              title={t('reportBug.report_success_button_text')}
              onClick={onCloseModal}
            />
          </SuccessButtonsBox>
        </ReportModalContainer>
      </Fade>
    </Modal>
  );
};

export default ReportSuccess;
