import React from 'react';
import { FormControl, FormHelperText, InputLabel, Select as MUISelect } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormikSelectProps } from '../../utils/types/inputTypes';

const Select = styled(MUISelect)(
  ({ theme }) => `
  & .MuiSvgIcon-root {
    color: ${theme.palette.text.secondary};
  }
`,
);

const FormikSelectField = ({ field, form, label, ...props }: FormikSelectProps) => {
  const error = form.errors[field.name] && form.touched[field.name];
  const errmsg = form.errors[field.name];
  return (
    <FormControl sx={{ width: '100%' }} variant="outlined">
      <InputLabel id={`${field.name}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${field.name}-select-label`}
        id={field.name}
        fullWidth
        label={label}
        {...props}
        {...field}
        error={error}
      >
        {props.children}
      </Select>
      {error && (
        <FormHelperText id={`${field.name}-helper-text`} sx={{ color: error ? 'red' : 'initial' }}>
          {errmsg}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelectField;
