import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// // @ts-ignore
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import GetStartedBlock from './components/getStartedBlock';
import UserGuideItem from './components/UserGuideItem';
import WelcomeBlock from './components/welcomeBlock';
import { Main, UserGuideTitle, TypoProjectButton } from './styles';
import useProjects from './useProjects';
import WelcomeTour from '../../tour/welcomeTour';
import Greetings from '../../tour/greetings';
import ProjectsList from './components/ProjectsList';

const WelcomePage = () => {
  const { t } = useTranslation();
  // const tawkMessengerRef = useRef<TawkMessengerReact>();
  const navigate = useNavigate();
  const UserGuides = [
    {
      guideName: t('welcome.user_guide_1'),
      desc: t('welcome.user_guide_desc'),
    },
    // {
    //   guideName: t('welcome.user_guide_2'),
    //   desc: t('welcome.user_guide_desc'),
    // },
  ];
  const {
    recentProjects,
    userStatus,
    verifyDetails,
    loginComplete,
    userData,
    getStartedComplete,
    ordersReading,
    inventoryReading,
    currentProjectData,
  } = useProjects();

  useEffect(() => {
    verifyDetails();
  }, []);

  useEffect(() => {
    if (!userStatus.loginLoading) {
      if (!userStatus.verified || userStatus.loginFaliure) {
        navigate('/app/error', { replace: true });
      } else {
        loginComplete();
      }
    }
  }, [userStatus.loginLoading]);

  // useEffect(() => {
  //   if (Object.keys(authData).length === 0 && tawkMessengerRef.current) {
  //     tawkMessengerRef.current.hideWidget();
  //   }
  // }, [authData]);

  return (
    <Main sx={{ overflow: 'auto' }}>
      {/* <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
        customStyle={{ zIndex: 999 }}
        ref={tawkMessengerRef}
      /> */}
      {!(userStatus.loginLoading || ordersReading || inventoryReading) && (
        <Greetings />
      )}
      {!(userStatus.loginLoading || ordersReading || inventoryReading) && (
        <WelcomeTour />
      )}
      <WelcomeBlock name={`${userData.first_name} ${userData.last_name}`} />
      {!getStartedComplete &&
        !(
          currentProjectData &&

          currentProjectData.is_sample_project
        ) && <GetStartedBlock />}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <UserGuideTitle variant="h6">
          {t('welcome.recent_projects')}
        </UserGuideTitle>
        <Button variant="text" onClick={() => navigate('/app/all-projects')}>
          <TypoProjectButton variant="subtitle2">
            {t('appbar.view_projects_button')}
          </TypoProjectButton>
        </Button>
      </Box>
      <ProjectsList
        isLoading={userStatus.loginLoading}
        projectData={recentProjects}
      />
      <UserGuideTitle variant="h6">User Guides</UserGuideTitle>
      <Grid container spacing={3}>
        {UserGuides.map(({ guideName, desc }) => (
          <Grid item md={6} key={guideName}>
            <UserGuideItem
              name={guideName}
              desc={desc}
              btnText={t('welcome.user_guide_btn')}
              link={process.env.REACT_APP_PROJECT_DOCS_LINK!}
            />
          </Grid>
        ))}
      </Grid>
    </Main>
  );
};

export default WelcomePage;
