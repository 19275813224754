import React from 'react';
import {
  Menu,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import ActionMenuItem from './actionMenuItem';
import { ActionMenuContainer } from '../styles';

type propTypes = {
  anchorEl: null | HTMLButtonElement;
  handleClose: () => void;
  open: boolean;
  editModalOpen: () => void;
  deleteModalOpen: () => void;
  disabled: boolean;
};

const ActionMenu = ({
  anchorEl,
  handleClose,
  open,
  editModalOpen,
  deleteModalOpen,
  disabled,
}: propTypes) => {
  const { t } = useTranslation();
  const openEditModal = () => {
    handleClose();
    editModalOpen();
  };
  const openDeleteModal = () => {
    handleClose();
    deleteModalOpen();
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ActionMenuContainer>
        {!disabled && (
          <ActionMenuItem
            icon={<EditIcon />}
            handleClick={openEditModal}
            text={t('allProjects.edit_project_button')}
            disabled={disabled}
          />
        )}
        <ActionMenuItem
          icon={<DeleteIcon />}
          handleClick={openDeleteModal}
          text={t('allProjects.delete_project_button')}
        />
      </ActionMenuContainer>
    </Menu>
  );
};

export default ActionMenu;
