import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountMenuContainer, ItemIconButton } from '../styles';
import useAppbar from '../useAppbar';
import {
  areasComplete,
  devicesComplete,
  reactFlowComplete,
  welcomeComplete,
} from '../../../store/slices/tourSlice';

type Props = {
  open: boolean;
  anchorEl: null | HTMLButtonElement;
  handleClose: () => void;
  tourClicked: boolean;
};

const isSystemBVisible = process.env.REACT_APP_SHOW_SYSTEM_BUILDER === 'true';
const isDeviceVisible = process.env.REACT_APP_SHOW_DEVICES === 'true';
const GetStarted = ({ open, handleClose, anchorEl, tourClicked }: Props) => {
  const navigate = useNavigate();
  const { welcomeTour, areasTour, reactFlowTour, devicesTour, dispatch } =
    useAppbar();
  const handleClick = (to: string) => {
    handleClose();
    navigate(to);
  };
  return (
    <Menu
      open={open && tourClicked}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={2}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ top: '64px' }}
    >
      <AccountMenuContainer>
        <ListItem
          secondaryAction={
            <ItemIconButton
              edge="end"
              onClick={() => {
                handleClick('/app/welcome');
                dispatch(welcomeComplete(false));
              }}
            >
              <LaunchIcon />
            </ItemIconButton>
          }
        >
          <ListItemIcon>
            <Checkbox
              disabled
              checked={welcomeTour}
              checkedIcon={<CheckCircleIcon />}
              icon={<RadioButtonUncheckedIcon />}
              className={!welcomeTour ? 'notSeenGlow' : ''}
            />
          </ListItemIcon>
          <ListItemText>Welcome</ListItemText>
        </ListItem>
        {isSystemBVisible && (
          <ListItem
            secondaryAction={
              <ItemIconButton
                edge="end"
                onClick={() => {
                  handleClick('/app/system-builder');
                  dispatch(areasComplete(false));
                  dispatch(reactFlowComplete(false));
                }}
              >
                <LaunchIcon />
              </ItemIconButton>
            }
          >
            <ListItemIcon>
              <Checkbox
                disabled
                checked={areasTour && reactFlowTour}
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
                className={!(areasTour && reactFlowTour) ? 'notSeenGlow' : ''}
              />
            </ListItemIcon>
            <ListItemText>System Builder</ListItemText>
          </ListItem>
        )}
        {isDeviceVisible && (
          <ListItem
            secondaryAction={
              <ItemIconButton
                edge="end"
                onClick={() => {
                  handleClick('/app/devices');
                  dispatch(devicesComplete(false));
                }}
              >
                <LaunchIcon />
              </ItemIconButton>
            }
          >
            <ListItemIcon>
              <Checkbox
                disabled
                checked={devicesTour}
                checkedIcon={<CheckCircleIcon />}
                icon={<RadioButtonUncheckedIcon />}
                className={!devicesTour ? 'notSeenGlow' : ''}
              />
            </ListItemIcon>
            <ListItemText>Devices</ListItemText>
          </ListItem>
        )}
      </AccountMenuContainer>
    </Menu>
  );
};

export default GetStarted;
