import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { getProjects, sampleProject } from '../../services/apiDefinition';
import {
  projectCreateResponse,
} from '../../utils/types/apiTypes';
import { projectType } from '../../utils/types/projectTypes';
import { updateCurrentProject } from './selectedDataThunk';
import { getCompanyData } from './welcomeThunk';
import { handleRacksOnprojectUpdate } from '../slices/maplayoutSlice';

type props = {
  obj: any;
  dispatch: any;
  sample?: boolean;
}

export const addProject = createAsyncThunk(
  'project/addProject',
  async ({ obj, dispatch, sample }: props) => {
    try {
      const response = await axiosApiInstance.post<projectCreateResponse>(
        sample ? sampleProject : getProjects,
        obj,
        {
          withCredentials: true,
        },
      );
      await dispatch(updateCurrentProject({ id: response.data.hashed_identifier, dispatch, updateProjectData: false }));
      if (sample) {
        await dispatch(getCompanyData());
      }
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.resp);
      }
    }
  },
);

type updateProjectProps = {
  updatedProject: projectType;
  nameChanged: boolean;
};

export const updateProject = createAsyncThunk(
  'project/updateProject',
  async ({ updatedProject, nameChanged }: updateProjectProps) => {
    const body = nameChanged ? updatedProject : { details: updatedProject.details };
    try {
      const response = await axiosApiInstance.put(
        `${getProjects + updatedProject.hashed_identifier}/`,
        body,
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      } else if (e.response.status === 302) {
        throw new Error(e.response.data.resp);
      }
    }
  },
);

type deleteProjectType = {
  project: projectType;
  onClose: () => void;
  dispatch: any;
  dataModal: (message:any) => void;
};

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async ({ project, dispatch, onClose, dataModal }: deleteProjectType) => {
    try {
      const response = await axiosApiInstance.delete(getProjects + project.hashed_identifier);
      onClose();
      if (response.data.status === false) {
        dataModal(response?.data?.message);
        return null;
      }
      if (project.is_sample_project) {
        dispatch(getCompanyData());
      }
      return project.hashed_identifier;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getProjectDetails = createAsyncThunk(
  'project/getProjectDetails',
  async ({ hashed_identifier, dispatch }: any) => {
    try {
      const response = await axiosApiInstance.get(
        `${getProjects + hashed_identifier}/`,
        {
          withCredentials: true,
        },
      );
      dispatch(handleRacksOnprojectUpdate(response.data));
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
