import { createAsyncThunk } from '@reduxjs/toolkit';
import { optimize } from '../../services/apiDefinition';
import axiosApiInstance from '../../services/axios';

type optimizeType = {
  mapId: string;
  analysis: string;
  values: any;
};

export const optimizeInventory = createAsyncThunk(
  'optimizer/optimizeInventory',
  async ({ mapId, analysis, values }: optimizeType) => {
    try {
      const response = await axiosApiInstance.post(
        `${optimize}${mapId}/`,
        analysis !== 'ai' && values,
        {
          params: { mode: analysis },
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.response);
      }
    }
  },
);

export const getOptimizationResult = createAsyncThunk(
  'optimizer/getOptimizationResult',
  async (mapId: string) => {
    try {
      const response = await axiosApiInstance.get(
        `${optimize}${mapId}/`,
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.response);
      }
    }
  },
);
