import { Close } from '@mui/icons-material';
import { Typography, Box, Button, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GreetingsImage from '../assets/images/greetingsImage';
import { Modal } from '../components';
import {
  GreetingsContainer,
  GreetingsHead,
  GreetingsBody,
  ActionsContainer,
} from './styles';
import useTour from './useTour';

const Greetings = () => {
  const { greeting, skipTour, setGreeting } = useTour();
  const [open, setOpen] = React.useState(greeting);
  const { t } = useTranslation();
  useEffect(() => {
    setOpen(greeting);
  }, [greeting]);

  return (
    <Modal open={!open}>
      <GreetingsContainer>
        <GreetingsHead>
          <IconButton onClick={skipTour}>
            <Close />
          </IconButton>
          <GreetingsImage />
        </GreetingsHead>
        <GreetingsBody>
          <Box>
            <Typography variant="subtitle1">
              {t('tour.greetings.title')}
            </Typography>
            <Typography variant="body2">{t('tour.greetings.body')}</Typography>
          </Box>
          <ActionsContainer>
            <Button variant="text" onClick={skipTour}>
              {t('tour.greetings.skip')}
            </Button>
            <Button variant="contained" onClick={setGreeting}>
              {t('tour.greetings.start')}
            </Button>
          </ActionsContainer>
        </GreetingsBody>
      </GreetingsContainer>
    </Modal>
  );
};

export default Greetings;
