import { Middleware } from 'redux';

const authTokenMiddleware: Middleware = () => (next) => (action) => {
  if (action.type === 'auth/doLogin/fulfilled') {
    localStorage.setItem('authToken', action.payload.key);
    localStorage.setItem('paymentStatus', action.payload.payment_status);
    localStorage.setItem('jwtToken', action.payload.jwt_token);
  }
  next(action);
};

export default authTokenMiddleware;
