import React from 'react';
import { AvatarContainer, AvatarCircle } from '../styles';
import useAppbar from '../useAppbar';

const UserAvatar = () => {
  const { userData } = useAppbar();

  function getStringValue(value: any): string {
    return value.toString();
  }

  return (
    <AvatarContainer>
      {userData.profile_picture === null ||
      userData.profile_picture === '' ||
      userData.profile_picture === undefined ? (
        <AvatarCircle />
        ) : (
          <img
            src={getStringValue(userData.profile_picture).replace(
              'http',
              'https',
            )}
            alt="profile"
          />
        )}
    </AvatarContainer>
  );
};

export default UserAvatar;
