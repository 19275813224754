import { createSlice } from '@reduxjs/toolkit';

import {
  deactivateAccount,
  doLogin,
  doSignup,
  getTerms,
  doLogout,
  sendVerificationEmail,
} from '../thunks/authThunk';
import {
  API_PENDING_STATUS,
  API_SUCCESS_STATUS,
  API_FAILURE_STATUS,
  API_LOGIN_COMPLETE,
} from '../../utils/constants';
import { loginReducer } from '../../utils/types/reducerStateTypes';
import i18 from '../../translation/i18n';

const initialState: loginReducer = {
  loginAPILoadingStatus: false,
  loginAPIExecutionStatus: API_PENDING_STATUS,
  loginAPISuccessMessage: '',
  loginAPIFailureMessage: '',
  signUpAPILoadingStatus: false,
  signUpAPIExecutionStatus: API_PENDING_STATUS,
  signUpAPISuccessMessage: '',
  signUpAPIFailureMessage: '',
  userData: {},
  termsAPILoadingStatus: API_PENDING_STATUS,
  termsData: [],
  deactivateAccountAPILoadingStatus: false,
  deactivateAccountAPISuccessMessage: '',
  deactivateAccountAPIFailureMessage: '',
  deactivateAccountAPIExecutionStatus: API_PENDING_STATUS,
  logoutAPIExecutionStatus: API_PENDING_STATUS,
  logoutData: false,
  loginRedirect: false,
  resendVerificationEmailAPILoadingStatus: false,
  resendVerificationEmailAPIExecutionStatus: API_PENDING_STATUS,
  resendVerificationEmailAPIFailureMessage: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    completeLogin: state => {
      state.loginAPIExecutionStatus = API_LOGIN_COMPLETE;
    },
    resetLogin: state => {
      state.loginAPIExecutionStatus = API_PENDING_STATUS;
      state.userData = {};
      state.logoutData = false;
    },
    resetSignUpData: state => {
      state.signUpAPILoadingStatus = false;
      state.signUpAPIExecutionStatus = API_PENDING_STATUS;
      state.signUpAPISuccessMessage = '';
      state.signUpAPIFailureMessage = '';
    },
    updateUserData: (state, action) => {
      state.userData = action.payload;
    },
    setLoginRedirect: state => {
      state.loginRedirect = true;
    },
    resetResendVerificationEmail: state => {
      state.resendVerificationEmailAPILoadingStatus = false;
      state.resendVerificationEmailAPIExecutionStatus = API_PENDING_STATUS;
      state.resendVerificationEmailAPIFailureMessage = '';
    },
  },
  extraReducers: {
    [doLogin.pending.type]: state => {
      state.loginAPILoadingStatus = true;
    },
    [doLogin.fulfilled.type]: (state, action) => {
      state.loginAPILoadingStatus = false;
      if (action.payload.key) {
        state.userData = action.payload;
        state.loginAPIExecutionStatus = API_SUCCESS_STATUS;
        state.loginAPISuccessMessage = i18.t('login.login_success_message');
      }
    },
    [doLogin.rejected.type]: (state, action) => {
      state.loginAPILoadingStatus = false;
      state.loginAPIExecutionStatus = API_FAILURE_STATUS;
      state.loginAPIFailureMessage = action.error.message;
    },
    [doSignup.pending.type]: state => {
      state.signUpAPILoadingStatus = true;
    },
    [doSignup.fulfilled.type]: state => {
      state.signUpAPILoadingStatus = false;
      state.signUpAPIExecutionStatus = API_SUCCESS_STATUS;
      state.signUpAPISuccessMessage = i18.t('signup.signup_success_message');
    },
    [doSignup.rejected.type]: (state, action) => {
      state.signUpAPILoadingStatus = false;
      state.signUpAPIExecutionStatus = API_FAILURE_STATUS;
      if (action.payload) {
        // Being that we passed in ValidationErrors to rejectType in `createAsyncThunk`, the payload will be available here.
        state.signUpAPIFailureMessage = action.payload.errorMessage;
      } else {
        state.signUpAPIFailureMessage = action.error.message;
      }
    },
    [getTerms.pending.type]: state => {
      state.termsAPILoadingStatus = API_PENDING_STATUS;
    },
    [getTerms.fulfilled.type]: (state, action) => {
      state.termsAPILoadingStatus = API_SUCCESS_STATUS;
      state.termsData = action.payload;
    },
    [getTerms.rejected.type]: (state, action) => {
      state.termsAPILoadingStatus = API_FAILURE_STATUS;
      state.signUpAPIFailureMessage = action.error.message;
    },

    [deactivateAccount.pending.type]: state => {
      state.deactivateAccountAPILoadingStatus = true;
      state.deactivateAccountAPIFailureMessage = '';
      state.deactivateAccountAPISuccessMessage = '';
      state.deactivateAccountAPIExecutionStatus = API_PENDING_STATUS;
    },
    [deactivateAccount.fulfilled.type]: (state, action) => {
      state.deactivateAccountAPILoadingStatus = false;
      state.deactivateAccountAPISuccessMessage = action.payload.message;
      state.deactivateAccountAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [deactivateAccount.rejected.type]: (state, action) => {
      state.deactivateAccountAPILoadingStatus = false;
      state.deactivateAccountAPIFailureMessage = action.error.message;
      state.deactivateAccountAPIExecutionStatus = API_FAILURE_STATUS;
    },
    [doLogout.pending.type]: state => {
      state.loginAPILoadingStatus = true;
    },
    [doLogout.fulfilled.type]: (state, action) => {
      state.logoutAPIExecutionStatus = API_PENDING_STATUS;
      state.loginAPIExecutionStatus = API_PENDING_STATUS;
      state.userData = {};
      state.logoutData = action.payload?.logout;
      state.loginAPILoadingStatus = false;
    },
    [doLogout.rejected.type]: (state) => {
      state.logoutAPIExecutionStatus = API_FAILURE_STATUS;
      state.loginAPILoadingStatus = false;
    },

    [sendVerificationEmail.pending.type]: state => {
      state.resendVerificationEmailAPILoadingStatus = true;
      state.resendVerificationEmailAPIExecutionStatus = API_PENDING_STATUS;
      state.resendVerificationEmailAPIFailureMessage = '';
    },
    [sendVerificationEmail.fulfilled.type]: state => {
      state.resendVerificationEmailAPILoadingStatus = false;
      state.resendVerificationEmailAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [sendVerificationEmail.rejected.type]: (state, action) => {
      state.resendVerificationEmailAPILoadingStatus = false;
      state.resendVerificationEmailAPIExecutionStatus = API_FAILURE_STATUS;
      state.resendVerificationEmailAPIFailureMessage = action.error.message;
    },
  },
});

export const { completeLogin, resetLogin, resetSignUpData, updateUserData, setLoginRedirect, resetResendVerificationEmail } = authSlice.actions;

export default authSlice;
