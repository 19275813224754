import { DataFrame, toCSV } from 'danfojs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { RootState } from '../../store/rootReducer';
import {
  resetOptmizeAPIStatus,
  setActiveStep,
  setCompleted,
  setRunningAnalysis,
  setTabIndex,
} from '../../store/slices/optimizerTabSlice';
import { optimizeInventory } from '../../store/thunks/optimizerThunk';
import { LOCATIONSHEADER } from '../../utils/constants';
import { useShallowEqualSelector } from '../../utils/helper';
import { optimizerTabReducer } from '../../utils/types/reducerStateTypes';

const useOptimizer = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    activeStep,
    completed,
    tabIndex,
    startOptimizeApiExecutionStatus,
    startOptimizeApiFailureMessage,
    startOptimizeApiLoadingStatus,
    getOptimizeApiExecutionStatus,
    getOptimizeApiFailureMessage,
    getOptimizeApiLoadingStatus,
    currentFloorOptimizedData,
    runningAnalysis,
    controlAnalysis,
    floors,
    mapAPILoadingStatus,
    currentMapData,
    currentFloor,
    optimizationPubnubMessages,
    clickedArea,
  }: optimizerTabReducer = useShallowEqualSelector(
    ({ optimizerTab }: RootState) => optimizerTab,
  );
  const { data: ordersData, reading: ordersReading } = useShallowEqualSelector(
    ({ orders }: RootState) => orders,
  );
  const { data: inventoryData, reading: inventoryReading } = useShallowEqualSelector(
    ({ inventory }: RootState) => inventory,
  );
  const { recentProjects } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { currentProject } = useShallowEqualSelector(({ selectedData }: RootState) => selectedData);

  const handleSteperNext = () => {
    if (activeStep === 3) {
      // go back to step 0 & reset the stepper
      dispatch(setActiveStep(0));
      dispatch(setCompleted({ 1: false, 2: false, 3: false }));
      dispatch(resetOptmizeAPIStatus());
      return;
    }
    const newCompleted = { ...completed };
    newCompleted[activeStep] = true;
    dispatch(setCompleted(newCompleted));
    dispatch(setActiveStep(activeStep + 1));
  };

  const handleSteperBack = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep] = false;
    dispatch(setCompleted(newCompleted));
    dispatch(setActiveStep(activeStep - 1));
  };

  const returnKeys = (analysis: string, index: string) => {
    switch (index) {
      case '1':
        return analysis === 'abc' ? 'A' : 'X';
      case '2':
        return analysis === 'abc' ? 'B' : 'Y';
      case '3':
        return analysis === 'abc' ? 'C' : 'Z';
      default:
        return '';
    }
  };

  const startOptimizer = (mapId: string, analysis: string, sliders: any) => {
    if (mapId === '') return;
    dispatch(setRunningAnalysis(analysis));
    const values: any = {};
    Object.keys(sliders).forEach((key) => {
      // eslint-disable-next-line prefer-destructuring
      values[returnKeys(analysis, key)] = sliders[key][1];
    });
    dispatch(optimizeInventory({ mapId, analysis, values }));
  };

  const handleTabChange = (index: number) => {
    dispatch(setTabIndex(index));
  };

  const resetOptimizer = () => {
    dispatch(setRunningAnalysis(null));
    dispatch(resetOptmizeAPIStatus());
    dispatch(setActiveStep(1));
  };

  function createData(
    item: string,
    oldLocation: string,
    newLocation: string,
    requireChanges: string,
  ) {
    return [item, oldLocation, newLocation, requireChanges];
  }

  const exportToCsv = () => {
    const rows =
      Object.keys(currentFloorOptimizedData).length > 0 &&
      currentFloorOptimizedData.improved_dict.initial_sku_to_bin_location_dictionary &&
      currentFloorOptimizedData.improved_dict.updated_sku_to_bin_location_dictionary
        ? Object.keys(
          currentFloorOptimizedData.improved_dict.initial_sku_to_bin_location_dictionary,
        ).map(key =>
          createData(
            key,
            currentFloorOptimizedData.improved_dict.initial_sku_to_bin_location_dictionary[key],
            currentFloorOptimizedData.improved_dict.updated_sku_to_bin_location_dictionary[key],
            currentFloorOptimizedData.improved_dict.initial_sku_to_bin_location_dictionary[key] === currentFloorOptimizedData.improved_dict.updated_sku_to_bin_location_dictionary[key] ? 'No' : 'Yes',
          ))
        : [];
    const df = new DataFrame(rows, { columns: [...LOCATIONSHEADER, 'Require Change'] });
    df.sortValues('Recommended Location', { inplace: true });
    df.sortValues('Require Change', { ascending: false, inplace: true });
    toCSV(df, {
      fileName: 'warehouse_proposed_changes.csv',
      header: true,
      download: true,
    });
  };

  return {
    activeStep,
    completed,
    handleSteperNext,
    handleSteperBack,
    startOptimizer,
    handleTabChange,
    tabIndex,
    floors,
    mapAPILoadingStatus,
    ordersData,
    inventoryData,
    currentMapData,
    startOptimizeApiExecutionStatus,
    startOptimizeApiFailureMessage,
    startOptimizeApiLoadingStatus,
    getOptimizeApiExecutionStatus,
    getOptimizeApiFailureMessage,
    getOptimizeApiLoadingStatus,
    resetOptimizer,
    currentFloorOptimizedData,
    exportToCsv,
    runningAnalysis,
    controlAnalysis,
    currentFloor,
    currentProject,
    optimizationPubnubMessages,
    recentProjects,
    ordersReading,
    inventoryReading,
    clickedArea,
  };
};

export default useOptimizer;
