import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import SystemSecurityUpdateGoodIcon from '@mui/icons-material/SystemSecurityUpdateGood';
import ImageIcon from '@mui/icons-material/Image';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import HelpIcon from '@mui/icons-material/Help';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import { useTranslation } from 'react-i18next';
import { ShoppingCart } from '@mui/icons-material';
import { MenuContainer } from '../styles';
import Item from './item';
import Divider from '../../Divider';

type propTypes = {
  open: boolean;
  toggleSidebar: () => void;
};

const Menu = ({ open, toggleSidebar }: propTypes) => {
  const { t } = useTranslation();
  const isBusinessIVisible =
    process.env.REACT_APP_SHOW_BUSINESS_INSIGHTS === 'true';
  const isDeviceVisible = process.env.REACT_APP_SHOW_DEVICES === 'true';
  const isMapLayoutVisible = process.env.REACT_APP_SHOW_MAP_LAYOUT === 'true';
  const isWarehouseIVisible =
    process.env.REACT_APP_SHOW_WAREHOUSE_INVENTORY === 'true';
  const isOrdersVisible =
    process.env.REACT_APP_SHOW_WAREHOUSE_ORDERS === 'true';
  const isIntegrationsVisible = process.env.REACT_APP_SHOW_INTEGRATIONS === 'true';

  return (
    <MenuContainer>
      <Item
        text={t('sidebar.welcome_tab')}
        icon={<HomeIcon />}
        open={open}
        index={0}
        to="/app/welcome"
        menuRef="welcomeBtn"
        toggleSidebar={toggleSidebar}
      />
      <Item
        text={t('sidebar.dashboard_tab')}
        icon={<DashboardIcon />}
        open={open}
        index={1}
        to="/app/dashboard"
        menuRef="dashboardBtn"
        toggleSidebar={toggleSidebar}
      />
      {isIntegrationsVisible && (
        <Item
          text={t('sidebar.integrations_tab')}
          icon={<DeviceHubIcon />}
          open={open}
          index={2}
          to="/app/integrations"
          menuRef="systemBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      {isDeviceVisible && (
        <Item
          text={t('sidebar.devices_tab')}
          icon={<SystemSecurityUpdateGoodIcon />}
          open={open}
          index={3}
          to="/app/devices"
          menuRef="devicesBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      {isMapLayoutVisible && (
        <Item
          text={t('sidebar.map_layout_tab')}
          icon={<ImageIcon />}
          open={open}
          index={4}
          to="/app/map-layout"
          menuRef="mapLayoutBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      {isWarehouseIVisible && (
        <Item
          text={t('sidebar.inventory_tab')}
          icon={<WarehouseIcon />}
          open={open}
          index={7}
          to="/app/inventory"
          menuRef="inventoryBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      {isOrdersVisible && (
        <Item
          text={t('sidebar.orders_tab')}
          icon={<ShoppingCart />}
          open={open}
          index={8}
          to="/app/orders"
          menuRef="ordersBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      <Item
        text={t('sidebar.optimisation_tab')}
        icon={<DisplaySettingsIcon />}
        open={open}
        index={9}
        to="/app/optimisation"
        menuRef="optimisationBtn"
        toggleSidebar={toggleSidebar}
      />
      {isBusinessIVisible && (
        <Item
          text={t('sidebar.business_insight_tab')}
          icon={<AnalyticsIcon />}
          open={open}
          index={5}
          to="/app/business-insight"
          menuRef="businessBtn"
          toggleSidebar={toggleSidebar}
        />
      )}
      <Divider />
      <Item
        text={t('sidebar.help_tab')}
        icon={<HelpIcon />}
        open={open}
        index={6}
        to="/app/help"
        menuRef="helpBtn"
        toggleSidebar={toggleSidebar}
      />
    </MenuContainer>
  );
};

export default Menu;
