import { Box, Tab, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { NOTIFICATIONTYPE } from '../../../utils/constants';
import { timeSince } from '../../../utils/timeCalc';
import {
  CustomBtn, CustomMenuItem, NotificationContainer, NotificationMenu,
  NotificationsTab, UnreadDot, TypoNotify,
} from '../styles';

type props = {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClose: () => void;
};

// const notifications = {
//   a: {
//     updatedat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     createdat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     title:
//       'Your account will be expired soon! click here to upgrade your plan!',
//     message: 'Your account will be expired soon!',
//     type: 'general',
//     read: false,
//   },
//   b: {
//     updatedat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     createdat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     title:
//       'Your account will be expired soon! click here to upgrade your plan!',
//     message: 'Your account will be expired soon!',
//     type: 'action',
//     read: false,
//   },
//   c: {
//     updatedat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     createdat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     title:
//       'Your account will be expired soon! click here to upgrade your plan!',
//     message: 'Your account will be expired soon!',
//     type: 'alert',
//     read: true,
//   },
//   d: {
//     updatedat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     createdat: 'Wed Aug 10 2022 11:44:52 GMT+0530 (India Standard Time)',
//     title:
//       'Your account will be expired soon! click here to upgrade your plan!',
//     message: 'Your account will be expired soon!',
//     type: 'general',
//     read: true,
//   },
// };

const NotificationsMenu = ({ anchorEl, open, handleClose }: props) => {
  const [menuIndex, setMenuIndex] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setMenuIndex(newValue);
  };
  const navigate = useNavigate();
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  );

  const handleNotificationClose = () => {
    navigate('/notifications');
    handleClose();
  };

  return (
    <NotificationMenu
      id="account-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <NotificationContainer>
        <Typography variant="subtitle1">Notifications</Typography>
        <NotificationsTab value={menuIndex} onChange={handleChange}>
          <Tab label={<Typography variant="subtitle2">general</Typography>} />
          <Tab
            label={<Typography variant="subtitle2">action logs</Typography>}
          />
          <Tab label={<Typography variant="subtitle2">alerts</Typography>} />
        </NotificationsTab>
        {Object.values(notifications).filter(
          (notification: any) =>
            notification.type === NOTIFICATIONTYPE[menuIndex],
        ).length > 0 ? (
            Object.values(notifications)
              .filter(
                (notification: any) =>
                  notification.type === NOTIFICATIONTYPE[menuIndex],
              )
              .sort((x: any, y: any) => y.updatedat - x.updatedat)
              .slice(0, 3)
              .map((option: any) => (
                <CustomMenuItem
                  key={option.createdat}
                  onClick={() => {
                    handleClose();
                  }}
                  className={option.read && 'read'}
                >
                  {!option.read && <UnreadDot />}
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">{option.title}</Typography>
                    <Typography variant="caption">
                      {timeSince(option.updatedat)}
                    </Typography>
                  </Box>
                </CustomMenuItem>
              ))
          ) : (
            <TypoNotify variant="body2">No notifications</TypoNotify>
          )}
        <CustomBtn variant="text" onClick={handleNotificationClose}>
          <Typography variant="subtitle2">View all notifications</Typography>
        </CustomBtn>
      </NotificationContainer>
    </NotificationMenu>
  );
};

export default NotificationsMenu;
