import React from 'react';

const DoubleLeftArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5898 18L18.9998 16.59L14.4198 12L18.9998 7.41L17.5898 6L11.5898 12L17.5898 18Z"
      fill="#C8C8C8"
    />
    <path
      d="M11 18L12.41 16.59L7.83 12L12.41 7.41L11 6L5 12L11 18Z"
      fill="#838383"
    />
  </svg>
);

export default DoubleLeftArrow;
