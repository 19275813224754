import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  userDetails,
  users,
  reportBug,
  cardDetails,
  retryPaymentApi,
  editCardApi,
  updatePaymentMethodStatus,
  invovicesHistory,
  userInvites,
  adminDelete,
} from '../../services/apiDefinition';

import axiosApiInstance from '../../services/axios';

export const getUserDetails = createAsyncThunk(
  'settings/getUserDetails',
  async () => {
    try {
      const response = await axiosApiInstance.get(userDetails);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      } else if (e.response.status >= 500 && e.response.status <= 504) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getUsers = createAsyncThunk('settings/getUsers', async () => {
  try {
    const response = await axiosApiInstance.get(users);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 499) {
      throw new Error(e.response.data.message);
    } else if (e.response.status >= 500 && e.response.status <= 504) {
      throw new Error(e.response.data.message);
    }
  }
});

export const getUserInvites = createAsyncThunk('settings/getUserInvites', async () => {
  try {
    const response = await axiosApiInstance.get(userInvites);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 499) {
      throw new Error(e.response.data.message);
    } else if (e.response.status >= 500 && e.response.status <= 504) {
      throw new Error(e.response.data.message);
    }
  }
});

type reportBugType = {
  request: FormData;
  resetReportModal: () => void;
  onCloseModal: () => void
};

export const reportABug = createAsyncThunk(
  'settings/reportBug',
  async ({ request, resetReportModal, onCloseModal }: reportBugType) => {
    try {
      const response = await axiosApiInstance.post<any>(reportBug, request, {
        withCredentials: true,
      });
      resetReportModal();
      onCloseModal();
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getCardDetails = createAsyncThunk(
  'settings/getCardDetails',
  async () => {
    try {
      const response = await axiosApiInstance.get(cardDetails);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getInvoicesHistory = createAsyncThunk(
  'settings/getInvoicesHistory',
  async () => {
    try {
      const response = await axiosApiInstance.get(invovicesHistory);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
export const retryPayment = createAsyncThunk('settings/retryPayment', async () => {
  try {
    const response = await axiosApiInstance.get(retryPaymentApi);
    return response.data;
  } catch (e: any) {
    if (e.response.status >= 400 && e.response.status < 499) {
      throw new Error(e.response.data.message);
    }
  }
});

export const editCard = createAsyncThunk(
  'settings/editCard',
  async () => {
    try {
      const response = await axiosApiInstance.get(editCardApi);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const updatePaymentMethodStatusHandler = createAsyncThunk(
  'settings/updatePaymentMethodStatus',
  async () => {
    try {
      const response = await axiosApiInstance.get(updatePaymentMethodStatus);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const deleteUserAsAdmin = createAsyncThunk(
  'settings/deleteUserAsAdmin',
  async ({ username, invite }: any) => {
    try {
      const response = await axiosApiInstance.delete(adminDelete, {
        data: { username, invite_only: invite },
      });
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 499) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
