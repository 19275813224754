import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import lottieJson from '../../../assets/images/with_sw.json';
import {
  ImageSubTitle,
  ImageTitle,
  PasswordInnerBlock2,
  PasswordRightItemGrid,
} from '../styles';

const PasswordImage = ({ t }: { t: any }) => (
  <PasswordRightItemGrid
    item
    justifyContent="center"
    display="flex"
    alignSelf="center"
  >
    <PasswordInnerBlock2>
      <Player
        autoplay
        loop
        src={JSON.stringify(lottieJson)}
        style={{ height: '40vh', width: '40vw' }}
      />

      <ImageTitle variant="h4">{t('login.login_cover_title')}</ImageTitle>
      <ImageSubTitle variant="body1">
        {t('login.login_cover_subtitle1')}
      </ImageSubTitle>
      <ImageSubTitle variant="body1">
        {t('login.login_cover_subtitle2')}
      </ImageSubTitle>
    </PasswordInnerBlock2>
  </PasswordRightItemGrid>
);

export default PasswordImage;
