import americanexpress from '../assets/icons/americanexpress.png';
import cb from '../assets/icons/cartesbancaires.png';
import chinaUnionPay from '../assets/icons/unionpay.png';
import discover from '../assets/icons/discover.png';
import jcb from '../assets/icons/jcb.png';
import mastercard from '../assets/icons/mastercard.png';
import visa from '../assets/icons/visa.png';
import dinersclub from '../assets/icons/dinersclub.png';
import interac from '../assets/icons/interac.png';
import unknown from '../assets/icons/unknown.png';
import { LegendsType } from './types/threeJsTypes';

export const API_PENDING_STATUS = 'API_PENDING_STATUS';
export const API_SUCCESS_STATUS = 'API_SUCCESS_STATUS';
export const API_FAILURE_STATUS = 'API_FAILURE_STATUS';
export const PAYMENT_STATUS_PENDING = 'PAYMENT_STATUS_PENDING';
export const PAYMENT_STATUS_SUCCESS = 'PAYMENT_STATUS_SUCCESS';
export const PAYMENT_STATUS_FAILURE = 'PAYMENT_STATUS_FAILURE';
export const NULL_STRING = 'null';
export const ROWSPERPAGE = [10, 25, 100];
export const API_LOGIN_COMPLETE = 'API_LOGIN_COMPLETE';
export const IMGTYPES = ['jpeg', 'png'];
export const ORDERSTYPEINDEX = {
  0: 'all',
  1: 'Pick Up Order',
  2: 'Packing Order',
  3: 'Completed',
};
export const ORDERTYPES = {
  'New Order': 'blue',
  'Pick Up Order': 'yellow',
  'Packing Order': 'orange',
  Completed: 'green',
};
export const LOCATIONINDEX = {
  '/app/welcome': 0,
  '/app/dashboard': 1,
  '/app/integrations': 2,
  '/app/devices': 3,
  '/app/map-layout': 4,
  '/app/business-insight': 5,
  '/app/help': 6,
  '/app/inventory': 7,
  '/app/orders': 8,
  '/app/optimisation': 9,
};
export const DATAOPTIONS = ['Save Data', 'Reset Data'];
export const FEATURESTEXT = {
  standard: {
    title: 'Starter',
    featuresHead: 'OUR STARTER PLAN INCLUDES',
    description:
      'Great for startups and small businesses that quickly want to improve their warehouse operations, without engaging a data scientist or consultant.',
    features: [
      '1 warehouse',
      'Key Performance Indicators',
      'Inventory Optimization (ABC + XYZ Analysis)',
      'Purchasing Patterns (Affinity Analysis or Market-Basket Analysis)',
      'Chart Interpretation Guidelines',
      'PDF reporting',
    ],
  },
  recommended: {
    title: 'Professional',
    featuresHead: 'EVERYTHING IN STARTER, PLUS',
    description:
      'Perfect for warehouse consultants who do data analysis for multiple different warehouses, fulfillment centers, or distribution centers.',
    features: ['3 Warehouses', 'Integration with WMS', 'White Labeling'],
  },
  enterprise: {
    title: 'Enterprise',
    featuresHead: 'EVERYTHING IN PROFESSIONAL, PLUS',
    description:
      'Ideal for 3PL and multinational companies requiring a standardized and comparable analysis of all their warehouses, fulfilment centers and distribution centers.',
    features: [
      'Custom usage limits',
      'Customized analyses',
      'Language translation',
      'Multi-region hosting',
      'Enterprise SLA',
      'Priority support and live support calls',
    ],
  },
};
export const MONTHNAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const CARDLOGOS = {
  'american express': americanexpress,
  'diners club': dinersclub,
  discover: discover,
  jcb: jcb,
  mastercard: mastercard,
  visa: visa,
  cb: cb,
  unionpay: chinaUnionPay,
  interac: interac,
  unknown: unknown,
};

const standard = process.env.REACT_APP_STRIPE_STANDARD_PLAN!;
const recommended = process.env.REACT_APP_STRIPE_RECOMENDED_PLAN!;
export const PLANTYPE = {
  [standard]: 'standard',
  [recommended]: 'recommended',
};
export const NOTIFICATIONTYPE = ['general', 'action', 'alert'];
export const ORIGNALLOCATIONS = {
  'Coca Cola Can': 'Area01_1',
  'Mineral Water': 'Area01_2',
  'Hand Soap': 'Area01_3',
  'Disposable Cups': 'Area01_4',
  'Cup Noodles': 'Area01_5',
  Pampers: 'Area01_6',
  'Baby Powder': 'Area01_7',
  'Canned Tuna': 'Area01_8',
  'Cat Food': 'Area01_9',
  'Cadbury Chocolate': 'Area01_10',
  'Lays Chips': 'Area02_1',
  'Fresh Milk': 'Area02_2',
  'Magnum Ice Cream': 'Area02_3',
  'Ice Pack': 'Area02_4',
  Brocolli: 'Area02_5',
  Watermelon: 'Area02_6',
  'Absolut Vodka': 'Area02_7',
  'Sapporo Beer': 'Area02_8',
  'Milo Powder': 'Area02_9',
  'Vegetable Oil': 'Area02_10',
  'Cooking Oil': 'Area03_1',
  'Jasmine Rice': 'Area03_2',
  'Cashew Nuts': 'Area03_3',
  'Wholemeal Bread': 'Area03_4',
  'Koko Krunch Cereal': 'Area03_5',
  'Skippy Peanut Butter': 'Area03_6',
  'Sanitary Pads': 'Area03_7',
  'Cough Medication': 'Area03_8',
  'Green Beans': 'Area03_9',
  'Haribo Gummies': 'Area03_10',
};
export const NEWLOCATIONS = {
  'Coca Cola Can': 'Area02_6',
  'Mineral Water': 'Area01_6',
  'Hand Soap': 'Area01_3',
  'Disposable Cups': 'Area01_4',
  'Cup Noodles': 'Area03_1',
  Pampers: 'Area01_9',
  'Baby Powder': 'Area03_10',
  'Canned Tuna': 'Area01_1',
  'Cat Food': 'Area01_2',
  'Cadbury Chocolate': 'Area01_10',
  'Lays Chips': 'Area02_1',
  'Fresh Milk': 'Area02_2',
  'Magnum Ice Cream': 'Area02_3',
  'Ice Pack': 'Area02_4',
  Brocolli: 'Area02_5',
  Watermelon: 'Area01_8',
  'Absolut Vodka': 'Area02_10',
  'Sapporo Beer': 'Area03_6',
  'Milo Powder': 'Area02_9',
  'Vegetable Oil': 'Area03_3',
  'Cooking Oil': 'Area01_5',
  'Jasmine Rice': 'Area03_2',
  'Cashew Nuts': 'Area02_7',
  'Wholemeal Bread': 'Area03_4',
  'Koko Krunch Cereal': 'Area03_5',
  'Skippy Peanut Butter': 'Area02_8',
  'Sanitary Pads': 'Area03_7',
  'Cough Medication': 'Area03_8',
  'Green Beans': 'Area03_9',
  'Haribo Gummies': 'Area01_7',
};
export const LOCATIONSHEADER = [
  'Item Name',
  'Previous Location',
  'Recommended Location',
  // 'Updated On',
];
export const AREA04 = {
  name: 'Area04',
  measurements: {
    top_left_x: 1378,
    top_left_y: 560,
    angle: 0,
    horizontal_length: 774,
    vertical_length: 79,
  },
  row: 2,
  column: 5,
  Slots: {
    '(0, 0)': {
      name: 'Area04_1',
      measurements: {
        top_left_x: 1378,
        top_left_y: 560,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: true,
      items: [],
    },
    '(0, 1)': {
      name: 'Area04_2',
      measurements: {
        top_left_x: 1530,
        top_left_y: 560,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: true,
      items: [],
    },
    '(0, 2)': {
      name: 'Area04_3',
      measurements: {
        top_left_x: 1685,
        top_left_y: 560,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: true,
      items: [],
    },
    '(0, 3)': {
      name: 'Area04_4',
      measurements: {
        top_left_x: 1834,
        top_left_y: 560,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: false,
      items: [],
    },
    '(0, 4)': {
      name: 'Area04_5',
      measurements: {
        top_left_x: 1987,
        top_left_y: 560,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: false,
      items: [],
    },
    '(1, 0)': {
      name: 'Area04_6',
      measurements: {
        top_left_x: 1378,
        top_left_y: 598,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: true,
      items: [],
    },
    '(1, 1)': {
      name: 'Area04_7',
      measurements: {
        top_left_x: 1530,
        top_left_y: 598,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: false,
      items: [],
    },
    '(1, 2)': {
      name: 'Area04_8',
      measurements: {
        top_left_x: 1685,
        top_left_y: 598,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: true,
      items: [],
    },
    '(1, 3)': {
      name: 'Area04_9',
      measurements: {
        top_left_x: 1834,
        top_left_y: 598,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: false,
      items: [],
    },
    '(1, 4)': {
      name: 'Area04_10',
      measurements: {
        top_left_x: 1987,
        top_left_y: 598,
        angle: 0,
        horizontal_length: 154,
        vertical_length: 40,
      },
      available: false,
      items: [],
    },
  },
};
export const PALLETOPTIONS = [
  'GMA style 4-way',
  '2-way Pallet',
  'Beverage Pallet',
  'Stringer Pallet',
];

// integration form field types
export const STRING = 'string';
export const NUMBER = 'number';
export const BOOLEAN = 'boolean';
export const MAP = 'map';
export const SELECT = 'select';

// fabric JS constants
export const RACK = 'Rack';
export const DISPATCH = 'Dispatch';
export const STARTING = 'Starting';
export const FORBIDDEN = 'Forbidden';
export const RECEIVING = 'Receiving';
export const FABRICOPTIONS = [
  'group_name',
  'bay_name',
  'details',
  'area_type',
  'items',
  'row_column',
  'group_properties',
  'row_num',
  'col_num',
  'id',
  'class',
  'scale_measurement',
  'scale_value',
  'scale_unit',
  'bins',
  'levels',
];
export const AREA_TYPES = {
  Dispatch: 'Dispatch Area',
  Starting: 'Starting point',
  Forbidden: 'Forbidden Area',
};
export const WALLCOLOR = 'blue';
export const WALLPOINT = 'circlwallpoint';
export const WALLLINE = 'wallline';
export const POLYGON = 'polygon';
export const RULERCOLOR = '#E4E4E4';
export const RULERLINECOLOR = 'rgba(0,0,0,0.23)';
export const RACKCOLOR = '#FFCD29';
export const RACKOUTLINE = '#C38202';
export const DISPATCHCOLOR = '#5E5E5E';
export const DISPATCHOUTLINE = '#363434';
export const STARTINGCOLOR = '#497D15';
export const STARTINGOUTLINE = '#308919';
export const FORBIDDENCOLOR = '#AF1616';
export const FORBIDDENOUTLINE = '#AA0000';
/* Tools Pointer */
export const SELECTTYPE = 'select';
export const AREA_CREATOR = 'rect';
export const NEW_WALL = 'newWall';
export const EDIT_WALL = 'editWall';
export const FEET = 'ft';
export const METER = 'm';

// Three.js constants
export const TWOD = '2D';
export const THREED = '3D';
export const BACKGROUND_COLOR = 0x000000;
export const AMBIENT_LIGHT_COLOR = 0xffffff;
export const DIRECTIONAL_LIGHT_COLOR = 0xfafafa;
export const WIRE_FRAME_COLOR = 0x000000;
export const RACK_COLOR = 0x35BFB0;
export const FORBIDDEN_AREA_COLOR = 0xff0000;
export const PICKER_POINT_COLOR = 0x0000ff;
export const RACKCOLORS = [0x8EEA80, 0xe6e885, 0x735454];
export const LEGENDS: LegendsType = [
  {
    A: '#8EEA80',
    B: '#e6e885',
    C: '#735454',
    Unoccupied: '#fff',
  },
  {
    X: '#8EEA80',
    Y: '#e6e885',
    Z: '#735454',
    Unoccupied: '#fff',
  },
];

export const OPTIMIZERSTEPS = [
  'Optimizer',
  'Warehouse Statistics',
  'Recommended Optimization',
  'Optimization Results',
];

// optimizer constants
export const DISTANCE_WALKED_BEFORE = 'distance_walked_before (mm)';
export const DISTANCE_WALKED_AFTER = 'distance_walked_after (mm)';
export const TIME_TAKEN_BEFORE = 'time_taken_before (s)';
export const TIME_TAKEN_AFTER = 'time_taken_after (s)';
export const DISTANCE_IMPROVEMENT = 'percentage_improvement_on_distance_walked (%)';
export const TIME_IMPROVEMENT = 'percentage_improvement_on_time_taken (%)';
export const SKUS_MOVED = 'number_of_skus_moved';
export const BINS_AFFECTED = 'number_of_bins_affected';
export const BEFORE_OPTIMIZATION_BINS = 'initial_sku_to_bin_location_dictionary';
export const AFTER_OPTIMIZATION_BINS = 'updated_sku_to_bin_location_dictionary';

// pubnub status constants
export const IN_PROGRESS = 'IN_PROGRESS';
export const COMPLETED = 'COMPLETED';
export const ERROR = 'ERROR';

// table header constants
export const INBOUND_HEADERS = [
  'Sku Id',
  'Sku Description',
  'Sku Category',
  'Sku Quantity',
  'Putaway Location Id',
  'Replenishment Order Datetime',
];

export const OUTBOUND_HEADERS = [
  'Order Id',
  'Sku Id',
  'Sku Name',
  'Sku Category',
  'Quantity',
  'Customer Id',
  'Destination',
  'Operator Id',
];
