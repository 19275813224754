import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditProject from './editProject';
import DeleteProject from './deleteProject';
import ActionMenu from './actionMenu';
import { ActionButton } from '../styles';
import { projectType } from '../../../utils/types/projectTypes';
import { Mixpanel } from '../../../utils/mixPanel';
import MixPanelConstants from '../../../utils/mixpanelConstants';
import DeleteConfirmation from './deleteConfirm';
import useSidebar from '../../../components/SideBar/useSidebar';

type propTypes = {
  project: projectType;
};

const Actions = ({ project }: propTypes) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const [messageText, setMessageText] = useState('');
  const { projectLoading } = useSidebar();
  const open = Boolean(anchorEl);
  const handleClick = (currentTarget: EventTarget & HTMLButtonElement) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const editModalOpen = () => {
    Mixpanel.track(MixPanelConstants.editProject);
    setOpenEdit(true);
  };
  const deleteModalOpen = () => {
    Mixpanel.track(MixPanelConstants.deleteProject);
    setOpenDelete(true);
  };
  const closeEditModal = () => {
    if (projectLoading) {
      return;
    }
    setOpenEdit(false);
  };
  const closeDeleteModal = () => {
    if (projectLoading) {
      return;
    }
    setOpenDelete(false);
  };

  const dataModal = (message: any) => {
    setMessageText(message);
    setOpenModal(true);
  };

  const dataModalHandleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      <ActionButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => handleClick(e.currentTarget)}
      >
        <MoreVertIcon />
      </ActionButton>
      <ActionMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        editModalOpen={editModalOpen}
        deleteModalOpen={deleteModalOpen}
        disabled={project.is_sample_project}
      />

      {openEdit && (
        <EditProject
          open={openEdit}
          handleClose={closeEditModal}
          project={project}
        />
      )}
      {openDelete && (
        <DeleteProject
          dataModal={dataModal}
          open={openDelete}
          handleClose={closeDeleteModal}
          project={project}
        />
      )}
      {openModal && <DeleteConfirmation open={openModal} onClose={dataModalHandleClose} messageText={messageText} />}
    </>
  );
};

export default Actions;
