import React, { useEffect } from 'react';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Modal from '../../../components/Modal';
import { AppDispatch } from '../../../store';
import { resetProjectData } from '../../../store/slices/crudSlice';
import { projectType } from '../../../utils/types/projectTypes';
import { ActionBox, ActionButtonsBox } from '../styles';
import useProjects from '../useProjects';

type propTypes = {
  open: boolean;
  handleClose: () => void;
  project: projectType;
  dataModal: (message:any) => void;
};

const DeleteProject = ({ open, handleClose, project, dataModal }: propTypes) => {
  const { t } = useTranslation();
  const { deleteProjectFrom, projectAPILoadingStatus } = useProjects();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(resetProjectData());
  }, []);

  const onClose = () => {
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ActionBox>
          <Typography variant="h6" padding="0 0 24px 0">
            {t('allProjects.delete_project_title')}
          </Typography>
          <Typography variant="body1">
            {t('allProjects.delete_project_confirmation', {
              name: project.project_name,
            })}
            <br />
            {/* {project.areas.length > 0 && (
            <Trans
              i18nKey="allProjects.delete_project_result"
              count={project.areas.length}
            />
          )} */}
          </Typography>
          <ActionButtonsBox>
            <Button size="medium" onClick={onClose} className="action">
              <Typography variant="body2">
                {t('allProjects.cancel_action_button')}
              </Typography>
            </Button>
            {projectAPILoadingStatus ? (
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<Save />}
                variant="outlined"
              >
                Wait
              </LoadingButton>
            ) : (
              <Button
                size="medium"
                color="error"
                variant="contained"
                className="action"
                onClick={() => {
                  deleteProjectFrom(project, onClose, dataModal);
                }}
              >
                <Typography variant="body2">
                  {t('allProjects.delete_project_button')}
                </Typography>
              </Button>
            )}
          </ActionButtonsBox>
        </ActionBox>
      </Modal>
    </>
  );
};

export default DeleteProject;
