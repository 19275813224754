import { Box, Paper, styled } from '@mui/material';

const LogicBuilderContainer = styled(Paper)(({ theme }) => ({
  // minWidth: '60%',
  minWidth: '1000px',
  width: 'fit-content',
  borderRadius: '5px',
  textAlign: 'initial',
  padding: '24px',
  maxHeight: '575px',
  overflow: 'auto',
  '.MuiTypography-root': {
    color: theme.palette.text.primary,
  },
}));

const ConditionsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ConditionContainer = styled(Box)(() => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  width: 'initial',
  marginTop: '24px',
  borderRadius: '5px',
  padding: '16px 24px',
}));

const BlockContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const RulesContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  '& ul, li': {
    listStyle: 'none',
    margin: '0',
  },
}));

const ConditionText = styled(Box)(() => ({
  minWidth: '58px',
  display: 'flex',
}));

export {
  LogicBuilderContainer,
  ConditionsContainer,
  ConditionContainer,
  BlockContainer,
  RulesContainer,
  ConditionText,
};
