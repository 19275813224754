import React from 'react';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import { PasswordBtn } from '../styles';

type passwordProps = {
  handleClickShowPassword: () => void,
  showPassword: boolean,
}

const PasswordIcon = ({ handleClickShowPassword, showPassword }: passwordProps) => (
  <InputAdornment position="end">
    <PasswordBtn
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      edge="end"
    >
      {showPassword ? (
        <VisibilityOff />
      ) : (
        <Visibility />
      )}
    </PasswordBtn>
  </InputAdornment>
);

export default PasswordIcon;
