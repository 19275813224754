import { styled } from '@mui/material/styles';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 14,
  height: 14,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 0 0 1px rgb(16 22 26 / 40%)'
    : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: 'transparent',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
  border: `3px solid ${theme.palette.primary.main}`,
  display: 'grid',
  placeContent: 'center',
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: 'transparent',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  border: '3px solid #137cbd',
  display: 'grid',
  placeContent: 'center',
  '&:before': {
    display: 'block',
    width: 9,
    height: 9,
    backgroundColor: '#137cbd',
    content: '""',
    borderRadius: '50%',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

export { BpCheckedIcon, BpIcon };
