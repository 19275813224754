import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosApiInstance from '../../services/axios';
import { addAreaToProject } from '../../services/apiDefinition';
import {
  areaCreateResponse,
} from '../../utils/types/apiTypes';

type addAreaType = {
  area_name: string;
  currentProjectId: string;
};

type updateAreaType = {
  area_name: string;
  currentProjectId: string;
  area_uuid: string;
};

type deleteAreaType = {
  currentProjectId: string;
  area_uuid: string;
};

export const addArea = createAsyncThunk(
  'area/addArea',
  async ({ area_name, currentProjectId }: addAreaType) => {
    try {
      const response = await axiosApiInstance.post<areaCreateResponse>(
        `${addAreaToProject}?current_project=${currentProjectId}`,
        { area_name },
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);

export const updateArea = createAsyncThunk(
  'area/updateArea',
  async ({ area_uuid, area_name, currentProjectId }: updateAreaType) => {
    try {
      const response = await axiosApiInstance.put<areaCreateResponse>(
        `${addAreaToProject}${area_uuid}?current_project=${currentProjectId}`,
        { area_name },
        {
          withCredentials: true,
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);

export const deleteArea = createAsyncThunk(
  'area/deleteArea',
  async ({ area_uuid, currentProjectId }: deleteAreaType) => {
    try {
      const config = {
        withCredentials: true,
      };
      await axiosApiInstance.delete<areaCreateResponse>(
        `${addAreaToProject}${area_uuid}?current_project=${currentProjectId}`,
        config,
      );
      return area_uuid;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data);
      }
    }
  },
);
