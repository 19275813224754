import { combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import authSlice from './slices/authSlice';
import welcomeSlice from './slices/welcomeSlice';
import subscriptionSlice from './slices/subscriptionSlice';
import menuSlice from './slices/menuSlice';
import deviceSlice from './slices/deviceSlice';
import projectsSlice from './slices/projectsSlice';
import areasSlice from './slices/areasSlice';
import devicesSlice from './slices/devicesSlice';
import selectedDataSlice from './slices/selectedDataSlice';
import notificationsSlice from './slices/notificationSlice';
import maplayoutSlice from './slices/maplayoutSlice';
import settingsSlice from './slices/settingsSlice';
import crudSlice from './slices/crudSlice';
import inventorySlice from './slices/inventorySlice';
import tourSlice from './slices/tourSlice';
import ordersSlice from './slices/ordersSlice';
import aiTabSlice from './slices/aiTabSlice';
import integrationsSlice from './slices/integrationsSlice';
import optimizerTab from './slices/optimizerTabSlice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['userData'],
};

const projectsDataPersistConfig = {
  key: 'projectsData',
  storage,
};

const areaDataPersistConfig = {
  key: 'areasData',
  storage,
};

const devicesDataPersistConfig = {
  key: 'devicesData',
  storage,
};

const selectedDataPersistConfig = {
  key: 'selectedData',
  storage,
  whitelist: ['currentProject', 'currentAreaId', 'currentProjectData', 'dashboardUrls'],
};

const settingsDataPersistConfig = {
  key: 'settingsData',
  storage,
};

const tourDataPersistConfig = {
  key: 'tourData',
  storage,
};

const inventoryDataPersistConfig = {
  key: 'inventoryData',
  storage,
};

const ordersDataPersistConfig = {
  key: 'ordersData',
  storage,
};

const notificationsDataPersistConfig = {
  key: 'notificationsData',
  storage,
};

const maplayoutDataPersistConfig = {
  key: 'maplayoutData',
  storage,
};

const welcomeDataPersistConfig = {
  key: 'welcomeData',
  storage,
};

const integrationsDataPersistConfig = {
  key: 'integrationsData',
  storage,
};

const optimizerTabDataPersistConfig = {
  key: 'optimizerTabData',
  storage,
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  projects: persistReducer(welcomeDataPersistConfig, welcomeSlice),
  subscription: subscriptionSlice,
  menu: menuSlice.reducer,
  deviceData: deviceSlice,
  projectsData: persistReducer(
    projectsDataPersistConfig,
    projectsSlice.reducer,
  ),
  areasData: persistReducer(areaDataPersistConfig, areasSlice.reducer),
  devicesData: persistReducer(devicesDataPersistConfig, devicesSlice.reducer),
  selectedData: persistReducer(
    selectedDataPersistConfig,
    selectedDataSlice.reducer,
  ),
  notifications: persistReducer(notificationsDataPersistConfig, notificationsSlice.reducer),
  maplayout: persistReducer(maplayoutDataPersistConfig, maplayoutSlice.reducer),
  settings: persistReducer(settingsDataPersistConfig, settingsSlice.reducer),
  crudData: crudSlice,
  inventory: persistReducer(inventoryDataPersistConfig, inventorySlice.reducer),
  tour: persistReducer(tourDataPersistConfig, tourSlice.reducer),
  orders: persistReducer(ordersDataPersistConfig, ordersSlice.reducer),
  aiTab: aiTabSlice.reducer,
  integrations: persistReducer(integrationsDataPersistConfig, integrationsSlice.reducer),
  optimizerTab: persistReducer(optimizerTabDataPersistConfig, optimizerTab.reducer),
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/setLoginRedirect') {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    // localStorage.clear();
    localStorage.removeItem('authToken');
    localStorage.removeItem('paymentStatus');
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('gf_key');

    state = {} as RootState;
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
