import { BugReport } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomIconButton } from '../styles';
import useAppbar from '../useAppbar';
import ReportBugModal from './reportBug';
import ReportSuccess from './reportSuccess';

const ReportbugButton = () => {
  const [reportBug, setReportBug] = useState(false);
  const { reportSuccessModal, resetReportBugData, reportAPILoadingStatus } = useAppbar();
  const { t } = useTranslation();
  const closeReportBugModal = () => {
    if (reportAPILoadingStatus) return;
    setReportBug(false);
  };

  return (
    <>
      <Tooltip title={t('appbar.report_bug_text')}>
        <CustomIconButton onClick={() => setReportBug(true)}>
          <BugReport />
        </CustomIconButton>
      </Tooltip>

      <ReportBugModal
        isModalVisible={reportBug}
        onCloseModal={closeReportBugModal}
      />
      <ReportSuccess
        isModalVisible={reportSuccessModal}
        onCloseModal={resetReportBugData}
      />
    </>
  );
};

export default ReportbugButton;
