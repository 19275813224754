import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { Form } from 'formik';
import { Button } from '../../components';

const PasswordContainerGrid = styled(Grid)<GridProps>(() => ({
  height: '100vh',
  display: 'flex',
}));

const PasswordContainerItem = styled(Grid)<GridProps>(() => ({
  height: '100vh',
}));

const PasswordLeftItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.paper,
  width: 'inherit',
  padding: '0rem 2rem',
}));

const PasswordRightItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.default,
  width: 'inherit',
}));

const PasswordInnerBlock = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'left',
}));

const PasswordInnerBlock2 = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

const PasswordContainer = styled(Container)<ContainerProps>(() => ({
  root: {
    maxWidth: 'inherit !important',
  },
}));

const ForgotButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  fontWeight: 600,
}));

const SignUpButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  fontWeight: 600,
  padding: 0,
}));

const SendButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  fontWeight: 400,
}));

const BackToLoginButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  fontWeight: 400,
  padding: 0,
  color: 'white',
}));

const SignUpText = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  color: theme.palette.common.white,
}));

const PasswordTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 600,
}));

const PasswordSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  paddingTop: 12,
  paddingBottom: 24,
}));

const ImageTitle = styled(Typography)<TypographyProps>(() => ({
  background: 'linear-gradient(89.68deg, #3BA6F9 10.34%, #36ADF3 18.23%, #00F6C0 94.46%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 600,
  textAlign: 'center',
  margin: 5,
}));

const ImageSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const FormContainer = styled(Form)(() => ({
  display: 'grid',
  maxWidth: 400,
  gap: 24,
}));

const TextFieldContainer = styled(Grid)(() => ({
  display: 'grid',
  gap: 16,
}));

export {
  PasswordContainerGrid,
  PasswordInnerBlock,
  PasswordInnerBlock2,
  PasswordContainer,
  PasswordLeftItemGrid,
  PasswordRightItemGrid,
  ForgotButton,
  SendButton,
  BackToLoginButton,
  SignUpButton,
  SignUpText,
  PasswordTitle,
  PasswordSubTitle,
  ImageTitle,
  ImageSubTitle,
  FormContainer,
  TextFieldContainer,
  PasswordContainerItem,
};
