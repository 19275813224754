import { IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import { HalfSidebarLogo } from '../../assets/images';
import Notifications from './components/notification';
import ProjectList from './components/projectList';
import ReportbugButton from './components/reportbugButton';
import UserMenu from './components/userMenu';

import {
  MenuContainer,
  NavBox,
  NavContainer,
  NavigationContainer,
  NavToolBar,
  ProjectText,
  NavProjectBox,
} from './styles';

const NavigationBar = ({ mobileSideBar, setMobileSideBar }: any) => {
  const { t } = useTranslation();

  const handleMobileDrawer = () => {
    setMobileSideBar(!mobileSideBar);
  };

  return (
    <NavContainer>
      <NavigationContainer>
        <NavToolBar>
          <NavBox>
            <HalfSidebarLogo />
            <IconButton color="inherit" onClick={handleMobileDrawer}>
              <MenuIcon />
            </IconButton>
          </NavBox>
          <MenuContainer>
            <NavProjectBox display="flex" alignItems="center">
              <ProjectText variant="body2">
                {t('appbar.project_text')}:
              </ProjectText>
              <ProjectList />
            </NavProjectBox>
            <Notifications />
            <ReportbugButton />
            <UserMenu />
          </MenuContainer>
        </NavToolBar>
      </NavigationContainer>
    </NavContainer>
  );
};

export default NavigationBar;
