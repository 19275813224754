import axios, { AxiosRequestConfig } from 'axios';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosApiInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authToken = localStorage.getItem('authToken');
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (authToken) {
      config.headers.Authorization = `Token ${authToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('authToken');
      window.location.href = '/app/login';
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
