import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularLoading } from '../components';

const ProtectedRoute = ({ children }: { children: any }) => {
  const isAuthenticated = localStorage.getItem('authToken');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/app/login', { replace: true });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <CircularLoading />
      </>
    );
  }

  return children;
};

export default ProtectedRoute;
