/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { notificationsReducer } from '../../utils/types/reducerStateTypes';

const initialState: notificationsReducer = {
  notifications: {},
  filterDate: {
    startDate: 0,
    endDate: 0,
    apply: false,
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = { ...state.notifications, [action.payload.key]: action.payload.value };
    },
    setFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    resetFilterDate: (state) => {
      state.filterDate = initialState.filterDate;
    },
  },
});

export const { setNotifications, setFilterDate, resetFilterDate } = notificationsSlice.actions;

export default notificationsSlice;
