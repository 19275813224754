import React, { useEffect } from 'react';
import { t } from 'i18next';
import toast from 'react-hot-toast';
import { Hidden } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PasswordContainerGrid, PasswordContainerItem } from './styles';
import useLogin from './useLogin';
import { API_SUCCESS_STATUS, API_FAILURE_STATUS } from '../../utils/constants';
import PasswordForm from './components/passwordForm';
import PasswordImage from './components/passwordImage';

const PasswordPage = () => {
  const [
    PasswordAPIExecutionStatus,
    PasswordAPISuccessMessage,
    PasswordAPIFailureMessage,
  ] = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/app/welcome', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (PasswordAPIExecutionStatus === API_SUCCESS_STATUS) {
      toast.success(PasswordAPISuccessMessage);
    } else if (PasswordAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(PasswordAPIFailureMessage);
    }
  }, [PasswordAPIExecutionStatus]);

  return (
    <div>
      <PasswordContainerGrid container>
        <PasswordContainerItem container item md={6}>
          <PasswordForm />
        </PasswordContainerItem>
        <Hidden mdDown>
          <PasswordContainerItem container item md={6}>
            <PasswordImage t={t} />
          </PasswordContainerItem>
        </Hidden>
      </PasswordContainerGrid>
    </div>
  );
};

export default PasswordPage;
