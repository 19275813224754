import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import { AppDispatch } from '../../store';
import { doSignup, getTerms } from '../../store/thunks/authThunk';
import { SignupFormValues } from '../../utils/types/formTypes';
import { resetSignUpData } from '../../store/slices/authSlice';
import { Mixpanel } from '../../utils/mixPanel';
import MixPanelConstants from '../../utils/mixpanelConstants';

const useSignup = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    signUpAPIExecutionStatus,
    signUpAPIFailureMessage,
    signUpAPISuccessMessage,
    signUpAPILoadingStatus,
    termsAPILoadingStatus,
    termsData,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
  } = useShallowEqualSelector(({ auth }: RootState) => auth);

  const openLogin = () => {
    navigate('/app/login');
  };

  const resetData = () => {
    dispatch(resetSignUpData());
  };

  const submitSignup = (data: SignupFormValues) => {
    Mixpanel.track(MixPanelConstants.signUp);
    dispatch(doSignup(data));
  };

  const getTermsAndConditions = () => {
    dispatch(getTerms());
  };

  return {
    openLogin,
    submitSignup,
    signUpAPIExecutionStatus,
    signUpAPIFailureMessage,
    signUpAPISuccessMessage,
    resetData,
    getTermsAndConditions,
    termsData,
    termsAPILoadingStatus,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
    signUpAPILoadingStatus,
  };
};

export default useSignup;
