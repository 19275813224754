import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { RootState } from '../../store/rootReducer';
import { setCurrentMapUnassignedRacks, setCurrentMapUnassignedLocations, setEditModalOpen } from '../../store/slices/maplayoutSlice';
import { FABRICOPTIONS, RACK } from '../../utils/constants';
import { useShallowEqualSelector } from '../../utils/helper';
import { maplayoutReducer } from '../../utils/types/reducerStateTypes';
import { FabricContext } from './context/FabricContext';

const useMaplayout = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    pointer,
    clickedArea,
    editModalOpen,
    selectedAreaData,
    layerMode,
    editWallMode,
    currentMapData,
    floors,
    mapAPILoadingStatus,
    floorAPIExecutionStatus,
    floorAPILoadingStatus,
    floorAPIFaliureMessage,
    currentFloor,
    saveAPILoadStatus,
    saveAPIFailureStatus,
    scaleValue,
    floorInpOpen,
    currentMapUnassignedRacks,
    isLocationsSyncwarePattern,
    currentMapLocations,
    currentMapUnassignedLocations,
    submitAPIExecutionStatus,
    submitAPILoadingStatus,
    submitAPIFailureMessage,
    submitPubnubMessage,
  }: maplayoutReducer = useShallowEqualSelector(({ maplayout }: RootState) => maplayout);
  const { recentProjects } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { currentProject, currentProjectData, projectDataAPILoadingStatus } = useShallowEqualSelector(
    ({ selectedData }: RootState) => selectedData,
  );
  const { data: inventoryData, reading: inventoryReading } = useShallowEqualSelector(({ inventory }: RootState) => inventory);
  const { canvas } = useContext(FabricContext);
  const toolsDisabled =
    currentMapData === undefined ||
    (currentMapData && Object.keys(currentMapData).length === 0) ||
    (currentProjectData && currentProjectData.is_sample_project) ||
    (submitPubnubMessage &&
      Object.keys(submitPubnubMessage).includes(currentFloor) &&
      submitPubnubMessage[currentFloor].status === 'IN_PROGRESS');

  const handleEditModalOpen = () => {
    dispatch(setEditModalOpen(true));
  };

  const handleEditModalClose = () => {
    dispatch(setEditModalOpen(false));
  };

  const updateUnassignedLocations = (rackName: string) => {
    if (isLocationsSyncwarePattern) {
      const updatedUnassignedLocations = currentMapUnassignedLocations.filter(
        (location: any) => !location.startsWith(rackName),
      );
      dispatch(setCurrentMapUnassignedLocations(updatedUnassignedLocations));
      const updatedRacks = currentMapUnassignedRacks.filter(
        (rack: any) => rack !== rackName,
      );
      dispatch(setCurrentMapUnassignedRacks(updatedRacks));
    }
  };

  const updateUnassignedLocationsOnObjectsChange = () => {
    if (isLocationsSyncwarePattern && canvas) {
      const json = canvas.toJSON(FABRICOPTIONS) as any;
      console.log(json);
      let assignedRacks = json.objects.map(
        (object: any) => object.area_type === RACK && object.group_name,
      );
      let totalRacks = currentMapLocations.map(
        (location: any) => location.split('-')[0],
      );
      totalRacks = [...new Set(totalRacks)];
      // remove duplicates
      assignedRacks = [...new Set(assignedRacks)];
      console.log(assignedRacks);
      const updatedUnassignedRacks = totalRacks.filter(
        (rack: any) => !assignedRacks.includes(rack),
      );
      const updatedUnassignedLocations = currentMapUnassignedLocations.filter(
        (location: any) => updatedUnassignedRacks.includes(location.split('-')[0]),
      );
      console.log('updatedUnassignedRacks', updatedUnassignedRacks);
      console.log('updatedUnassignedLocations', updatedUnassignedLocations);
      dispatch(setCurrentMapUnassignedRacks(updatedUnassignedRacks));
      dispatch(setCurrentMapUnassignedLocations(updatedUnassignedLocations));
    }
  };

  const findCountSuffixNumberForRack = (rackName: any, suffix: any) => {
    const filteredLocations = currentMapLocations.filter(location =>
      location.startsWith(rackName));
    const uniqueSufixesWithNumbers = filteredLocations[0].split('-');
    // remove numbers and extract unique suffixes
    const uniqueSufixes = uniqueSufixesWithNumbers.map(suff =>
      suff.slice(0, 2));
    const indexOfSuffix = uniqueSufixes.indexOf(suffix);
    const suffixes = filteredLocations.map(
      location => location.split('-')[indexOfSuffix],
    );
    const maxSuffix = Math.max(
      ...suffixes.map(suff => parseInt(suff.slice(2), 10)),
    );

    return maxSuffix;
  };

  return {
    origin,
    recentProjects,
    pointer,
    clickedArea,
    handleEditModalOpen,
    handleEditModalClose,
    editModalOpen,
    selectedAreaData,
    layerMode,
    editWallMode,
    currentMapData,
    floors,
    mapAPILoadingStatus,
    floorAPIExecutionStatus,
    floorAPIFaliureMessage,
    floorAPILoadingStatus,
    currentProject,
    currentFloor,
    saveAPILoadStatus,
    saveAPIFailureStatus,
    scaleValue,
    floorInpOpen,
    currentProjectData,
    currentMapUnassignedRacks,
    isLocationsSyncwarePattern,
    currentMapLocations,
    currentMapUnassignedLocations,
    updateUnassignedLocations,
    updateUnassignedLocationsOnObjectsChange,
    findCountSuffixNumberForRack,
    submitAPIExecutionStatus,
    submitAPILoadingStatus,
    submitAPIFailureMessage,
    submitPubnubMessage,
    toolsDisabled,
    inventoryData,
    inventoryReading,
    projectDataAPILoadingStatus,
  };
};

export default useMaplayout;
