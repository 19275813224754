import React, { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import {
  AddDeviceButton,
  CounterText,
  CustomSlider,
  GetStartedContainer,
  GetStartedHeading,
  GetStartedInnerContainer,
  GetStartedInnerText,
  GetStartedSubHeading,
  GetStartedTextContainer,
  GetStartedTitle,
  SliderContainer,
  StepAvatar,
} from '../styles';
import useProjects from '../useProjects';

type datatype = {
  name: string;
  title: string;
  desc: string;
  btnText: string;
  done: boolean;
  handle: () => void;
};

type stepsType = datatype[];

// const isDeviceVisible = process.env.REACT_APP_SHOW_DEVICES === 'true';
// const isSystemBVisible = process.env.REACT_APP_SHOW_SYSTEM_BUILDER === 'true';
const isInventoryVisible =
  process.env.REACT_APP_SHOW_WAREHOUSE_INVENTORY === 'true';
const isOrdersVisible = process.env.REACT_APP_SHOW_WAREHOUSE_ORDERS === 'true';

const GetStartedBlock = () => {
  const {
    createProject,
    openProjectModal,
    inventory,
    openInventoryImportModal,
    dashboard,
    openDashboard,
    orders,
    openOrdersImportModal,
    closeGetStarted,
    handleUserSawProgress,
    userSawProgress,
  } = useProjects();
  const [sliderValue, setSliderValue] = useState(0);
  const [data, setData] = useState<datatype>();
  const currentStep = sliderValue / 10;
  const stepCount = currentStep + 1;
  const { t } = useTranslation();

  const [steps, setSteps] = useState<stepsType>([
    {
      name: 'createProject',
      title: t('welcome.get_started_title1'),
      desc: t('welcome.get_started_desc1'),
      btnText: t('welcome.get_started_btn1'),
      done: createProject,
      handle: openProjectModal,
    },
    {
      name: 'inventory',
      title: t('welcome.get_started_title2'),
      desc: t('welcome.get_started_desc2'),
      btnText: t('welcome.get_started_btn1'),
      done: inventory,
      handle: openInventoryImportModal,
    },
    {
      name: 'orders',
      title: t('welcome.get_started_title4'),
      desc: t('welcome.get_started_desc4'),
      btnText: t('welcome.get_started_btn1'),
      done: orders,
      handle: openOrdersImportModal,
    },
    {
      name: 'dashboard',
      title: t('welcome.get_started_title3'),
      desc: t('welcome.get_started_desc3'),
      btnText: t('welcome.get_started_btn1'),
      done: dashboard,
      handle: openDashboard,
    },
  ]);

  useEffect(() => {
    let temp = [...steps];
    if (!isInventoryVisible) {
      const arr = steps.filter(step => step.name !== 'inventory');
      temp = arr;
    }
    if (!isOrdersVisible) {
      const arr = steps.filter(step => step.name !== 'orders');
      temp = arr;
    }
    temp.forEach(step => {
      if (step.name === 'createProject') {
        step.done = createProject;
      }
      if (step.name === 'inventory') {
        step.done = inventory;
      }
      if (step.name === 'dashboard') {
        step.done = dashboard;
      }
      if (step.name === 'orders') {
        step.done = orders;
      }
    });
    setSteps(temp);
  }, [createProject, inventory, dashboard, orders]);

  useEffect(() => {
    try {
      steps.forEach((step, index) => {
        if (!step.done) {
          setSliderValue(index * 10);
          throw 'Break';
        } else if (index === steps.length - 1) {
          setSliderValue(steps.length * 10);
        }
      });
    } catch (e) {
      if (e !== 'Break') throw e;
    }
    if (currentStep < steps.length) {
      setData(steps[currentStep]);
    } else {
      setData({
        title: t('welcome.get_started_title_final'),
        desc: t('welcome.get_started_desc_final'),
        btnText: '',
        done: true,
        handle: () => {},
        name: '',
      });
    }
  }, [sliderValue, steps]);

  useEffect(() => {
    if (userSawProgress) {
      closeGetStarted();
    } else if (!userSawProgress && currentStep === steps.length) {
      handleUserSawProgress();
    }
  }, [currentStep]);

  const getCurrentStep = () => {
    if (stepCount === steps.length + 1) {
      return <CheckIcon />;
    }
    return stepCount;
  };

  return (
    <GetStartedContainer>
      <GetStartedTitle variant="h6">Get Started!</GetStartedTitle>
      <GetStartedInnerContainer>
        <GetStartedTextContainer>
          <StepAvatar alt="counter">{getCurrentStep()}</StepAvatar>
          <GetStartedInnerText>
            <GetStartedHeading variant="subtitle1">
              {data?.title}
            </GetStartedHeading>
            <GetStartedSubHeading variant="subtitle2">
              {data?.desc}
            </GetStartedSubHeading>
          </GetStartedInnerText>
        </GetStartedTextContainer>
        <div>
          {stepCount < steps.length + 1 && (
            <AddDeviceButton
              title={data?.btnText}
              variant="text"
              endIcon={<ArrowForwardIcon />}
              onClick={data?.handle}
            />
          )}
        </div>
      </GetStartedInnerContainer>
      <SliderContainer>
        <CustomSlider
          valueLabelDisplay="auto"
          aria-label="Get started"
          value={stepCount < steps.length + 2 ? sliderValue : 0}
          size="medium"
          disabled
          max={steps.length * 10}
        />
        <CounterText variant="subtitle2">
          {currentStep}/{steps.length}
        </CounterText>
      </SliderContainer>
    </GetStartedContainer>
  );
};

export default GetStartedBlock;
