import { StatusEvent } from 'pubnub';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePubNub } from 'pubnub-react';
import { RootState } from '../rootReducer';
import { updateCardDetails } from '../slices/settingsSlice';
import { AppDispatch } from '../index';
import { updatePubnubMessages } from '../slices/aiTabSlice';
import { updateSubmitPubnubMessage } from '../slices/maplayoutSlice';
import { updateOptimizationPubnubMessages } from '../slices/optimizerTabSlice';

const usePubnubWatcher = () => {
  const pubnub = usePubNub();
  const companyName = useRef<string>('');
  const lastMessageTime = useRef<any>(0);
  const companyDataLoaded = useSelector(
    (state: RootState) => state.projects.companyDataLoaded,
  );
  const company = useSelector(
    (state: RootState) => state.projects.companyData?.companyId,
  );
  const { submitPubnubMessage } = useSelector((state: RootState) => state.maplayout);
  const { optimizationPubnubMessages } = useSelector((state: RootState) => state.optimizerTab);
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('authToken');

  const handlePubnubMessage = (e: any) => {
    // Messages will be received here...Parse the object, store in redux and use it in your screens
    const { message, timetoken } = e;
    localStorage.setItem('timetoken', timetoken);
    const { event, data } = message;
    if (event && data) {
      if (event === 'payment_method_updated') {
        dispatch(updateCardDetails(data));
      } else if (event === 'SOLUTION_FINDER') {
        dispatch(updatePubnubMessages(data));
      } else if (event.startsWith('SM_')) {
        const map_id = event.split('_')[1];
        const obj = {
          ...submitPubnubMessage,
          [map_id]: { ...data, floor_id: map_id },
        };
        dispatch(updateSubmitPubnubMessage(obj));
      } else if (event.startsWith('OP_')) {
        const map_id = event.split('_')[1];
        const obj = {
          ...optimizationPubnubMessages,
          [map_id]: { ...data, floor_id: map_id },
        };
        dispatch(updateOptimizationPubnubMessages(obj));
      }
    }
  };

  const handleInitialMessages = (
    status: any,
    res: any,
  ) => {
    console.log('status', status);
    console.log('res', res);
    if (status.statusCode === 200 && res && res.channels) {
      const { channels } = res;
      const firstChannel = Object.keys(channels)[0];
      const messages = channels[firstChannel];
      console.log('messages', messages);
      const filteredSolutionFinderMessages = messages.filter(
        (mess: any) =>
          !(
            typeof mess.message === 'string' || mess.message instanceof String
          ) &&
          mess.message.event &&
          mess.message.event === 'SOLUTION_FINDER',
      );
      const filteredSubmitMapMessages = messages.filter(
        (mess: any) =>
          !(
            typeof mess.message === 'string' || mess.message instanceof String
          ) &&
          mess.message.event &&
          mess.message.event.startsWith('SM_'),
      );
      const filteredOptimizationMessages = messages.filter(
        (mess: any) =>
          !(
            typeof mess.message === 'string' || mess.message instanceof String
          ) &&
          mess.message.event &&
          mess.message.event.startsWith('OP_'),
      );
      const lastSolutionFinderMessage = filteredSolutionFinderMessages[filteredSolutionFinderMessages.length - 1];
      const lastSubmitMapMessage = filteredSubmitMapMessages[filteredSubmitMapMessages.length - 1];
      const lastOptimizationMessage = filteredOptimizationMessages[filteredOptimizationMessages.length - 1];
      if (lastSolutionFinderMessage) {
        dispatch(updatePubnubMessages(lastSolutionFinderMessage.message.data));
      }
      if (lastSubmitMapMessage) {
        const submitObj = {
          ...submitPubnubMessage,
          [lastSubmitMapMessage.message.event.split('_')[1]]: lastSubmitMapMessage.message.data,
        };
        dispatch(updateSubmitPubnubMessage(submitObj));
      }
      if (lastOptimizationMessage) {
        const optimizationObj = {
          ...optimizationPubnubMessages,
          [lastOptimizationMessage.message.event.split('_')[1]]: {
            ...lastOptimizationMessage.message.data,
            floor_id: lastOptimizationMessage.message.event.split('_')[1],
          },
        };
        dispatch(updateOptimizationPubnubMessages(optimizationObj));
      }
    } else {
      console.log('Error in fetching initial messages');
    }
  };

  const handleStatusEvent = (statusEvent: StatusEvent) => {
    if (statusEvent.category === 'PNDisconnectedCategory') {
      console.log('Disconnected from PubNub server');
      pubnub.reconnect();
    }
    if (statusEvent.category === 'PNReconnectedCategory') {
      console.log('Reconnected to PubNub server');
      const chnls = [company];
      pubnub.fetchMessages(
        {
          channels: chnls,
          start: lastMessageTime.current,
          end: new Date().getTime(),
          count: 25,
        },
        (status, response) => {
          try {
            handleInitialMessages(status, response);
            if (response?.channels[Object.keys(chnls)[0]]?.length > 0) {
              lastMessageTime.current = response.channels[Object.keys(chnls)[0]][0].timetoken;
            }
          } catch (error) {
            console.error('Error in fetching initial messages:', error);
          }
        },
      );
    }
    if (statusEvent.category === 'PNNetworkDownCategory') {
      console.log('Network down');
    }
    if (statusEvent.category === 'PNNetworkUpCategory') {
      console.log('Network up');
      pubnub.reconnect();
    }
  };

  useEffect(() => {
    if (isAuthenticated && companyDataLoaded && company) {
      companyName.current = company;
      const channels = [company];
      console.log('subscribing to channels', channels);
      pubnub.subscribe({ channels });
      pubnub.addListener({
        message: handlePubnubMessage,
        status: handleStatusEvent,
      });
      pubnub.fetchMessages(
        {
          channels: channels,
          end: new Date().getTime(),
          count: 25,
        },
        (status, response) => {
          try {
            handleInitialMessages(status, response);
            if (response?.channels[Object.keys(channels)[0]]?.length > 0) {
              lastMessageTime.current = response.channels[Object.keys(channels)[0]][0].timetoken;
            }
          } catch (error) {
            console.error('Error in fetching initial messages:', error);
          }
        },
      );
    }
    if (!companyDataLoaded) {
      const channels = [companyName.current];
      if (channels.length > 0) { // Check if channels array is not empty
        pubnub.unsubscribe({ channels });
        pubnub.removeListener({
          message: handlePubnubMessage,
          status: handleStatusEvent,
        });
      }
      companyName.current = '';
    }
  }, [isAuthenticated, companyDataLoaded, company]);
};

export default usePubnubWatcher;
