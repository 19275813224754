import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../store';
import { sendVerificationEmail } from '../../../store/thunks/authThunk';
import { SignUpButton, SignUpText, CoverSubTitle, CoverTitle, SendButton } from '../../forgotpassword/styles';
import { SignupLeftItemGrid, SignupSuccessBlock } from '../styles';

type SignupSuccessProps = {
    email: string | null;
};

const SignupSuccess = ({ email }: SignupSuccessProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  return (
    <SignupLeftItemGrid
      item
      display="flex"
      justifyContent="center"
      alignSelf="center"
    >
      <SignupSuccessBlock>
        <CoverTitle variant="h4">
          {t('signup.signup_success_header')}
        </CoverTitle>
        <CoverSubTitle variant="body1">
          <Trans i18nKey="signup.signup_success_message" />
        </CoverSubTitle>
        <SendButton
          title={t('forgot.back_to_login_button')}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate('/app/login')}
        />
        <SignUpText variant="subtitle1">
          {t('signup.email_no_receive')}
          <SignUpButton
            title={t('forgot.email_resend')}
            variant="text"
            color="primary"
            sx={{ ml: 1 }}
            onClick={() => dispatch(sendVerificationEmail(email))}
          />
        </SignUpText>
      </SignupSuccessBlock>
    </SignupLeftItemGrid>
  );
};

export default SignupSuccess;
