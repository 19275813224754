const MixPanelConstants = {
  login: 'Login button clicked',
  signUp: 'Signup button clicked',
  couponApply: 'Coupon apply button clicked',
  couponRemoved: 'Coupon removed button clicked',
  cardSubmit: 'Card submit button clicked',
  enterprisePlan: 'Enterprise selected',
  createProject: 'Create project button clicked',
  projectSave: 'project save button clicked',
  editProject: 'edit project button clicked',
  projectEditSave: 'project edit save button clicked',
  deleteProject: 'Delete project button clicked',
  deleteProjectSubmit: 'Delete Project submit clicked',
  createArea: 'Area created',
  editArea: 'Area edited',
  deleteArea: 'Area deleted',
  deviceAdd: 'Device Add button clicked',
  logicBuilderAdded: 'Added rule in logic builder',
};

export default MixPanelConstants;
