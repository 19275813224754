import { Box, styled, BoxProps } from '@mui/material';

const BodyContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'grid',
  height: '100%',
  width: '-webkit-fill-available',
  gridTemplateColumns: 'min-content auto',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'none',
  },
}));

const AppbarMainContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}));

const MainContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'grid',
  width: '-webkit-fill-available',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  overflow: 'hidden',
}));

export { BodyContainer, AppbarMainContainer, MainContainer };
