import { createSlice } from '@reduxjs/toolkit';
import { menuReducer } from '../../utils/types/reducerStateTypes';

const initialState: menuReducer = {
  menuIndex: 0,
  sidebarState: true,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    handleMenuItemClick: (state, action) => {
      state.menuIndex = action.payload;
    },
    handleSidebarState: (state, action) => {
      state.sidebarState = action.payload;
    },
  },
});

export const { handleMenuItemClick, handleSidebarState } = menuSlice.actions;

export default menuSlice;
