import React from 'react';
import { Radio as MUIRadio, RadioProps } from '@mui/material';
import { BpCheckedIcon, BpIcon } from './style';

// Inspired by blueprintjs
const Radio = (props: RadioProps) => (
  <MUIRadio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  />
);

export default Radio;
