import { createAsyncThunk } from '@reduxjs/toolkit';
import { tourInfo } from '../../services/apiDefinition';

import axiosApiInstance from '../../services/axios';

export const getTour = createAsyncThunk(
  'tour/getTour',
  async () => {
    try {
      const response = await axiosApiInstance.get(tourInfo);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
