import React, { useState } from 'react';
import { Divider, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { ArrowButton, AccountMenuContainer, ItemIcon } from '../styles';
import useAppbar from '../useAppbar';
import GetStarted from './getStarted';

const AccountMenu = () => {
  const { t } = useTranslation();
  const {
    handleClickItem,
    logoutUser,
  } = useAppbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [tourAnchorEl, setTourAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const open = Boolean(anchorEl);
  const tourOpen = Boolean(tourAnchorEl);
  const [tourClicked, setTourClicked] = useState(false);
  const handleClick = (currentTarget: EventTarget & HTMLButtonElement) => {
    setAnchorEl(currentTarget);
    setTourAnchorEl(currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTourClose = () => {
    setTourAnchorEl(null);
    setTourClicked(false);
  };
  const logOut = () => {
    handleClose();
    logoutUser();
  };

  return (
    <>
      <ArrowButton
        onClick={e => handleClick(e.currentTarget)}
        id="accountsArrowMenu"
      >
        <ArrowDropDownIcon />
      </ArrowButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ top: '64px' }}
      >
        <AccountMenuContainer>
          <MenuItem
            onClick={() => {
              handleClose();
              setTourClicked(true);
            }}
          >
            <ItemIcon>
              <PlaylistAddCheckIcon />
            </ItemIcon>
            <ListItemText>{t('appbar.getstarted_test')}</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              handleClickItem('/app/settings');
            }}
          >
            <ItemIcon>
              <SettingsIcon />
            </ItemIcon>
            <ListItemText>{t('appbar.settings_text')}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={logOut}>
            <ItemIcon>
              <LogoutIcon />
            </ItemIcon>
            <ListItemText>{t('appbar.logout_text')}</ListItemText>
          </MenuItem>
        </AccountMenuContainer>
      </Menu>
      <GetStarted
        open={tourOpen}
        anchorEl={tourAnchorEl}
        handleClose={handleTourClose}
        tourClicked={tourClicked}
      />
    </>
  );
};

export default AccountMenu;
