import React from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DoubleLeftArrow,
  HalfSidebarLogo,
  SidebarLogo,
} from '../../../assets/images';
import syncwarelogo from '../../../assets/images/syncwarelogo.png';
import { LogoContainer } from '../styles';
import styles from '../sideBar.module.css';

type LogoProps = {
  open: boolean;
  toggleSidebar: () => void;
};

const Logo = ({ open, toggleSidebar }: LogoProps) => (
  <LogoContainer className={styles.logo} id="logoToggle">
    {open && window.innerWidth <= 599 ? (
      <img src={syncwarelogo} alt="Syncware Logo" className={styles.logoImg} />
    ) : null}
    {open ? (
      <>{window.innerWidth > 599 ? <SidebarLogo /> : null}</>
    ) : (
      <IconButton onClick={toggleSidebar}>
        <HalfSidebarLogo />
      </IconButton>
    )}
    {open && (
      <IconButton onClick={toggleSidebar}>
        {window.innerWidth < 599 ? (
          <CloseIcon style={{ color: 'white' }} />
        ) : (
          <DoubleLeftArrow />
        )}
      </IconButton>
    )}
  </LogoContainer>
);

export default Logo;
