import React from 'react';
import { MUIModal } from './style';

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  props?: Object;
  onClose?: () => void;
};

const Modal = ({ children, open, onClose, ...props }: ModalProps) => (
  <div>
    <MUIModal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={onClose || (() => {})}
      {...props}
    >
      <>{children}</>
    </MUIModal>
  </div>
);
export default Modal;
