import { createSlice } from '@reduxjs/toolkit';
import { API_FAILURE_STATUS, API_PENDING_STATUS, API_SUCCESS_STATUS } from '../../utils/constants';
import { optimizerTabReducer } from '../../utils/types/reducerStateTypes';
import { getFloors, getMapData } from '../thunks/maplayoutThunk';
import { getOptimizationResult, optimizeInventory } from '../thunks/optimizerThunk';

const initClickedArea = {
  areaType: '-',
  label: '-',
  data: null,
  items: [],
};

const initialState: optimizerTabReducer = {
  activeStep: 0,
  completed: {},
  tabIndex: 0,
  floors: [],
  currentMapData: {},
  currentFloor: '',
  mapAPIFaliureStatus: false,
  mapAPILoadingStatus: false,
  currentFloorOptimizedData: {},
  startOptimizeApiLoadingStatus: false,
  startOptimizeApiExecutionStatus: API_PENDING_STATUS,
  startOptimizeApiFailureMessage: '',
  getOptimizeApiLoadingStatus: false,
  getOptimizeApiExecutionStatus: API_PENDING_STATUS,
  getOptimizeApiFailureMessage: '',
  runningAnalysis: null,
  controlAnalysis: 0,
  optimizationPubnubMessages: {},
  clickedArea: initClickedArea,
};

const optimizerTab = createSlice({
  name: 'optimizerTab',
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setCompleted: (state, action) => {
      state.completed = action.payload;
    },
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    resetOptmizeAPIStatus: state => {
      state.startOptimizeApiLoadingStatus = false;
      state.startOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.startOptimizeApiFailureMessage = '';
      state.getOptimizeApiLoadingStatus = false;
      state.getOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.getOptimizeApiFailureMessage = '';
    },
    setRunningAnalysis: (state, action) => {
      state.runningAnalysis = action.payload;
    },
    setCurrentFloor: (state, action) => {
      state.currentFloor = action.payload;
    },
    resetFloorMapData: state => {
      state.currentMapData = {};
      state.currentFloor = '';
    },
    resetOptimizerTab: state => {
      state.activeStep = 0;
      state.completed = {};
      state.tabIndex = 0;
      state.currentFloorOptimizedData = {};
      state.startOptimizeApiLoadingStatus = false;
      state.startOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.startOptimizeApiFailureMessage = '';
      state.getOptimizeApiLoadingStatus = false;
      state.getOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.getOptimizeApiFailureMessage = '';
      state.runningAnalysis = null;
      state.controlAnalysis = 0;
    },
    updateOptimizationPubnubMessages: (state, action) => {
      state.optimizationPubnubMessages = action.payload;
    },
    setClickedArea: (state, action) => {
      state.clickedArea = action.payload;
    },
    resetClickedArea: state => {
      state.clickedArea = initClickedArea;
    },
  },
  extraReducers: {
    [optimizeInventory.pending.type]: state => {
      state.startOptimizeApiLoadingStatus = true;
      state.startOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.currentFloorOptimizedData = {};
    },
    [optimizeInventory.fulfilled.type]: state => {
      state.startOptimizeApiLoadingStatus = false;
      state.startOptimizeApiExecutionStatus = API_SUCCESS_STATUS;
    },
    [optimizeInventory.rejected.type]: (state, action) => {
      state.startOptimizeApiLoadingStatus = false;
      state.startOptimizeApiExecutionStatus = API_FAILURE_STATUS;
      state.startOptimizeApiFailureMessage = action.error.message;
    },

    [getOptimizationResult.pending.type]: state => {
      state.getOptimizeApiLoadingStatus = true;
      state.getOptimizeApiExecutionStatus = API_PENDING_STATUS;
      state.currentFloorOptimizedData = {};
    },
    [getOptimizationResult.fulfilled.type]: (state, action) => {
      state.getOptimizeApiLoadingStatus = false;
      state.getOptimizeApiExecutionStatus = API_SUCCESS_STATUS;
      state.currentFloorOptimizedData = action.payload;
      if (action.payload?.mode) {
        state.runningAnalysis = action.payload.mode;
        if (action.payload.mode === 'xyz') {
          state.controlAnalysis = 1;
        } else if (action.payload.mode === 'abc') {
          state.controlAnalysis = 0;
        }
      }
    },
    [getOptimizationResult.rejected.type]: (state, action) => {
      state.getOptimizeApiLoadingStatus = false;
      state.getOptimizeApiExecutionStatus = API_FAILURE_STATUS;
      state.getOptimizeApiFailureMessage = action.error.message;
    },

    [getFloors.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
    },
    [getFloors.fulfilled.type]: (state, action) => {
      state.mapAPILoadingStatus = false;
      if (action.payload.length === 0) {
        state.currentMapData = {};
      } else {
        state.currentFloor = action.payload[0].uuid;
      }
      state.floors = action.payload;
    },
    [getFloors.rejected.type]: state => {
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },

    [getMapData.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
      state.currentMapData = {};
    },
    [getMapData.fulfilled.type]: (state, action) => {
      state.mapAPILoadingStatus = false;
      state.currentMapData = action.payload;
    },
    [getMapData.rejected.type]: state => {
      state.currentMapData = {};
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },
  },
});

export const {
  setActiveStep,
  setCompleted,
  setTabIndex,
  resetOptmizeAPIStatus,
  resetOptimizerTab,
  setRunningAnalysis,
  setCurrentFloor,
  resetFloorMapData,
  updateOptimizationPubnubMessages,
  setClickedArea,
  resetClickedArea,
} = optimizerTab.actions;

export default optimizerTab;
