import {
  Box,
  styled,
  BoxProps,
  Grid,
  Paper,
  PaperProps,
  Card,
  CardProps,
  ListItemIcon,
  MenuItem,
  Typography,
  CardHeader,
  IconButton,
} from '@mui/material';

const ProjectsPageBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: '40px 60px',
  overflow: 'auto',
  '& .projectPageTitle': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1rem',
  },
}));

const ProjectsContainer = styled(Grid)(() => ({
  paddingTop: '26px',
}));

const NoProjectsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const ProjectBox = styled(Card)<CardProps>(({ theme }) => ({
  height: '144px',
  backgroundColor: theme.palette.background.paper,
  '&: hover': {
    border: '1px solid #027EDE',
    '& .MuiCardHeader-avatar': {
      color: theme.palette.primary.main,
    },
    cursor: 'pointer',
  },
  '& .MuiCardActionArea-root': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  '& .MuiCardActions-root': {
    height: 'fit-content',
    position: 'absolute',
    right: '0',
  },
  '& .add-project-button': {
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCardHeader-root': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '49px',
      marginBottom: '4px',
      color: theme.palette.primary.main,
    },
  },
}));

const ActionBox = styled(Paper)<PaperProps>(({ theme }) => ({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
  width: '600px',
  backgroundColor: theme.palette.background.paper,
  padding: '24px',
}));

const NewModal = styled(Paper)<PaperProps>(({ theme }) => ({
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2))',
  width: '300px',
  backgroundColor: theme.palette.background.paper,
  padding: '24px',
}));

const ButtonBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '10px',
}));

const ActionButtonsBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  paddingTop: '24px',
  '& .action': {
    width: '93px',
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    height: '42px',
  },
}));

const ItemIcon = styled(ListItemIcon)(() => ({
  '& .MuiSvgIcon-root': {
    color: '#A1A9B1', // todo
  },
}));

const ItemContainer = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: '#A1A9B1', // todo
}));

const ActionMenuContainer = styled(Paper)(() => ({
  width: '181px',
  boxShadow: 'none',
}));

const CardBody = styled(CardHeader)(() => ({
  alignItems: 'start',
}));

const AreasText = styled(Typography)(({ theme }) => ({
  marginTop: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  height: 'fit-content',
  color: theme.palette.text.primary,
}));

const CreateProject = styled(Typography)(() => ({
  padding: '0 0 24px 0',
}));

const FormSubtitles = styled(Typography)(() => ({
  paddingTop: '24px',
}));

const TextBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '24px',
  '& .not-last': {
    marginRight: '16px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.light,
  },
}));

export {
  ProjectsPageBox,
  ProjectsContainer,
  NoProjectsContainer,
  ProjectBox,
  ActionBox,
  ActionButtonsBox,
  ItemIcon,
  ItemContainer,
  ActionMenuContainer,
  CardBody,
  AreasText,
  ActionButton,
  CreateProject,
  FormSubtitles,
  TextBox,
  NewModal,
  ButtonBox,
};
