import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch } from '../../store';
import { doLogin } from '../../store/thunks/authThunk';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import { LoginFormValues } from '../../utils/types/formTypes';
import { Mixpanel } from '../../utils/mixPanel';
import MixPanelConstants from '../../utils/mixpanelConstants';

const useLogin = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loginAPIExecutionStatus,
    loginAPIFailureMessage,
    userData,
    logoutData,
    loginAPILoadingStatus,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
  } = useShallowEqualSelector(({ auth }: RootState) => auth);

  const submitLogin = (data: LoginFormValues) => {
    Mixpanel.track(MixPanelConstants.login);
    dispatch(doLogin(data));
    sessionStorage.setItem('autoLogoutMessage', '');
  };

  const openSignup = () => {
    navigate('/app/signup');
  };

  return {
    loginAPIExecutionStatus,
    loginAPIFailureMessage,
    submitLogin,
    openSignup,
    userData,
    logoutData,
    loginAPILoadingStatus,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
  };
};

export default useLogin;
