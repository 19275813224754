import React, { useCallback } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import wallTexture from '../../../assets/textures/wall.jpg';
import floorTexture from '../../../assets/textures/floor.jpg';
import { AMBIENT_LIGHT_COLOR, BACKGROUND_COLOR, DIRECTIONAL_LIGHT_COLOR, FORBIDDENCOLOR, DISPATCHCOLOR, STARTINGCOLOR, WIRE_FRAME_COLOR, RACKCOLORS } from '../../../utils/constants';
import { CustomMesh } from '../../../utils/types/threeJsTypes';
import useOptimizer from '../useOptimizer';

type ThreeJsContextType = {
  renderer: THREE.Renderer | null;
  scene: THREE.Scene | null;
  camera: THREE.PerspectiveCamera | null;
  controls: OrbitControls | null;
  initCanvas: (canvas: HTMLCanvasElement | null) => void;
  animate: () => void;
  construct3DModel: () => void;
  updateVisualization: (visualization: string) => void;
  resetCameraPosition: () => void;
  topView: () => void;
};

const initialState: ThreeJsContextType = {} as ThreeJsContextType;
const MAXWIDTH = 600;
const MAXHEIGHT = 600;

export const ThreeJsContext =
  React.createContext<ThreeJsContextType>(initialState);

export const OptimizerThreeJsContextBeforeProvider = ({ children }: any) => {
  const [renderer, setRenderer] = React.useState<THREE.Renderer | null>(null);
  const [scene, setScene] = React.useState<THREE.Scene | null>(null);
  const [camera, setCamera] = React.useState<THREE.PerspectiveCamera | null>(null);
  const [controls, setControls] = React.useState<OrbitControls | null>(null);
  const { currentMapData, controlAnalysis } = useOptimizer();
  const { metaData: canvasInJson } = currentMapData;

  const onWindowResize = (el: HTMLCanvasElement) => {
    // set canvas size to 100%
    el.style.width = '100%';
    el.style.height = '100%';
    el.width = el.offsetWidth;
    el.height = el.offsetHeight;
    if (camera && renderer) {
      console.log(camera.aspect);
      camera.aspect = el.clientWidth / el.clientHeight;
      console.log('camera.aspect', camera.aspect);
      camera.updateProjectionMatrix();
      renderer.setSize(el.clientWidth, el.clientHeight);
    }
  };

  // const onMouseMove = (event: any) => {
  //   event.preventDefault();
  //   const mouse = new THREE.Vector2();
  //   mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  //   mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  //   const raycaster = new THREE.Raycaster();
  //   raycaster.setFromCamera(mouse, camera!);
  //   const intersects = raycaster.intersectObjects(scene!.children);
  //   if (intersects.length > 0) {
  //     const arr = intersects.filter((item) => item.object.type === 'Mesh');
  //     if (arr.length > 0) {
  //       console.log('name', arr[0].object.name);
  //     }
  //   }
  // };

  const initCanvas = useCallback((el: HTMLCanvasElement | null) => {
    if (!el) {
      return;
    }

    const tempScene = new THREE.Scene();
    tempScene.background = new THREE.Color(BACKGROUND_COLOR);
    // tempScene.fog = new THREE.FogExp2(0x021D49, 0.0010);
    const ambient = new THREE.AmbientLight(AMBIENT_LIGHT_COLOR, 0.6);
    tempScene.add(ambient);
    const directionalLight = new THREE.DirectionalLight(DIRECTIONAL_LIGHT_COLOR, 0.6);
    directionalLight.position.set(0, 300, 300);
    tempScene.add(directionalLight);

    // const axesHelper = new THREE.AxesHelper(1000);
    // tempScene.add(axesHelper);

    // const gridHelper = new THREE.GridHelper(650, 30);
    // gridHelper.rotation.x = Math.PI / 2;
    // tempScene.add(gridHelper);

    const tempCamera = new THREE.PerspectiveCamera(
      60,
      el.clientWidth / el.clientHeight,
      0.1,
      10000,
    );

    tempCamera.up.set(0, 0, 1);
    tempCamera.position.set(0, 300, 300);
    tempCamera.lookAt(0, 0, 0);
    const tempControls = new OrbitControls(tempCamera, el);
    tempControls.maxPolarAngle = Math.PI / 2;
    tempControls.minDistance = 10;
    tempControls.maxDistance = 500;
    tempControls.enablePan = false;

    const tempRenderer = new THREE.WebGLRenderer({ canvas: el, antialias: true });
    tempRenderer.setSize(el.clientWidth, el.clientHeight);
    tempRenderer.setPixelRatio(window.devicePixelRatio);
    tempRenderer.shadowMap.enabled = true;
    tempRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
    tempRenderer.outputEncoding = THREE.sRGBEncoding;

    setControls(tempControls);
    setRenderer(tempRenderer);
    setScene(tempScene);
    setCamera(tempCamera);

    window.addEventListener('resize', () => onWindowResize(el));
    // el.addEventListener('mousemove', onMouseMove);
  }, []);

  const constructWalls = () => {
    if (canvasInJson.walls && canvasInJson.walls.vertices && canvasInJson.walls.vertices.length > 0) {
      const xScaleFavtor = MAXWIDTH / canvasInJson.image_dimension.width;
      const yScaleFavtor = MAXHEIGHT / canvasInJson.image_dimension.height;
      const scaleFactor = Math.min(xScaleFavtor, yScaleFavtor);
      const shiftX = (canvasInJson.image_dimension.width * scaleFactor) / 2;
      const shiftY = (canvasInJson.image_dimension.height * scaleFactor) / 2;
      const points = [...canvasInJson.walls.vertices];
      const wallTex = new THREE.TextureLoader().load(wallTexture);
      wallTex.encoding = THREE.sRGBEncoding;
      wallTex.wrapS = THREE.RepeatWrapping;
      wallTex.wrapT = THREE.RepeatWrapping;
      wallTex.repeat.set(4, 1);
      const wallMaterial = new THREE.MeshBasicMaterial({ map: wallTex, side: THREE.DoubleSide });
      const floorMaterial = new THREE.MeshStandardMaterial({ side: THREE.DoubleSide });
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(floorTexture, (texture) => {
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.encoding = THREE.sRGBEncoding;
        texture.repeat.set(0.01, 0.01);
        floorMaterial.map = texture;
        floorMaterial.needsUpdate = true;
      });
      const lineMaterial = new THREE.LineBasicMaterial({ color: WIRE_FRAME_COLOR });
      const floorPoints: any[] = [];
      for (let i = 0; i < points.length; i++) {
        const x1 = points[i].x * scaleFactor - shiftX;
        const y1 = points[i].y * scaleFactor - shiftY;
        let x2;
        let y2;
        if (i === points.length - 1) {
          x2 = points[0].x * scaleFactor - shiftX;
          y2 = points[0].y * scaleFactor - shiftY;
        } else {
          x2 =
            points[i + 1].x * scaleFactor - shiftX;
          y2 =
            points[i + 1].y * scaleFactor - shiftY;
        }
        floorPoints.push([-x1, y1]);
        // if (i === points.length - 1) {
        //   floorPoints.push([-x2, y2]);
        // }
        const wallGeometry = new THREE.BufferGeometry();
        const wallVertices = new Float32Array([
          -x1,
          y1,
          0, // vertex 1
          -x1,
          y1,
          10, // vertex 2
          -x2,
          y2,
          0, // vertex 3
          -x2,
          y2,
          10, // vertex 4
        ]);
        // define texture coordinates for wallGeometry
        const wallUvs = new Float32Array([
          0,
          0, // vertex 1
          0,
          1, // vertex 2
          1,
          0, // vertex 3
          1,
          1, // vertex 4
        ]);
        wallGeometry.setAttribute('uv', new THREE.BufferAttribute(wallUvs, 2));
        wallGeometry.setAttribute('position', new THREE.BufferAttribute(wallVertices, 3));
        wallGeometry.setIndex([0, 2, 1, 2, 3, 1]);
        const wall = new THREE.Mesh(wallGeometry, wallMaterial);
        const geo = new THREE.EdgesGeometry(wall.geometry);
        const wireframe = new THREE.LineSegments(geo, lineMaterial);
        wall.add(wireframe);
        wall.castShadow = true;
        wall.receiveShadow = true;
        scene!.add(wall);
      }
      const floorGeometry = new THREE.Shape(floorPoints.map((point) => new THREE.Vector2(point[0], point[1])));
      const shapeGeometry = new THREE.ShapeGeometry(floorGeometry);
      const floor = new THREE.Mesh(shapeGeometry, floorMaterial);
      floor.receiveShadow = true;
      scene!.add(floor);
    }
  };

  const getBinColor = (value: string) => {
    switch (value) {
      case 'A':
      case 'X':
        return RACKCOLORS[0];
      case 'B':
      case 'Y':
        return RACKCOLORS[1];
      case 'C':
      case 'Z':
        return RACKCOLORS[2];
      default:
        return '0X000000';
    }
  };

  const constructBays = () => {
    if (canvasInJson.racks.length > 0) {
      console.log(canvasInJson);
      const mat = new THREE.LineBasicMaterial({ color: WIRE_FRAME_COLOR });
      const xScaleFavtor = MAXWIDTH / canvasInJson.image_dimension.width;
      const yScaleFavtor = MAXHEIGHT / canvasInJson.image_dimension.height;
      const scaleFactor = Math.min(xScaleFavtor, yScaleFavtor);
      const shiftX = (canvasInJson.image_dimension.width * scaleFactor) / 2;
      const shiftY = (canvasInJson.image_dimension.height * scaleFactor) / 2;
      const bayHeight = 6;
      canvasInJson.racks.forEach((rack: any) => {
        const width =
          rack.bays[0].virtual_properties.horizontal_length * scaleFactor;
        const height =
          rack.bays[0].virtual_properties.vertical_length * scaleFactor;
        rack.bays.forEach((bay: any) => {
          const geometry = new THREE.BoxGeometry(width, height, bayHeight);
          const x =
            bay.virtual_properties.top_left_x * scaleFactor - shiftX;
          const y =
            bay.virtual_properties.top_left_y * scaleFactor - shiftY;
          let z = 0;
          // sort levels by level_index from 0 to n & store it in a new array
          const sortedLevels = [...bay.levels].sort((a: any, b: any) => a.level_index - b.level_index);
          sortedLevels.forEach((level: any) => {
            const analysis = controlAnalysis === 0 ? 'ABC' : 'XYZ';
            const color = getBinColor(level.bins[0][analysis]);
            const material = new THREE.MeshStandardMaterial({ color: color, transparent: true, opacity: 0.8 });
            const cube: CustomMesh = new THREE.Mesh(geometry, material) as unknown as CustomMesh;
            cube.name = level.level_label;
            cube.areaType = 'bay-level';
            cube.userData = {
              ABC: level.bins[0].ABC,
              XYZ: level.bins[0].XYZ,
            };
            const geo = new THREE.EdgesGeometry(cube.geometry);
            const wireframe = new THREE.LineSegments(geo, mat);
            if (level.level_index === 0) {
              z = bayHeight / 2;
            } else {
              z += bayHeight + 3;
            }
            cube.add(wireframe);
            cube.position.set(-(x + width / 2), y + height / 2, z);
            cube.castShadow = true;
            cube.receiveShadow = true;
            scene!.add(cube);
          });
        });
      });
    }
  };

  const constructForbiddenAreas = () => {
    if (canvasInJson.forbidden_zones.length > 0) {
      const mat = new THREE.LineBasicMaterial({ color: WIRE_FRAME_COLOR });
      const xScaleFavtor = MAXWIDTH / canvasInJson.image_dimension.width;
      const yScaleFavtor = MAXHEIGHT / canvasInJson.image_dimension.height;
      const scaleFactor = Math.min(xScaleFavtor, yScaleFavtor);
      const shiftX = (canvasInJson.image_dimension.width * scaleFactor) / 2;
      const shiftY = (canvasInJson.image_dimension.height * scaleFactor) / 2;
      canvasInJson.forbidden_zones.forEach((area: any) => {
        const width = area.virtual_properties.horizontal_length * scaleFactor;
        const height = area.virtual_properties.vertical_length * scaleFactor;
        const geometry = new THREE.BoxGeometry(width, height, 0);
        const material = new THREE.MeshStandardMaterial({ color: FORBIDDENCOLOR, opacity: 0.8 });
        const x = area.virtual_properties.top_left_x * scaleFactor - shiftX;
        const y = area.virtual_properties.top_left_y * scaleFactor - shiftY;
        const cube = new THREE.Mesh(geometry, material);
        cube.name = area.name;
        const geo = new THREE.EdgesGeometry(cube.geometry);
        const wireframe = new THREE.LineSegments(geo, mat);
        cube.add(wireframe);
        cube.position.set(-(x + (width / 2)), y + (height / 2), 0.5);
        scene!.add(cube);
      });
    }
  };

  const constructPickerPoints = (key: string) => {
    if (canvasInJson[key].length > 0) {
      const mat = new THREE.LineBasicMaterial({ color: WIRE_FRAME_COLOR });
      const xScaleFavtor = MAXWIDTH / canvasInJson.image_dimension.width;
      const yScaleFavtor = MAXHEIGHT / canvasInJson.image_dimension.height;
      const scaleFactor = Math.min(xScaleFavtor, yScaleFavtor);
      const shiftX = (canvasInJson.image_dimension.width * scaleFactor) / 2;
      const shiftY = (canvasInJson.image_dimension.height * scaleFactor) / 2;
      canvasInJson[key].forEach((point: any) => {
        const width = point.virtual_properties.horizontal_length * scaleFactor;
        const height = point.virtual_properties.vertical_length * scaleFactor;
        const x =
          (point.virtual_properties.top_left_x * scaleFactor) - shiftX;
        const y =
          (point.virtual_properties.top_left_y * scaleFactor) - shiftY;
        const geometry = new THREE.BoxGeometry(width, height, 0);
        const material = new THREE.MeshStandardMaterial({ color: key === 'picker_dispatch_point' ? DISPATCHCOLOR : STARTINGCOLOR, opacity: 0.8 });
        const cube = new THREE.Mesh(geometry, material);
        const geo = new THREE.EdgesGeometry(cube.geometry);
        const wireframe = new THREE.LineSegments(geo, mat);
        cube.add(wireframe);
        cube.position.set(-(x + (width / 2)), y + (height / 2), 0.5);
        cube.castShadow = true;
        cube.receiveShadow = true;
        scene!.add(cube);
        const loader = new GLTFLoader();
        loader.load('https://syncwarecdn.blob.core.windows.net/models/forklift_truck.glb', (gltf) => {
          const model = gltf.scene; // the first child is usually the mesh
          model.scale.set(0.05, 0.05, 0.05); // scale it down
          // rotate it to face the correct direction
          model.rotation.set(Math.PI / 2, 0, 0);
          // get the height of the model
          const box = new THREE.Box3().setFromObject(model);
          const modelHeight = box.getSize(new THREE.Vector3()).z;
          model.position.set(-(x + (width / 2)), y + (height / 2), modelHeight / 2); // set the position
          scene!.add(model); // add the model to the scene
        });
      });
    }
  };

  const construct3DModel = () => {
    if (!scene || !canvasInJson) {
      return;
    }
    console.log(canvasInJson);
    constructBays();
    constructForbiddenAreas();
    constructPickerPoints('picker_dispatch_point');
    constructPickerPoints('picker_starting_point');
    constructWalls();
  };

  const animate = () => {
    if (!canvasInJson) {
      return;
    }
    requestAnimationFrame(animate);
    if (renderer && scene && camera) {
      renderer.render(scene, camera);
    }
  };

  const updateVisualization = (visuals: string) => {
    if (scene) {
      scene.traverse((child: any) => {
        if (child.areaType && child.areaType === 'slot') {
          const key = visuals === '0' ? 'ABC' : 'XYZ';
          const char = child.userData[key];
          const color = getBinColor(char);
          console.log(color);
          child.material.color.set(color);
        }
      });
    }
  };

  const resetCameraPosition = () => {
    if (camera) {
      camera.up.set(0, 0, 1);
      camera.position.set(0, 300, 300);
      camera.lookAt(0, 0, 0);
    }
  };

  const topView = () => {
    if (camera) {
      // camera.up.set(0, 0, 1);
      camera.position.set(0, 0, 300);
      camera.lookAt(0, 0, 0);
    }
  };

  return (
    <ThreeJsContext.Provider
      // eslint-disable-next-line
      value={{
        renderer,
        scene,
        camera,
        controls,
        initCanvas,
        animate,
        construct3DModel,
        updateVisualization,
        resetCameraPosition,
        topView,
      }}
    >
      {children}
    </ThreeJsContext.Provider>
  );
};
