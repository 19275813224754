import React, { useEffect } from 'react';
// import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Toaster } from 'react-hot-toast';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import Routing from './navigation/routes';
import { persistor, store } from './store';
// import { darkTheme } from './theme';
import './translation/i18n';
import { FabricContextProvider } from './pages/maplayout/context/FabricContext';
import { ThreeJsContextProvider } from './pages/maplayout/context/ThreeJsContext';
import { OptimizerContextProvider } from './pages/optimizerTab/context/fabricContext';
import { OptimizerThreeJsContextAfterProvider } from './pages/optimizerTab/context/ThreeJsContextAfter';
import { OptimizerThreeJsContextBeforeProvider } from './pages/optimizerTab/context/ThreeJsContextBefore';

const App = () => {
  const pubnub = new PubNub({
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY!,
    userId: process.env.REACT_APP_PUBNUB_USER_ID!,
    restore: true,
    autoNetworkDetection: true,
  });

  useEffect(() => {
    hotjar.initialize(parseInt(`${process.env.REACT_APP_HOTJAR_HJID}`, 10), parseInt(`${process.env.REACT_APP_HOTJAR_HJSV}`, 10));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PubNubProvider client={pubnub}>
          <FabricContextProvider>
            <ThreeJsContextProvider>
              <OptimizerContextProvider>
                <OptimizerThreeJsContextAfterProvider>
                  <OptimizerThreeJsContextBeforeProvider>
                    {/* <ThemeProvider theme={darkTheme}> */}
                    <Routing />
                    <Toaster reverseOrder={false} />
                    {/* </ThemeProvider> */}
                  </OptimizerThreeJsContextBeforeProvider>
                </OptimizerThreeJsContextAfterProvider>
              </OptimizerContextProvider>
            </ThreeJsContextProvider>
          </FabricContextProvider>
        </PubNubProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
