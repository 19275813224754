import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipBody, ActionsContainer } from './styles';

type props = {
  index: number;
  isLastStep: boolean;
  step: any;
  backProps: any;
  primaryProps: any;
  skipProps: any;
  tooltipProps: any;
  size: number;
};

const TourTooltip = ({
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  size,
}: props) => {
  const { t } = useTranslation();
  return (
    <TooltipBody {...tooltipProps}>
      <Box display="flex" alignItems="center">
        <Typography variant="caption">
          {`Step ${index + 1} of ${size}`}
        </Typography>
        <IconButton {...skipProps}>
          <Close />
        </IconButton>
      </Box>

      <Box>
        {step.title && (
          <Typography variant="subtitle1">{step.title}</Typography>
        )}
        {step.content && step.content}
      </Box>
      <ActionsContainer className="tourActions">
        {!isLastStep && (
          <Button {...skipProps} variant="text">
            {t('tour.footer.skip')}
          </Button>
        )}
        <Box className="end-actions">
          {index > 0 && (
            <Button {...backProps} variant="text">
              {t('tour.footer.back')}
            </Button>
          )}
          <Button {...primaryProps} variant="contained">
            {!isLastStep ? t('tour.footer.next') : t('tour.footer.done')}
          </Button>
        </Box>
      </ActionsContainer>
    </TooltipBody>
  );
};

export default TourTooltip;
