import React from 'react';
import { Typography } from '@mui/material';
import { UserDetailsContainer, UserProfileContainer } from '../styles';
import AccountMenu from './accountMenu';
import useAppbar from '../useAppbar';
import UserAvatar from './userAvatar';

const UserMenu = () => {
  const { role, name } = useAppbar();
  return (
    <UserProfileContainer container spacing={2}>
      <UserAvatar />
      <UserDetailsContainer>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography variant="caption">{role}</Typography>
      </UserDetailsContainer>
      <AccountMenu />
    </UserProfileContainer>
  );
};

export default UserMenu;
