import { useDispatch } from 'react-redux';
import { tourInfo } from '../services/apiDefinition';
import axiosApiInstance from '../services/axios';
import { AppDispatch } from '../store';
import { RootState } from '../store/rootReducer';
import { greetingComplete, welcomeComplete } from '../store/slices/tourSlice';
import { useShallowEqualSelector } from '../utils/helper';

const useTour = () => {
  const { greeting } = useShallowEqualSelector(({ tour }: RootState) => tour);
  const dispatch: AppDispatch = useDispatch();

  const setGreeting = () => {
    dispatch(greetingComplete());
    axiosApiInstance.put(tourInfo, { tour: { greeting: true } });
  };

  const skipTour = () => {
    dispatch(greetingComplete());
    dispatch(welcomeComplete(true));
    axiosApiInstance.put(tourInfo, {
      tour: { greeting: true, welcomeTour: true },
    });
  };

  return { greeting, setGreeting, skipTour };
};

export default useTour;
