import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../store';
import { doLogin } from '../../store/thunks/authThunk';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import { LoginFormValues } from '../../utils/types/formTypes';

const useLogin = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    loginAPIExecutionStatus,
    loginAPISuccessMessage,
    loginAPIFailureMessage,
  } = useShallowEqualSelector(({ auth }: RootState) => auth);

  const submitLogin = (data: LoginFormValues) => {
    dispatch(doLogin(data));
    sessionStorage.setItem('autoLogoutMessage', '');
  };

  return [
    loginAPIExecutionStatus,
    loginAPISuccessMessage,
    loginAPIFailureMessage,
    submitLogin,
  ];
};

export default useLogin;
