import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import { projectType } from '../../utils/types/projectTypes';
import { AppDispatch } from '../../store';
import switchCurrentProjectOperation from '../../store/operations/switchCurrentProjectOperation';
import { updateProject, deleteProject } from '../../store/thunks/projectThunk';
import { Mixpanel } from '../../utils/mixPanel';
import MixPanelConstants from '../../utils/mixpanelConstants';
import { handleModal } from '../../store/slices/welcomeSlice';

const useProjects = () => {
  const dispatch: AppDispatch = useDispatch();
  const { recentProjects, openModal } = useShallowEqualSelector(
    ({ projects }: RootState) => projects,
  );
  const { projectAPILoadingStatus } = useShallowEqualSelector(
    ({ crudData }: RootState) => crudData,
  );
  const navigate = useNavigate();

  const updateProjectDetails = (project: projectType, nameChanged: boolean) => {
    dispatch(updateProject({ updatedProject: project, nameChanged }));
  };

  const deleteProjectFrom = (project: projectType, onClose: () => void, dataModal: (message: any) => void) => {
    dispatch(deleteProject({ project, onClose, dataModal, dispatch }));
    Mixpanel.track(MixPanelConstants.deleteProjectSubmit);
  };

  const switchCurrProject = (project: string) => {
    dispatch(switchCurrentProjectOperation(project));
    navigate('/app/orders');
  };

  const openProjectModal = () => {
    Mixpanel.track(MixPanelConstants.createProject);
    dispatch(handleModal(true));
  };
  const closeProjectModal = () => {
    dispatch(handleModal(false));
  };

  return {
    recentProjects,
    updateProjectDetails,
    deleteProjectFrom,
    switchCurrProject,
    projectAPILoadingStatus,
    openProjectModal,
    openModal,
    closeProjectModal,
  };
};

export default useProjects;
