/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect } from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride';
import { useSetState } from 'react-use';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import a11yChecker from 'a11y-checker';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { welcomeComplete } from '../store/slices/tourSlice';
import { useShallowEqualSelector } from '../utils/helper';
import TourTooltip from './tourTooltip';
import axiosApiInstance from '../services/axios';
import { tourInfo } from '../services/apiDefinition';

type State = {
  run: boolean;
  stepIndex: number;
  steps: Step[];
};

const isSystemBVisible = process.env.REACT_APP_SHOW_SYSTEM_BUILDER === 'true';
const isBusinessIVisible =
  process.env.REACT_APP_SHOW_BUSINESS_INSIGHTS === 'true';
const isDeviceVisible = process.env.REACT_APP_SHOW_DEVICES === 'true';
const isMapLayoutVisible = process.env.REACT_APP_SHOW_MAP_LAYOUT === 'true';
const isWarehouseIVisible =
  process.env.REACT_APP_SHOW_WAREHOUSE_INVENTORY === 'true';
const isOrdersVisible = process.env.REACT_APP_SHOW_WAREHOUSE_ORDERS === 'true';

const WelcomeTour = () => {
  const dispatch: AppDispatch = useDispatch();
  const { welcomeTour, greeting } = useShallowEqualSelector(
    ({ tour }: RootState) => tour,
  );
  const { t } = useTranslation();

  const initSteps: Step[] = [
    {
      content: (
        <Typography variant="body2">
          {t('tour.welcome.createProject.content')}
        </Typography>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'auto',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#addProjectCardBtn',
      title: t('tour.welcome.createProject.title'),
    },
    {
      content: (
        <Typography variant="body2">
          {t('tour.welcome.inventory.content')}
        </Typography>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#inventoryBtn',
      title: t('tour.welcome.inventory.title'),
    },
    {
      content: (
        <Typography variant="body2">
          {t('tour.welcome.orders.content')}
        </Typography>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#ordersBtn',
      title: t('tour.welcome.orders.title'),
    },
    {
      content: (
        <Typography variant="body2">
          {t('tour.welcome.dashboard.content')}
        </Typography>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#dashboardBtn',
      title: t('tour.welcome.dashboard.title'),
    },
    {
      content: (
        <Typography variant="body2">
          {t('tour.welcome.mapLayout.content')}
        </Typography>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'right',
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#mapLayoutBtn',
      title: t('tour.welcome.mapLayout.title'),
    },
  ];
  const [{ run, stepIndex, steps }, setState] = useSetState<State>({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  useEffect(() => {
    let arr = [...initSteps];
    if (!isSystemBVisible) {
      const temp = arr.filter(step => step.target !== '#systemBtn');
      arr = temp;
    }
    if (!isDeviceVisible) {
      const temp = arr.filter(step => step.target !== '#devicesBtn');
      arr = temp;
    }
    if (!isBusinessIVisible) {
      const temp = arr.filter(step => step.target !== '#businessBtn');
      arr = temp;
    }
    if (!isMapLayoutVisible) {
      const temp = arr.filter(step => step.target !== '#mapLayoutBtn');
      arr = temp;
    }
    if (!isWarehouseIVisible) {
      const temp = arr.filter(step => step.target !== '#inventoryBtn');
      arr = temp;
    }
    if (!isOrdersVisible) {
      const temp = arr.filter(step => step.target !== '#ordersBtn');
      arr = temp;
    }
    setState({
      run: !welcomeTour && greeting,
      steps: arr,
    });

    a11yChecker();
  }, [welcomeTour, greeting]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setState({ run: false, stepIndex: 0 });
      dispatch(welcomeComplete(true));
      axiosApiInstance.put(tourInfo, { tour: { welcomeTour: true } });
    } else if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setState({
        run: true,
        stepIndex: nextStepIndex,
      });
    }
  };

  return (
    <Box>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        tooltipComponent={TourTooltip}
        styles={{
          options: {
            arrowColor: '#000000',
          },
        }}
      />
    </Box>
  );
};

export default WelcomeTour;
