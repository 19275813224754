export const darkColorPalette = {
  primary: {
    main: '#027EDE',
    dark: '#0063AF',
    light: '#2DA3FF',
  },
  text: {
    primary: 'rgba(255, 255, 255, 255)',
    primaryLight: 'rgba(255,255,255,.54)',
    secondary: 'rgba(255, 255, 255, 70)',
    disabled: 'rgba(255, 255, 255, 0.3)',
    light: 'rgba(255, 255, 255, 0.7)',
    icon: 'rgba(255, 255, 255, 0.3)',
    note: 'rgba(0, 0, 0, 0.60)',
    deviceLight: 'rgba(255, 255, 255, 0.7)',
    passwordIcn: '#9e9e9e',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(255, 255, 255, 0.26)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  background: {
    paper: '#1C1D22',
    default: '#0F1013',
    dark: '#000000',
    primaryLight: 'rgba(2, 126, 222, 0.3)',
    node: '#272930',
    note: '#F9E18D',
    available: '#B3D8F5',
    noItems: '#FDD586',
    restricted: '#BDBDBD',
  },
  error: {
    main: '#D32F2F',
  },
  warning: {
    main: '#ED6C02',
  },
  success: {
    main: '#2E7D32',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  cover:
    'linear-gradient(89.68deg, #3BA6F9 10.34%, #36ADF3 18.23%, #00F6C0 94.46%)',
};

export const lightColorPalette = {
  primary: {
    main: '#027EDE',
    dark: '#0063AF',
    light: '#2DA3FF',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    primaryLight: 'rgba(255,255,255,.54)',
    secondary: 'rgba(0, 0, 0, 0.60)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    light: 'rgba(255, 255, 255, 0.7)',
    icon: 'rgba(255, 255, 255, 0.3)',
    note: 'rgba(0, 0, 0, 0.60)',
    passwordIcn: '#9e9e9e',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    focus: 'rgba(0, 0, 0, 0.12)',
  },
  background: {
    paper: '#F7F7F7',
    default: '#F7F7F7',
    primaryLight: 'rgba(2, 126, 222, 0.3)',
    node: '#272930',
    note: '#F9E18D',
    available: '#B3D8F5',
    noItems: '#FDD586',
    restricted: '#BDBDBD',
  },
  error: {
    main: '#D32F2F',
  },
  warning: {
    main: '#ED6C02',
  },
  success: {
    main: '#2E7D32',
  },
  divider: 'rgba(0,0,0,0.12)',
};
