import React from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Count, CustomIconButton } from '../styles';
import NotificationsMenu from './notifictionsMenu';

const Notifications = () => {
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications,
  );
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t('appbar.notifications')}>
        <CustomIconButton
          id="long-button"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Count
            badgeContent={
              Object.values(notifications).filter(
                (option: any) => option.read === false,
              ).length
            }
            overlap="circular"
          >
            <NotificationsIcon />
          </Count>
        </CustomIconButton>
      </Tooltip>
      <NotificationsMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default Notifications;
