import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { API_FAILURE_STATUS, API_SUCCESS_STATUS } from '../../utils/constants';
import SignupForm from './components/signupForm';
import SignupImage from './components/signupImage';
import SignupSuccess from './components/signupSuccess';
import { SignupContainerGrid, SignupContainerInnerGrid } from './styles';
import useSignup from './useSignup';
import { resetResendVerificationEmail } from '../../store/slices/authSlice';
import { CircularLoading } from '../../components';

const SignupPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState<string | null>(null);
  const {
    signUpAPIExecutionStatus,
    signUpAPIFailureMessage,
    resetData,
    resendVerificationEmailAPIExecutionStatus,
    resendVerificationEmailAPIFailureMessage,
    resendVerificationEmailAPILoadingStatus,
    signUpAPILoadingStatus,
  } = useSignup();
  const resendToastId = useRef('');
  const signupToastId = useRef('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getEmail = (value: string | null) => {
    setEmail(value);
  };

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      navigate('/app/welcome', { replace: true });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (signUpAPILoadingStatus) {
      signupToastId.current = toast.loading(t('signup.creating_account'));
    } else if (signUpAPIExecutionStatus === API_SUCCESS_STATUS) {
      resetData();
      setIsSuccess(true);
      toast.dismiss(signupToastId.current);
    } else if (signUpAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(signUpAPIFailureMessage, { id: signupToastId.current });
    }
  }, [signUpAPIExecutionStatus, signUpAPILoadingStatus]);
  useEffect(() => {
    if (resendVerificationEmailAPILoadingStatus) {
      resendToastId.current = toast.loading(t('signup.sending_email'));
    } else if (resendVerificationEmailAPIExecutionStatus === API_SUCCESS_STATUS) {
      toast.success(t('signup.email_sent'), { id: resendToastId.current });
      dispatch(resetResendVerificationEmail());
    } else if (resendVerificationEmailAPIExecutionStatus === API_FAILURE_STATUS) {
      toast.error(resendVerificationEmailAPIFailureMessage, { id: resendToastId.current });
    }
  }, [resendVerificationEmailAPIExecutionStatus, resendVerificationEmailAPILoadingStatus]);

  if (isLoading) {
    return (
      <>
        <CircularLoading />
      </>
    );
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
      <SignupContainerGrid container>
        <SignupContainerInnerGrid container item md={6}>
          {isSuccess ? <SignupSuccess email={email} /> : <SignupForm getEmail={getEmail} />}
        </SignupContainerInnerGrid>
        <Hidden mdDown>
          <SignupContainerInnerGrid container item md={6}>
            <SignupImage />
          </SignupContainerInnerGrid>
        </Hidden>
      </SignupContainerGrid>
    </GoogleReCaptchaProvider>
  );
};

export default SignupPage;
