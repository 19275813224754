import React from 'react';
import { Divider } from '@mui/material';
import { DividerLine } from './styles';

const CustomDivider = () => (
  <DividerLine>
    <Divider />
  </DividerLine>
);

export default CustomDivider;
