import { Grid } from '@mui/material';
import React from 'react';
import ProjectsBox from '../../projects/components/projectsBox';
import ProjectSkeleton from './ProjectSkeleton';
import { projectType } from '../../../utils/types/projectTypes';
import AddProjectButton from '../../projects/components/addProjectButton';

type props = {
  isLoading: boolean;
  projectData: any;
};

const ProjectsList = ({ isLoading, projectData }: props) => (
  <Grid container spacing={3}>
    <AddProjectButton />
    {isLoading ? (
      <>
        <ProjectSkeleton />
        <ProjectSkeleton />
      </>
    ) : (
      projectData &&
      projectData
        .slice()
        .sort(
          (a: any, b: any) =>
            (new Date(b.modified_on) as unknown as number) -
            (new Date(a.modified_on) as unknown as number),
        )
        .slice(0, 2)
        .map((project: projectType) => (
          <ProjectsBox project={project} key={project.hashed_identifier} />
        ))
    )}
  </Grid>
);

export default ProjectsList;
