import { createSlice } from '@reduxjs/toolkit';
import { API_FAILURE_STATUS, API_PENDING_STATUS, API_SUCCESS_STATUS, FEET, SELECT, TWOD } from '../../utils/constants';
import { maplayoutReducer } from '../../utils/types/reducerStateTypes';
import { createFloor, deleteFloor, deleteMap, editFloor, getFloors, getMapData, saveMapDetails, submitFloorPlan, uploadMap } from '../thunks/maplayoutThunk';

const initClickedArea = {
  areaType: '-',
  label: '-',
  data: null,
  items: [],
};

const initialState: maplayoutReducer = {
  mapAPIFaliureStatus: false,
  mapAPILoadingStatus: false,
  pointer: SELECT,
  editWallMode: false,
  clickedArea: initClickedArea,
  editModalOpen: false,
  selectedAreaData: {},
  layerMode: TWOD,
  floors: [],
  currentMapData: {},
  currentFloor: '',
  currentMapUnassignedRacks: [],
  currentMapLocations: [],
  currentMapUnassignedLocations: [],
  isLocationsSyncwarePattern: false,
  floorAPIExecutionStatus: API_PENDING_STATUS,
  floorAPILoadingStatus: false,
  floorAPIFaliureMessage: '',
  saveAPILoadStatus: false,
  saveAPIFailureStatus: false,
  submitAPILoadingStatus: false,
  submitAPIExecutionStatus: API_PENDING_STATUS,
  submitAPIFailureMessage: '',
  scaleValue: {
    scale: null,
    unit: FEET,
  },
  floorInpOpen: { open: false, value: '', uuid: null, mapUpload: null },
  submitPubnubMessage: {},
};

const maplayoutSlice = createSlice({
  name: 'maplayout',
  initialState,
  reducers: {
    setPointer: (state, action) => {
      state.pointer = action.payload;
    },
    setClickedArea: (state, action) => {
      state.clickedArea = action.payload;
    },
    resetClickedArea: state => {
      state.clickedArea = initClickedArea;
    },
    setEditModalOpen: (state, action) => {
      state.editModalOpen = action.payload;
    },
    setSelectedAreaData: (state, action) => {
      state.selectedAreaData = action.payload;
    },
    setLayerMode: (state, action) => {
      state.layerMode = action.payload;
    },
    setEditWallMode: (state, action) => {
      state.editWallMode = action.payload;
    },
    resetFloorAPIStatus: state => {
      state.floorAPIExecutionStatus = API_PENDING_STATUS;
      state.floorAPIFaliureMessage = '';
      state.floorAPILoadingStatus = false;
    },
    resetMapAPIStatus: state => {
      state.mapAPIFaliureStatus = false;
      state.mapAPILoadingStatus = false;
    },
    setCurrentFloor: (state, action) => {
      state.currentFloor = action.payload;
    },
    setCanvasLoadingStatus: (state, action) => {
      state.mapAPILoadingStatus = action.payload;
    },
    setmapMetadata: (state, action) => {
      state.currentMapData = {
        ...state.currentMapData,
        metaData: action.payload,
      };
    },
    setScaleValue: (state, action) => {
      state.scaleValue = action.payload;
    },
    setFloorInpOpen: (state, action) => {
      state.floorInpOpen = action.payload;
    },
    resetFloorMapData: state => {
      state.currentMapData = {};
      state.currentFloor = '';
    },
    setCurrentMapUnassignedRacks: (state, action) => {
      state.currentMapUnassignedRacks = action.payload;
    },
    setCurrentMapLocations: (state, action) => {
      state.currentMapLocations = action.payload;
    },
    setCurrentMapUnassignedLocations: (state, action) => {
      state.currentMapUnassignedLocations = action.payload;
    },
    setIsLocationsSyncwarePattern: (state, action) => {
      state.isLocationsSyncwarePattern = action.payload;
    },
    resetSubmitAPIStatus: state => {
      state.submitAPILoadingStatus = false;
      state.submitAPIExecutionStatus = API_PENDING_STATUS;
      state.submitAPIFailureMessage = '';
    },
    handleRacksOnprojectUpdate: (state, action) => {
      const isSyncwarePattern = (locations: any) => {
        const pattern = /^RA\d{2}-BY\d{2}-LV\d{2}-BN\d{3}$/;
        // console location that are not matching the pattern
        locations.forEach((location: any) => {
          if (!pattern.test(location)) {
            console.log('location', location);
          }
        });
        return locations.every((location: any) => pattern.test(location));
      };

      const currentProjectData = action.payload;
      if (currentProjectData && currentProjectData.details) {
        console.log('currentProjectData', currentProjectData);
        const { details } = currentProjectData;
        const { putaway_location } = details;
        if (putaway_location) {
          const { unassigned, list } = putaway_location;
          if (unassigned && unassigned.length > 0) {
            state.currentMapUnassignedLocations = unassigned;
          } else {
            state.currentMapUnassignedLocations = [];
            state.isLocationsSyncwarePattern = false;
          }
          if (list && list.length > 0) {
            state.currentMapLocations = list;
          } else {
            state.currentMapLocations = [];
          }
          const areLocationsSyncwarePattern = isSyncwarePattern(list);
          state.isLocationsSyncwarePattern = areLocationsSyncwarePattern;
          if (areLocationsSyncwarePattern) {
            const racks = [
              ...new Set<string>(
                list.map((location: any) => location.split('-')[0] as string),
              ),
            ];
            state.currentMapUnassignedRacks = racks;
          } else {
            state.currentMapUnassignedRacks = [];
          }
        } else {
          state.currentMapUnassignedLocations = [];
          state.currentMapLocations = [];
          state.currentMapUnassignedRacks = [];
          state.isLocationsSyncwarePattern = false;
        }
      }
    },
    updateSubmitPubnubMessage: (state, action) => {
      state.submitPubnubMessage = action.payload;
    },
  },
  extraReducers: {
    [getMapData.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
      state.currentMapData = {};
    },
    [getMapData.fulfilled.type]: (state, action) => {
      state.mapAPILoadingStatus = false;
      state.currentMapData = action.payload;
      state.pointer = SELECT;
    },
    [getMapData.rejected.type]: state => {
      state.currentMapData = {};
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
      state.pointer = SELECT;
    },

    [uploadMap.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
    },
    [uploadMap.fulfilled.type]: (state, action) => {
      state.mapAPILoadingStatus = false;
      state.currentMapData = action.payload;
    },
    [uploadMap.rejected.type]: state => {
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },

    [deleteMap.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
    },
    [deleteMap.fulfilled.type]: state => {
      state.mapAPILoadingStatus = false;
      state.currentMapData = {};
    },
    [deleteMap.rejected.type]: state => {
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },

    [saveMapDetails.pending.type]: state => {
      state.saveAPILoadStatus = true;
      state.saveAPIFailureStatus = false;
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
    },
    [saveMapDetails.fulfilled.type]: (state, action) => {
      state.saveAPILoadStatus = false;
      state.mapAPILoadingStatus = false;
      state.currentMapData = action.payload;
    },
    [saveMapDetails.rejected.type]: state => {
      state.saveAPILoadStatus = false;
      state.saveAPIFailureStatus = true;
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },

    [submitFloorPlan.pending.type]: state => {
      state.submitAPIExecutionStatus = API_PENDING_STATUS;
      state.submitAPILoadingStatus = true;
    },
    [submitFloorPlan.fulfilled.type]: (state, action) => {
      state.currentMapData = action.payload;
      state.submitAPIExecutionStatus = API_SUCCESS_STATUS;
      state.submitAPILoadingStatus = false;
    },
    [submitFloorPlan.rejected.type]: (state, action) => {
      state.submitAPIExecutionStatus = API_FAILURE_STATUS;
      state.submitAPILoadingStatus = false;
      state.submitAPIFailureMessage = action.error.message;
    },

    [getFloors.pending.type]: state => {
      state.mapAPILoadingStatus = true;
      state.mapAPIFaliureStatus = false;
    },
    [getFloors.fulfilled.type]: (state, action) => {
      state.mapAPILoadingStatus = false;
      if (action.payload.length === 0) {
        state.currentMapData = {};
        state.currentFloor = '';
      } else {
        state.currentFloor = action.payload[0].uuid;
      }
      state.floors = action.payload;
    },
    [getFloors.rejected.type]: state => {
      state.mapAPILoadingStatus = false;
      state.mapAPIFaliureStatus = true;
    },

    [createFloor.pending.type]: state => {
      state.floorAPILoadingStatus = true;
      state.floorAPIExecutionStatus = API_PENDING_STATUS;
    },
    [createFloor.fulfilled.type]: state => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [createFloor.rejected.type]: (state, action) => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_FAILURE_STATUS;
      state.floorAPIFaliureMessage = action.error.message;
    },

    [editFloor.pending.type]: state => {
      state.floorAPILoadingStatus = true;
      state.floorAPIExecutionStatus = API_PENDING_STATUS;
    },
    [editFloor.fulfilled.type]: state => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [editFloor.rejected.type]: (state, action) => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_FAILURE_STATUS;
      state.floorAPIFaliureMessage = action.error.message;
    },

    [deleteFloor.pending.type]: state => {
      state.floorAPILoadingStatus = true;
      state.floorAPIExecutionStatus = API_PENDING_STATUS;
    },
    [deleteFloor.fulfilled.type]: state => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_SUCCESS_STATUS;
    },
    [deleteFloor.rejected.type]: (state, action) => {
      state.floorAPILoadingStatus = false;
      state.floorAPIExecutionStatus = API_FAILURE_STATUS;
      state.floorAPIFaliureMessage = action.error.message;
    },
  },
});

export const {
  setPointer,
  setClickedArea,
  setEditModalOpen,
  setSelectedAreaData,
  setLayerMode,
  setEditWallMode,
  resetFloorAPIStatus,
  setCurrentFloor,
  setCanvasLoadingStatus,
  setmapMetadata,
  resetMapAPIStatus,
  setScaleValue,
  setFloorInpOpen,
  resetFloorMapData,
  setCurrentMapUnassignedRacks,
  setCurrentMapLocations,
  setCurrentMapUnassignedLocations,
  setIsLocationsSyncwarePattern,
  handleRacksOnprojectUpdate,
  resetSubmitAPIStatus,
  updateSubmitPubnubMessage,
  resetClickedArea,
} = maplayoutSlice.actions;

export default maplayoutSlice;
