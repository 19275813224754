import React from 'react';
import Box from '@mui/material/Box';

import { useNavigate } from 'react-router-dom';
import {
  ForgotInnerBlock,
  ForgotLeftItemGrid,
  EmailSubTitle,
  SendButton,
  CoverTitle,
  SignUpText,
  SignUpButton,
} from '../styles';

const SendEmail = ({
  t,
  emailValue,
  resetUserPassword,
}: {
  t: any;
  emailValue: string;
  resetUserPassword: (email: string) => Promise<void>;
}) => {
  const navigate = useNavigate();
  return (
    <ForgotLeftItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <ForgotInnerBlock>
        <Box component="form" sx={{ maxWidth: 400 }}>
          <CoverTitle variant="h4">{t('forgot.email_title')}</CoverTitle>
          <EmailSubTitle variant="body1">
            {t('forgot.email_subtitle')}
          </EmailSubTitle>
          <SendButton
            title={t('forgot.back_to_login_button')}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 3 }}
            onClick={() => navigate('/app/login')}
          />
          <SignUpText variant="subtitle1">
            {t('forgot.email_no_receive')}
            <SignUpButton
              title={t('forgot.email_resend')}
              variant="text"
              color="primary"
              sx={{ ml: 1 }}
              onClick={() => resetUserPassword(emailValue)}
            />
          </SignUpText>
        </Box>
      </ForgotInnerBlock>
    </ForgotLeftItemGrid>
  );
};

export default SendEmail;
