import React from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';

const Button = ({
  title,
  variant = 'contained',
  color = 'primary',
  disabled = false,
  size = 'medium',
  startIcon = null,
  endIcon = null,
  ...props
}: ButtonProps) => (
  <MUIButton
    variant={variant}
    color={color}
    disabled={disabled}
    size={size}
    startIcon={startIcon}
    endIcon={endIcon}
    {...props}
  >
    {title}
  </MUIButton>
);
export default Button;
