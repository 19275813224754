import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  GetStartedHeading,
  GetStartedSubHeading,
  UserGuideItemBlock,
  LearnMoreButton,
} from '../styles';

const UserGuideItem = (
  { name, desc, btnText, link }: { name: string, desc: string, btnText: string, link: string },
) => (
  <UserGuideItemBlock>
    <GetStartedHeading variant="subtitle1">{name}</GetStartedHeading>
    <GetStartedSubHeading variant="subtitle2">{desc}</GetStartedSubHeading>
    <LearnMoreButton
      title={btnText}
      variant="text"
      endIcon={<ArrowForwardIcon />}
      onClick={() => window.open(link, '_blank')}
    />
  </UserGuideItemBlock>
);

export default UserGuideItem;
