import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

export const projectsAdapter = createEntityAdapter();

const projectsSlice = createSlice({
  name: 'projectsData',
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    setProjects: projectsAdapter.setAll,
  },
});

export const projectsSelectors = projectsAdapter.getSelectors(
  (state: RootState) => state.projectsData,
);

export const getProjectsList = (state: RootState) => projectsSelectors.selectAll(state);

export const getProjectAreas = (state: RootState, currentProject: string) => {
  const data: any = projectsSelectors
    .selectAll(state)
    .find((project: any) => project.id === currentProject);
  return data.areas;
};

export const { setProjects } = projectsSlice.actions;

export default projectsSlice;
