import React from 'react';
import { DisabledPill, ActivePill } from './style';

type PillProps = {
  text: string;
  disabled?: boolean;
}

const Pill = ({ text, disabled }: PillProps) => (
  disabled
    ? (
      <DisabledPill>
        {text}
      </DisabledPill>
    )
    : (
      <ActivePill>
        {text}
      </ActivePill>
    )
);

Pill.defaultProps = {
  disabled: false,
};

export default Pill;
