import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useShallowEqualSelector } from '../../utils/helper';
import { RootState } from '../../store/rootReducer';
import { AppDispatch } from '../../store';
import switchCurrentProjectOperation from '../../store/operations/switchCurrentProjectOperation';
import { getProjectsList } from '../../store/slices/projectsSlice';
import { resetReportData } from '../../store/slices/settingsSlice';
import { reportABug } from '../../store/thunks/settingsThunk';
import { doLogout } from '../../store/thunks/authThunk';
import { API_FAILURE_STATUS, API_SUCCESS_STATUS } from '../../utils/constants';

const useAppbar = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [reportTitle, setReportTitle] = useState('');
  const [reportDesc, setReportDesc] = useState('');
  const [reportTitleError, setReportTitleError] = useState(false);
  const [reportDescError, setReportDescError] = useState(false);
  const [reportImage, setReportImage] = useState<File | null>(null);
  const [reportImageError, setReportImageError] = useState(false);
  const [reportSuccessModal, setReportSuccessModal] = useState(false);

  const { currentProject, projectAPILoadingStatus } = useShallowEqualSelector(({ selectedData }: RootState) => selectedData);
  const projectsList = useSelector((state: RootState) => getProjectsList(state));
  const { recentProjects, userStatus } =
    useShallowEqualSelector(({ projects }: RootState) => projects);
  const { userData, reportAPILoadingStatus, reportAPIExecutionStatus } = useShallowEqualSelector(
    ({ settings }: RootState) => settings,
  );
  const { logoutData } = useShallowEqualSelector(
    ({ auth }: RootState) => auth,
  );

  const resetReportModal = () => {
    setReportTitleError(false);
    setReportTitle('');
    setReportDescError(false);
    setReportDesc('');
    setReportImageError(false);
    setReportImage(null);
  };

  useEffect(() => {
    if (reportAPIExecutionStatus === API_SUCCESS_STATUS) {
      resetReportModal();
      setReportSuccessModal(true);
    }
    if (reportAPILoadingStatus === API_FAILURE_STATUS) {
      // setErrorMsg(projectAPIFailureMessage);
    }
  }, [reportAPILoadingStatus]);

  useEffect(() => {
    if (logoutData) {
      navigate('/app/login');
    }
  }, [logoutData]);

  const { welcomeTour, areasTour, reactFlowTour, devicesTour } = useShallowEqualSelector(
    ({ tour }: RootState) => tour,
  );

  const handleClickItem = (to: string) => {
    navigate(to);
  };

  const logoutUser = () => {
    dispatch(doLogout(localStorage.getItem('gf_key')!));
  };

  const switchCurrProject = (project: string) => {
    dispatch(switchCurrentProjectOperation(project));
  };

  const handleReportTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportTitleError(false);
    setReportTitle(event.target.value);
  };

  const handleReportDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportDescError(false);
    setReportDesc(event.target.value);
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if (file) {
      if (file[0].type.split('/')[0] !== 'image') {
        setReportImageError(true);
      } else {
        setReportImageError(false);
        setReportImage(file[0]);
      }
    }
  };

  const submitReport = (onCloseModal: () => void) => {
    if (reportTitle.trim() === '') {
      setReportTitleError(true);
    } else if (reportDesc.trim() === '') {
      setReportDescError(true);
    } else {
      const data = new FormData();
      data.append('title', reportTitle);
      data.append('description', reportDesc);
      if (reportImage) {
        data.append('image', reportImage, reportImage.name);
      }
      dispatch(reportABug({ request: data, resetReportModal, onCloseModal }));
    }
  };

  const resetReportBugData = () => {
    setReportSuccessModal(false);
    dispatch(resetReportData());
  };

  return {
    handleClickItem,
    recentProjects,
    logoutUser,
    currentProject,
    projectAPILoadingStatus,
    switchCurrProject,
    role: userStatus.role,
    name: `${userData.first_name} ${userData.last_name}`,
    projectsList,
    userData,
    welcomeTour,
    areasTour,
    reactFlowTour,
    devicesTour,
    dispatch,
    reportTitle,
    reportDesc,
    handleReportTitleChange,
    handleReportDescChange,
    submitReport,
    reportTitleError,
    reportDescError,
    resetReportModal,
    reportSuccessModal,
    resetReportBugData,
    handleImageChange,
    reportImageError,
    reportImage,
    setReportImage,
    reportAPILoadingStatus,
  };
};

export default useAppbar;
