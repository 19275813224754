import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ActivePill = styled(Typography)<TypographyProps>(({ theme }) => ({
  width: 'fit-content',
  padding: '6px 14px',
  borderRadius: '100px',
  backgroundColor: theme.palette.primary.light,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '1',
  },
}));

const DisabledPill = styled(Typography)<TypographyProps>(({ theme }) => ({
  width: 'fit-content',
  padding: '6px 14px',
  borderRadius: '100px',
  backgroundColor: theme.palette.action.disabled,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '1',
  },
}));

export {
  ActivePill,
  DisabledPill,
};
