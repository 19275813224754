import { Buffer } from 'buffer';

const getGrafanaCredentials = (key: string) => {
  const decodedString = Buffer.from(key, 'base64').toString('utf-8');
  const grafanaCredentials = decodedString.split(':');
  return {
    user: grafanaCredentials[0],
    password: grafanaCredentials[1],
  };
};

export default getGrafanaCredentials;
