import React from 'react';
import { Chip } from '@mui/material';
import { MenuItem, MenuIcon, MenuText } from '../styles';
import useSidebar from '../useSidebar';
import styles from '../sideBar.module.css';
import { NULL_STRING } from '../../../utils/constants';
// import AccordianMenuItem from './accordianMenuItem';

type propTypes = {
  text: String;
  icon: any;
  open: boolean;
  index: Number;
  to: string;
  menuRef: string;
  toggleSidebar: () => void;
};

const Item = ({ text, icon, open, index, to, menuRef, toggleSidebar }: propTypes) => {
  const { menuIndex, handleClickMenu, recentProjects, currentProject } = useSidebar();
  return (
    <>
      {open ? (
        <MenuItem
          onClick={() =>
            (menuRef === 'helpBtn'
              ? window.open('https://syncware.readme.io/docs', '_blank')
              : handleClickMenu(index, to))}
          className={`${menuIndex === index ? styles.selected : ''}`}
          id={menuRef}
          disabled={(recentProjects.length === 0 || currentProject === '' || currentProject === NULL_STRING) && index !== 0 && index !== 6}
        >
          {icon}
          <MenuText variant="subtitle2" sx={{ paddingLeft: '24px' }} onClick={toggleSidebar}>
            {text}
            {(menuRef === 'mapLayoutBtn' ||
              menuRef === 'optimisationBtn') && (
                <Chip
                  label="Beta"
                  size="small"
                  color="primary"
                  sx={{ marginLeft: '8px' }}
                />
            )}
          </MenuText>
        </MenuItem>
      ) : (
        <MenuIcon
          onClick={() =>
            (menuRef === 'helpBtn'
              ? window.open('https://syncware.readme.io/docs', '_blank')
              : handleClickMenu(index, to))}
          className={`${menuIndex === index ? styles.selected : ''}`}
          id={menuRef}
          disabled={recentProjects.length === 0 && index !== 0 && index !== 6}
        >
          {icon}
        </MenuIcon>
      )}
    </>
  );
};

export default Item;
