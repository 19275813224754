import { batch } from 'react-redux';
import { ThunkDispatch, RootState } from '../index';
import { switchArea } from '../slices/selectedDataSlice';
import { projectsSelectors } from '../slices/projectsSlice';
import { updateCurrentProject } from '../thunks/selectedDataThunk';
import { getProjectDetails } from '../thunks/projectThunk';

const switchCurrentProjectOperation = (projectId: string | null) => (
  dispatch: ThunkDispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const project: any = projectsSelectors.selectById(state, projectId || '');
  batch(() => {
    dispatch(updateCurrentProject({ id: projectId, dispatch, updateProjectData: true }));
    if (projectId) {
      dispatch(switchArea(project?.areas[0]));
      dispatch(getProjectDetails({ hashed_identifier: projectId, dispatch }));
    }
  });
};

export default switchCurrentProjectOperation;
