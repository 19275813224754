import React, { createContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fabric } from 'fabric';
import $ from 'jquery';
import useMaplayout from '../useMaplayout';
import { AREA_CREATOR, WALLPOINT, EDIT_WALL, FABRICOPTIONS, WALLLINE, NEW_WALL, POLYGON, SELECT, WALLCOLOR, RULERCOLOR, RACK, FORBIDDEN, DISPATCH, STARTING, RACKCOLOR, RACKOUTLINE, DISPATCHCOLOR, STARTINGCOLOR, DISPATCHOUTLINE, STARTINGOUTLINE, FORBIDDENCOLOR, FORBIDDENOUTLINE, RULERLINECOLOR } from '../../../utils/constants';
import { AppDispatch } from '../../../store';
import { resetClickedArea, setCanvasLoadingStatus, setClickedArea, setEditWallMode, setmapMetadata, setPointer, setScaleValue } from '../../../store/slices/maplayoutSlice';
import { saveMapDetails } from '../../../store/thunks/maplayoutThunk';

type slotAreaType = {
  name: string;
  type: string;
  columns: number;
  rows: number;
  shelvesQuantity: number;
  binLength: number;
  binWidth: number;
  binHeight: number;
  location: string;
};
type canvasOptionsType = {
  width: number;
  height: number;
};
type imageDimensionsType = {
  width: number | null;
  height: number | null;
};
type FabricContextType = {
  canvas: fabric.Canvas | null;
  initCanvas: (
    el: HTMLCanvasElement | null,
    options: canvasOptionsType,
    mapImg: string,
    jsonData: any,
    topRulerEl: HTMLCanvasElement | null,
    leftRulerEl: HTMLCanvasElement | null,
  ) => void;
  activeObject: fabric.Object | null;
  setActiveObject: React.Dispatch<React.SetStateAction<fabric.Object | null>>;
  canvasResize: () => void;
  constructBays: (obj: slotAreaType) => void;
  addItemsToSlot: () => void;
  zoom: number;
  setZoom: React.Dispatch<React.SetStateAction<number>>;
  undoAction: () => void;
  redoAction: () => void;
  markAreaName: (name: string, type: string) => void;
  saveCanvasAsJson: (unit?: string) => void;
  changesNumber: number;
  setChangesNumber: React.Dispatch<React.SetStateAction<number>>;
  canvasInJson: any;
  editWall: () => void;
  removeWall: () => void;
  setScaleModalOpen: React.Dispatch<React.SetStateAction<fabric.Circle[] | null>>;
  scaleModalOpen: fabric.Circle[] | null;
  resetWallVariables: () => void;
  redrawRulers: (zoomValue: number, scale: number | null) => void;
  editRackObject: fabric.Object[] | null;
  setEditRackObject: React.Dispatch<React.SetStateAction<fabric.Object[] | null>>;
  reconstructBays: (oldName: string, oldType: string, obj: slotAreaType) => void;
  editOtherArea: fabric.Object | null;
  setEditOtherArea: React.Dispatch<React.SetStateAction<fabric.Object | null>>;
  reconstructOtherArea: (oldname: string, oldType: string, name: string, type: string) => void;
  handleUnitChangeForBins: (unit: string) => void;
};

const initialContext: FabricContextType = {} as FabricContextType;

export const FabricContext = createContext<FabricContextType>(initialContext);

export const FabricContextProvider = ({ children }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [topRulerCanvas, setTopRulerCanvas] = useState<fabric.Canvas | null>(null);
  const [leftRulerCanvas, setLeftRulerCanvas] = useState<fabric.Canvas | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);
  const [actionsList, setActionsList] = useState<fabric.Object[]>([]);
  const [changesNumber, setChangesNumber] = useState<number>(0);
  const [canvasInJson, setCanvasInJson] = useState<any>(null);
  const [editRackObject, setEditRackObject] = useState<fabric.Object[] | null>(null);
  const [editOtherArea, setEditOtherArea] = useState<fabric.Object | null>(null);
  const {
    handleEditModalOpen,
    editModalOpen,
    handleEditModalClose,
    editWallMode,
    pointer,
    currentFloor,
    scaleValue,
    currentMapData,
    isLocationsSyncwarePattern,
    currentMapLocations,
    currentProjectData,
    updateUnassignedLocationsOnObjectsChange,
  } = useMaplayout();

  let origX: number;
  let origY: number;
  let tempRect: fabric.Rect;
  let tempPoints: any[] = [];
  let isDown = false;
  let isDragging: boolean = false;
  let lastPosX: any = null;
  let lastPosY: any = null;
  let activeWallLine: any;
  let activeWallShape: any;
  let wallLineArray: any = [];
  const imgDimensions: imageDimensionsType = { width: null, height: null };
  const [wallIncomplete, setWallIncomplete] = useState<boolean>(false);
  const [scaleModalOpen, setScaleModalOpen] = useState<fabric.Circle[] | null>(null);

  useEffect(() => {
    setChangesNumber(0);
    setActionsList([]);
    setActiveObject(null);
    setZoom(1);
    handleEditModalClose();
    setScaleModalOpen(null);
    setEditRackObject(null);
    setEditOtherArea(null);
  }, []);

  useEffect(() => {
    if (!canvas) {
      dispatch(setCanvasLoadingStatus(true));
      dispatch(setPointer(SELECT));
    } else {
      dispatch(setCanvasLoadingStatus(false));
    }
  }, [canvas]);

  const drawRulerLines = async (trc: fabric.Canvas, lrc: fabric.Canvas, zoomValue: number) => {
    for (
      let i = 0;
      i < 1200;
      i += zoomValue * 10
    ) {
      const topPos = (i / zoomValue) % 50 === 0 ? [i, 20, i, 40] : [i, 30, i, 40];
      const topLine = new fabric.Line(topPos, {
        stroke: RULERLINECOLOR,
        strokeWidth: 1,
        selectable: false,
        moveCursor: 'default',
        hoverCursor: 'default',
      });
      trc.add(topLine);
      const leftPos = (i / zoomValue) % 50 === 0 ? [20, i, 40, i] : [30, i, 40, i];
      const leftLine = new fabric.Line(leftPos, {
        stroke: RULERLINECOLOR,
        strokeWidth: 1,
        selectable: false,
        moveCursor: 'default',
        hoverCursor: 'default',
      });
      lrc.add(leftLine);
    }
  };

  const drawRulerLabels = async (trc: fabric.Canvas, lrc: fabric.Canvas, zoomValue: number, scale: number) => {
    for (
      let i = 0;
      i < 1200;
      i += zoomValue * 100
    ) {
      const actualDistance = Math.round((i * scale) / zoomValue);
      const topText = new fabric.Text(actualDistance.toString(), {
        left: i,
        top: 6,
        fontSize: 10,
        selectable: false,
        moveCursor: 'default',
        hoverCursor: 'default',
      });
      const leftText = new fabric.Text(actualDistance.toString(), {
        left: 6,
        top: i,
        fontSize: 10,
        selectable: false,
        moveCursor: 'default',
        hoverCursor: 'default',
      });
      trc.add(topText);
      lrc.add(leftText);
    }
  };

  const redrawRulers = async (zoomValue: number, scale: number | null) => {
    if (topRulerCanvas && leftRulerCanvas) {
      topRulerCanvas.clear();
      leftRulerCanvas.clear();
      topRulerCanvas.setBackgroundColor(RULERCOLOR, topRulerCanvas.renderAll.bind(topRulerCanvas));
      leftRulerCanvas.setBackgroundColor(RULERCOLOR, leftRulerCanvas.renderAll.bind(leftRulerCanvas));
      if (scale) {
        drawRulerLines(topRulerCanvas, leftRulerCanvas, zoomValue);
        drawRulerLabels(topRulerCanvas, leftRulerCanvas, zoomValue, scale);
      }
    }
  };

  useEffect(() => {
    if (topRulerCanvas && leftRulerCanvas) {
      redrawRulers(zoom, scaleValue.scale);
    }
  }, [topRulerCanvas, leftRulerCanvas, scaleValue, zoom]);

  const getValueInMM = (value: number, unit: string) => {
    switch (unit) {
      case 'ft':
        return value * 304.8;
      case 'm':
        return value * 1000;
      default:
        return value;
    }
  };

  const constructWallFromJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    // scale points using ratio
    const newPoints = json.walls.vertices.map((point: any) => ({ x: point.x * ratio, y: point.y * ratio }));
    // construct polygon
    const polygon = new fabric.Polygon(newPoints, {
      stroke: WALLCOLOR,
      strokeWidth: 5,
      fill: 'transparent',
      transparentCorners: false,
      selectable: false,
      hoverCursor: 'default',
    });
    polygon['id' as keyof typeof polygon] = new Date().getTime();
    c.add(polygon);
    c.getObjects().forEach(object => {
      if (object.type === POLYGON) {
        c.sendToBack(object);
      }
    });
  };

  const constructForbiddenAreasJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    json.forbidden_zones.forEach((forbiddenArea: any) => {
      const rect = new fabric.Rect({
        left: forbiddenArea.virtual_properties.top_left_x * ratio,
        top: forbiddenArea.virtual_properties.top_left_y * ratio,
        width: forbiddenArea.virtual_properties.horizontal_length * ratio,
        height: forbiddenArea.virtual_properties.vertical_length * ratio,
        fill: FORBIDDENCOLOR,
        stroke: FORBIDDENOUTLINE,
        strokeWidth: 2,
        opacity: 0.8,
        angle: forbiddenArea.virtual_properties.angle,
      });
      rect['id' as keyof typeof rect] = new Date().getTime();
      rect['group_name' as keyof typeof rect] = forbiddenArea.name;
      rect['area_type' as keyof typeof rect] = FORBIDDEN;
      c.add(rect);
    });
  };

  const constructPickerPointsFromJson = async (c: fabric.Canvas, json: any, ratio: number, key: string) => {
    json[key].forEach((dispatchPoint: any) => {
      const rect = new fabric.Rect({
        left: dispatchPoint.virtual_properties.top_left_x * ratio,
        top: dispatchPoint.virtual_properties.top_left_y * ratio,
        width: dispatchPoint.virtual_properties.horizontal_length * ratio,
        height: dispatchPoint.virtual_properties.vertical_length * ratio,
        fill: key === 'picker_starting_point' ? STARTINGCOLOR : DISPATCHCOLOR,
        stroke: key === 'picker_starting_point' ? STARTINGOUTLINE : DISPATCHOUTLINE,
        strokeWidth: 2,
        opacity: 0.8,
      });
      rect['id' as keyof typeof rect] = new Date().getTime();
      rect['group_name' as keyof typeof rect] = dispatchPoint.name;
      rect['area_type' as keyof typeof rect] = key === 'picker_starting_point' ? STARTING : DISPATCH;
      c.add(rect);
    });
  };

  const constructSlotsFromJson = async (c: fabric.Canvas, json: any, ratio: number) => {
    json.racks.forEach((rack: any) => {
      rack.bays.forEach((bay: any) => {
        const rect = new fabric.Rect({
          left: bay.virtual_properties.top_left_x * ratio,
          top: bay.virtual_properties.top_left_y * ratio,
          width: bay.virtual_properties.horizontal_length * ratio,
          height: bay.virtual_properties.vertical_length * ratio,
          fill: RACKCOLOR,
          stroke: RACKOUTLINE,
          strokeWidth: 2,
          angle: bay.virtual_properties.angle_deg,
        });
        // lock rect movement & scaling
        rect.lockMovementX = true;
        rect.lockMovementY = true;
        rect.lockScalingX = true;
        rect.lockScalingY = true;
        rect.lockRotation = true;
        rect.hasControls = false;
        rect.hasBorders = true;
        rect.padding = 2;
        rect.borderColor = 'red';
        rect['group_name' as keyof typeof rect] = rack.rack_label;
        rect['bay_name' as keyof typeof rect] = bay.bay_label;
        rect['details' as keyof typeof rect] = rack.details;
        rect['area_type' as keyof typeof rect] = 'Rack';
        rect['row_num' as keyof typeof rect] = bay.row_column[0];
        rect['col_num' as keyof typeof rect] = bay.row_column[1];
        rect['group_properties' as keyof typeof rect] = {
          width: rack.virtual_properties.horizontal_length * ratio,
          height: rack.virtual_properties.vertical_length * ratio,
          left: rack.virtual_properties.top_left_x * ratio,
          top: rack.virtual_properties.top_left_y * ratio,
          angle: rack.virtual_properties.angle,
        };
        rect['levels' as keyof typeof rect] = bay.levels;
        c.add(rect);
      });
    });
  };

  const parseToFabricJSON = async (c: fabric.Canvas, json: any, ratio: number) => {
    dispatch(setCanvasLoadingStatus(true));
    if (json.walls && json.walls.vertices && json.walls.vertices.length > 0) {
      constructWallFromJson(c, json, ratio);
    }
    if (json.racks && json.racks.length > 0) {
      constructSlotsFromJson(c, json, ratio);
    }
    if (json.scale && Object.keys(json.scale).length > 0) {
      dispatch(setScaleValue({ scale: json.scale.frontend_value * ratio, unit: json.scale.frontend_unit_of_measurement }));
      redrawRulers(c.getZoom(), json.scale.frontend_value * ratio);
    } else {
      dispatch(setScaleValue({ scale: null, unit: 'ft' }));
      redrawRulers(c.getZoom(), null);
    }
    if (json.forbidden_zones && json.forbidden_zones.length > 0) {
      constructForbiddenAreasJson(c, json, ratio);
    }
    if (json.picker_dispatch_point && json.picker_dispatch_point.length > 0) {
      constructPickerPointsFromJson(c, json, ratio, 'picker_dispatch_point');
    }
    if (json.picker_starting_point && json.picker_starting_point.length > 0) {
      constructPickerPointsFromJson(c, json, ratio, 'picker_starting_point');
    }
    dispatch(setCanvasLoadingStatus(false));
    setCanvasInJson(json);
    setChangesNumber(0);
  };

  const initCanvas = async (
    el: HTMLCanvasElement | null,
    options: canvasOptionsType,
    mapImg: string,
    jsonData: any,
    topRulerEl: HTMLCanvasElement | null,
    leftRulerEl: HTMLCanvasElement | null,
  ) => {
    if (!el || mapImg === '') {
      return;
    }
    const canvasOptions = {
      preserveObjectStacking: true,
      selection: true,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      ...options,
    };
    const maxWidth = $('.maplayout-map-container').width()! - 40;
    const maxHeight = $('.maplayout-map-container').height()! - 40;
    // calculate the width and height, constraining the proportions
    const c = new fabric.Canvas(el, canvasOptions);
    const tl = new fabric.Canvas(topRulerEl!, { selection: false, width: maxWidth, height: 40 });
    const lr = new fabric.Canvas(leftRulerEl!, { selection: false, width: 40, height: maxHeight });
    fabric.Image.fromURL(mapImg, img => {
      imgDimensions.width = img.width!;
      imgDimensions.height = img.height!;
      const ratio = Math.min(maxWidth / img.width!, maxHeight / img.height!);
      img.scaleX = ratio;
      img.scaleY = ratio;
      // resize the canvas to the new image size
      c.setWidth(img.width! * ratio);
      c.setHeight(img.height! * ratio);
      c.setBackgroundImage(img, c.renderAll.bind(c));
      if (jsonData) {
        parseToFabricJSON(c, jsonData, ratio);
      } else {
        dispatch(setScaleValue({ scale: null, unit: 'ft' }));
        setCanvasInJson(null);
        setChangesNumber(0);
      }
    });
    setTopRulerCanvas(tl);
    setLeftRulerCanvas(lr);
    setCanvas(c);
  };
  const canvasResize = () => {
    // if (canvas) {
    //   const maxWidth = $('.maplayout-map-container').width()!;
    //   const maxHeight = $('.maplayout-map-container').height()!;
    //   const ratio = Math.min(maxWidth / imgDimensions.width!, maxHeight / imgDimensions.height!);
    //   const width = imgDimensions.width! * ratio;
    //   const height = imgDimensions.height! * ratio;
    //   canvas.setDimensions({ width, height });
    //   // scale background image
    //   const bgImg = canvas.backgroundImage as fabric.Image;
    //   if (bgImg) {
    //     bgImg.scaleX = ratio;
    //     bgImg.scaleY = ratio;
    //   }
    //   const json = canvas.toJSON(FABRICOPTIONS);
    //   console.log(json);
    //   canvas.loadFromJSON(json, () => {
    //     canvas.renderAll.bind(canvas);
    //   });
    // }
  };

  const generateBayName = (rack: string, unitNumber: number) => {
    const unit = unitNumber < 10 ? `0${unitNumber}` : unitNumber;
    const unitName = `${rack}-BY${unit}`;
    return unitName;
  };

  const extractRackUnitRowBinNumbers = (binLabel: string) => {
    const split = binLabel.split('-');
    console.log(split);
    const rack = parseInt(split[0].slice(2), 10) - 1;
    const unitNumber = parseInt(split[1].slice(2), 10) - 1;
    const rowNumber = parseInt(split[2].slice(2), 10) - 1;
    const binNumber = parseInt(split[3].slice(2), 10) - 1;
    return [rack, unitNumber, rowNumber, binNumber];
  };

  const addBinsToLevel = async (slot: slotAreaType, levelLocation: string) => {
    const foundBins = currentMapLocations.filter((location: string) => location.startsWith(levelLocation));
    const uniqueBins = [...new Set(foundBins)];
    if (uniqueBins.length > 0) {
      const binsObj = uniqueBins.map((bin: string) => ({
        bin_label: bin,
        row_column_level_bin: extractRackUnitRowBinNumbers(bin),
        real_properties: {
          unit_of_measurement: 'mm/px',
          length: getValueInMM(slot.binLength!, scaleValue.unit),
          width: getValueInMM(slot.binWidth!, scaleValue.unit),
          height: getValueInMM(slot.binHeight!, scaleValue.unit),
        },
        storage_available: true,
        bin_contents: [],
        bin_occupancy: 1.0,
        ABC: '',
        XYZ: '',
      }));
      return binsObj;
    }
    // if no bins found, return a default bin
    return [
      {
        bin_label: `${levelLocation}-BN001`,
        row_column_level_bin: extractRackUnitRowBinNumbers(`${levelLocation}-BN001`),
        real_properties: {
          unit_of_measurement: 'mm/px',
          length: getValueInMM(slot.binLength!, scaleValue.unit),
          width: getValueInMM(slot.binWidth!, scaleValue.unit),
          height: getValueInMM(slot.binHeight!, scaleValue.unit),
        },
        storage_available: true,
        bin_contents: [],
        bin_occupancy: 1.0,
        ABC: '',
        XYZ: '',
      },
    ];
  };

  const compareStringAndNumbers = (a: string, b: number) => Number(a.split('-')[1].split('UN')[1]) === b;
  const compareLevelStringAndNumbers = (a: string, b: number) => Number(a.split('-')[2].split('RW')[1]) === b;

  const getLevelLabel = (bayName: string, levelIndex: number, levelsBelongingToBay: any[]) => {
    // return level name if levelIndex matches with a level name in levelsBelongingToBay
    // else return level name with levelIndex
    const levelName = levelsBelongingToBay.find((level: string) => compareLevelStringAndNumbers(level, levelIndex));
    console.log(levelName);
    if (levelName) {
      return levelName;
    }
    return `${bayName}-LV${levelIndex > 9 ? levelIndex : `0${levelIndex}`}`;
  };

  const addLevelsToBay = async (obj: slotAreaType, levelsLocations: any[]) => {
    const canvasObjects = canvas!.getObjects();
    console.log('levelsLocations', levelsLocations);
    const bays = canvasObjects.filter((o: any) => o.group_name === obj.name);
    console.log('bays', bays);
    bays.forEach((bay: any) => {
      const levelsBelongingToBay = levelsLocations.filter((level: string) => level.startsWith(bay.bay_name));
      console.log('levelsBelongingToBay', levelsBelongingToBay);
      for (let i = 0; i < obj.shelvesQuantity; i += 1) {
        const levelName = getLevelLabel(bay.bay_name, i + 1, levelsBelongingToBay);
        addBinsToLevel(obj, levelName).then((bins: any) => {
          bay.set('levels', [
            ...bay.levels || [],
            {
              level_label: levelName,
              level_index: i,
              bins: bins,
            },
          ]);
        }).catch((error) => {
          console.error(error);
          // Set an empty array for the bins property of the current level
          bay.set('levels', [
            ...bay.levels || [],
            {
              level_label: levelName,
              level_index: i,
              bins: [],
            },
          ]);
        });
      }
    });
    console.log(bays);
  };

  const constructBays = async (obj: slotAreaType) => {
    if (canvas) {
      let binLocations: any[] = [];
      let levelsLocations: any[] = [];
      let baysLocations: any[] = [];
      if (isLocationsSyncwarePattern) {
        binLocations = currentMapLocations.filter(
          (location: string) => location.startsWith(obj.name),
        );
        // extract levels from bin locations
        const levelsLocationsWithDuplicates = binLocations.map((location: string) => {
          const levelLocation = location.split('-');
          return `${levelLocation[0]}-${levelLocation[1]}-${levelLocation[2]}`;
        });
        // extract bays from bin locations
        const baysLocationsWithDuplicates = binLocations.map((location: string) => {
          const bayLocation = location.split('-');
          return `${bayLocation[0]}-${bayLocation[1]}`;
        });
        // remove duplicates
        baysLocations = [...new Set(baysLocationsWithDuplicates)];
        levelsLocations = [...new Set(levelsLocationsWithDuplicates)];
      }
      // break active object rectangle into smaller rectangles and discard the active object
      const activeObj = canvas.getActiveObject() as any;
      const activeObjWidth =
        activeObj.scaleX !== 1
          ? activeObj.width! * activeObj.scaleX!
          : activeObj.width!;
      const activeObjHeight =
        activeObj.scaleY !== 1
          ? activeObj.height! * activeObj.scaleY!
          : activeObj.height!;
      const activeObjLeft = activeObj.left!;
      const activeObjTop = activeObj.top!;
      const slotWidth = activeObjWidth / obj.columns;
      const slotHeight = activeObjHeight / obj.rows;
      let bay_num = 1;
      for (let i = 0; i < obj.rows; i++) {
        for (let j = 0; j < obj.columns; j++) {
          const slot = new fabric.Rect({
            width: slotWidth,
            height: slotHeight,
            left: activeObjLeft + slotWidth * j,
            top: activeObjTop + slotHeight * i,
            fill: RACKCOLOR,
            stroke: RACKOUTLINE,
            strokeWidth: 2,
          });
          // lock rect movement & scaling
          slot.lockMovementX = true;
          slot.lockMovementY = true;
          slot.lockScalingX = true;
          slot.lockScalingY = true;
          slot.lockRotation = true;
          slot.hasControls = false;
          slot.hasBorders = true;
          slot.padding = 2;
          slot.borderColor = 'red';
          slot['group_name' as keyof typeof slot] = obj.name;
          slot['bay_name' as keyof typeof slot] =
            baysLocations.length > 0
              ? bay_num > baysLocations.length
                ? generateBayName(obj.name, bay_num)
                : compareStringAndNumbers(
                  baysLocations[bay_num - 1],
                  bay_num,
                )
                  ? baysLocations[bay_num - 1]
                  : generateBayName(obj.name, bay_num)
              : `s${bay_num}`;
          slot['details' as keyof typeof slot] = obj;
          slot['area_type' as keyof typeof slot] = obj.type;
          slot['items' as keyof typeof slot] = ['item1', 'item2'];
          slot['row_column' as keyof typeof slot] = `(${i},${j})`;
          slot['row_num' as keyof typeof slot] = i;
          slot['col_num' as keyof typeof slot] = j;
          slot['group_properties' as keyof typeof slot] = {
            width: activeObjWidth,
            height: activeObjHeight,
            left: activeObjLeft,
            top: activeObjTop,
            angle: activeObj.angle,
          };
          canvas.add(slot);
          bay_num++;
        }
      }
      console.log(isLocationsSyncwarePattern);
      if (isLocationsSyncwarePattern) {
        addLevelsToBay(obj, levelsLocations);
      }
      canvas.remove(activeObj);
    }
  };

  const reconstructBays = async (oldName: string, oldType: string, obj: slotAreaType) => {
    if (canvas) {
      // const rackDetails = document.getElementById('area-details-container')!;
      // rackDetails.style.display = 'none';
      dispatch(resetClickedArea());
      let binLocations: any[] = [];
      let levelsLocations: any[] = [];
      let baysLocations: any[] = [];
      if (isLocationsSyncwarePattern) {
        console.log(obj.name);
        binLocations = currentMapLocations.filter(
          (location: string) => location.startsWith(obj.name),
        );
        // extract levels from bin locations
        const levelsLocationsWithDuplicates = binLocations.map((location: string) => {
          const levelLocation = location.split('-');
          return `${levelLocation[0]}-${levelLocation[1]}-${levelLocation[2]}`;
        });
        // extract units from bin locations
        const baysLocationsWithDuplicates = binLocations.map((location: string) => {
          const bayLocation = location.split('-');
          return `${bayLocation[0]}-${bayLocation[1]}`;
        });
        // remove duplicates
        baysLocations = [...new Set(baysLocationsWithDuplicates)];
        levelsLocations = [...new Set(levelsLocationsWithDuplicates)];
      }
      // remove old bays with group_name obj.name from canvas
      const objects = canvas.getObjects();
      const bays = objects.filter((o: any) => o.group_name === oldName && o.area_type === oldType) as any;
      let width = 0;
      let height = 0;
      let left = 0;
      let top = 0;
      let angle = 0;
      if (bays.length > 1) {
        width = bays[0].group_properties.width;
        height = bays[0].group_properties.height;
        left = bays[0].group_properties.left;
        top = bays[0].group_properties.top;
        angle = bays[0].group_properties.angle;
      } else {
        width = bays[0].width;
        height = bays[0].height;
        left = bays[0].left;
        top = bays[0].top;
        angle = bays[0].angle;
      }
      bays.forEach((s: any) => {
        canvas.remove(s);
      });

      const slotWidth = width / obj.columns;
      const slotHeight = height / obj.rows;
      let bay_num = 1;
      console.log('baysLocations', baysLocations);
      for (let i = 0; i < obj.rows; i++) {
        for (let j = 0; j < obj.columns; j++) {
          const slot = new fabric.Rect({
            width: slotWidth,
            height: slotHeight,
            left: left + slotWidth * j,
            top: top + slotHeight * i,
            fill: RACKCOLOR,
            stroke: RACKOUTLINE,
            strokeWidth: 2,
          });
          slot['group_name' as keyof typeof slot] = obj.name;
          slot['bay_name' as keyof typeof slot] =
            baysLocations.length > 0
              ? bay_num >= baysLocations.length
                ? generateBayName(obj.name, bay_num)
                : compareStringAndNumbers(
                  baysLocations[bay_num - 1],
                  bay_num,
                )
                  ? baysLocations[bay_num - 1]
                  : generateBayName(obj.name, bay_num)
              : `s${bay_num}`;
          slot['details' as keyof typeof slot] = obj;
          slot['area_type' as keyof typeof slot] = obj.type;
          slot['items' as keyof typeof slot] = ['item1', 'item2'];
          slot['row_column' as keyof typeof slot] = `(${i},${j})`;
          slot['row_num' as keyof typeof slot] = i;
          slot['col_num' as keyof typeof slot] = j;
          slot['group_properties' as keyof typeof slot] = {
            width: width,
            height: height,
            left: left,
            top: top,
            angle: angle,
          };
          canvas.add(slot);
          bay_num++;
        }
      }
      console.log(isLocationsSyncwarePattern);
      if (isLocationsSyncwarePattern) {
        addLevelsToBay(obj, levelsLocations);
      }
      setEditRackObject(null);
      setEditOtherArea(null);
      // discard active object
      canvas.discardActiveObject();
    }
  };

  const getColor = (type: string) => {
    switch (type) {
      case DISPATCH:
        return DISPATCHCOLOR;
      case FORBIDDEN:
        return FORBIDDENCOLOR;
      case STARTING:
        return STARTINGCOLOR;
      default:
        return RACKCOLOR;
    }
  };
  const getOutline = (type: string) => {
    switch (type) {
      case DISPATCH:
        return DISPATCHOUTLINE;
      case FORBIDDEN:
        return FORBIDDENOUTLINE;
      case STARTING:
        return STARTINGOUTLINE;
      default:
        return RACKOUTLINE;
    }
  };

  const markAreaName = (name: string, type: string) => {
    if (canvas) {
      const activeObj = canvas.getActiveObject() as any;
      if (activeObj) {
        activeObj.setOptions({
          area_type: type,
          group_name: name,
          fill: getColor(type),
          stroke: getOutline(type),
          strokeWidth: 2,
        });
        // discard the active object
        canvas.discardActiveObject();
      }
    }
  };

  const reconstructOtherArea = async (oldname: string, oldType: string, name: string, type: string) => {
    if (canvas) {
      // const rackDetails = document.getElementById('area-details-container')!;
      // rackDetails.style.display = 'none';
      dispatch(resetClickedArea());
      // remove old slots with group_name obj.name from canvas
      const objects = canvas.getObjects();
      const area = objects.filter(
        (o: any) => o.group_name === oldname && o.area_type === oldType,
      ) as any;
      let width = 0;
      let height = 0;
      let left = 0;
      let top = 0;
      let angle = 0;
      if (area.length > 1) {
        width = area[0].group_properties.width;
        height = area[0].group_properties.height;
        left = area[0].group_properties.left;
        top = area[0].group_properties.top;
        angle = area[0].group_properties.angle;
      } else {
        width = area[0].width;
        height = area[0].height;
        left = area[0].left;
        top = area[0].top;
        angle = area[0].angle;
      }
      const newArea = new fabric.Rect({
        width,
        height,
        left,
        top,
        angle,
        fill: getColor(type),
        stroke: getOutline(type),
        strokeWidth: 2,
      });
      newArea['group_name' as keyof typeof newArea] = name;
      newArea['area_type' as keyof typeof newArea] = type;
      area.forEach((s: any) => {
        canvas.remove(s);
      });
      setEditRackObject(null);
      setEditOtherArea(null);
      canvas.add(newArea);
      // discard active object
      canvas.discardActiveObject();
    }
  };

  const getAreaData = async (x: number, y: number) => {
    console.log(x, y);
    if (canvas?.getActiveObject()) {
      const activeObj = canvas.getActiveObject();
      // const rackDetails = document.getElementById('area-details-container')!;
      // rackDetails.style.display = 'none';
      dispatch(resetClickedArea());
      if (activeObj['details' as keyof typeof activeObj]) {
        const rackObj = {
          areaType: activeObj['area_type' as keyof typeof activeObj],
          label: activeObj['bay_name' as keyof typeof activeObj],
          data: activeObj['details' as keyof typeof activeObj],
          items: activeObj['items' as keyof typeof activeObj],
        };
        dispatch(setClickedArea(rackObj));
        // get absolute position of the canvas
        // const canvasPos = canvas.getElement().getBoundingClientRect();
        // if (x + canvasPos.left + 217 > window.screen.width) {
        //   rackDetails.style.left = `${x + canvasPos.left - 217}px`;
        // } else {
        //   rackDetails.style.left = `${x + canvasPos.left}px`;
        // }
        // rackDetails.style.top = `${y + canvasPos.top}px`;
        // rackDetails.style.display = 'flex';
      } else if (activeObj['area_type' as keyof typeof activeObj] && activeObj['area_type' as keyof typeof activeObj] !== RACK) {
        const rackObj = {
          areaType: activeObj['area_type' as keyof typeof activeObj],
          label: activeObj['group_name' as keyof typeof activeObj],
        };
        dispatch(setClickedArea(rackObj));
        // get absolute position of the canvas
        // const canvasPos = canvas.getElement().getBoundingClientRect();
        // if (x + canvasPos.left + 217 > window.screen.width) {
        //   rackDetails.style.left = `${x + canvasPos.left - 217}px`;
        // } else {
        //   rackDetails.style.left = `${x + canvasPos.left}px`;
        // }
        // rackDetails.style.top = `${y + canvasPos.top}px`;
        // rackDetails.style.display = 'flex';
      }
    }
  };

  const addItemsToSlot = async () => {
    if (canvas) {
      const activeObj = canvas.getActiveObject() as any;
      const items = activeObj['items' as keyof typeof activeObj];
      activeObj.setOptions({ items: [...items, 'new item'] });
      const rackObj = {
        areaType: activeObj['area_type' as keyof typeof activeObj],
        slotName: activeObj['bay_name' as keyof typeof activeObj],
        data: activeObj['details' as keyof typeof activeObj],
        items: activeObj['items' as keyof typeof activeObj],
      };
      dispatch(setClickedArea(rackObj));
    }
  };

  const undoAction = () => {
    if (canvas) {
      // eslint-disable-next-line
      if (canvas._objects.length > 0 && changesNumber > 0) {
        // eslint-disable-next-line
        setActionsList([...actionsList, canvas._objects.pop()!]);
        setChangesNumber(changesNumber - 1);
        canvas.renderAll();
      }
    }
  };

  const redoAction = () => {
    if (actionsList.length > 0 && canvas) {
      const tempActions = [...actionsList];
      const lastAction = tempActions.pop();
      setActionsList(tempActions);
      canvas.add(lastAction!);
    }
  };

  const scaleCoordinatesToOne = (point: {x: number, y: number}, scaleX: number, scaleY: number) => {
    const fabricJson = canvas!.toJSON(FABRICOPTIONS) as any;
    const widthFactor = fabricJson.backgroundImage.width / canvas!.getWidth();
    const heightFactor = fabricJson.backgroundImage.height / canvas!.getHeight();
    const scaledX = Math.round(point.x * scaleX * widthFactor);
    const ScaledY = Math.round(point.y * scaleY * heightFactor);
    return { x: scaledX, y: ScaledY };
  };

  const scalePointToOne = (point: number, scale: number, type: 'width' | 'height') => {
    const fabricJson = canvas!.toJSON(FABRICOPTIONS) as any;
    const factor = type === 'width' ? fabricJson.backgroundImage.width / canvas!.getWidth() : fabricJson.backgroundImage.height / canvas!.getHeight();
    return Math.round(point * scale * factor);
  };

  const covertScaleValueForOrignalImage = (scaleVal: number) => {
    const fabricJson = canvas!.toJSON(FABRICOPTIONS) as any;
    const widthFactor = fabricJson.backgroundImage.width / canvas!.getWidth();
    const heightFactor = fabricJson.backgroundImage.height / canvas!.getHeight();
    const ratio = Math.min(widthFactor, heightFactor);
    return scaleVal * ratio;
  };

  const saveCanvasAsJson = (unit?: string) => {
    if (canvas) {
      const fabricJson = canvas.toJSON(FABRICOPTIONS) as any;
      const finalJson: any = {};
      const image_dimensions = {
        width: fabricJson.backgroundImage.width,
        height: fabricJson.backgroundImage.height,
        unit_of_measurement: 'px',
      };
      const map_origin = {
        point_coordinate_x: 0,
        point_coordinate_y: 0,
        unit_of_measurement: 'px',
        angle_deg: 0,
      };
      const indexOfPolygon = fabricJson.objects.findIndex(
        (obj: any) => obj.type === POLYGON,
      );
      const wallPolygonObj = fabricJson.objects[indexOfPolygon];
      const wallsPoints = wallPolygonObj?.points.map((point: any) => scaleCoordinatesToOne(point, wallPolygonObj.scaleX, wallPolygonObj.scaleY));
      const wallObj = {
        label: 'wall',
        vertices: wallsPoints === undefined ? null : wallsPoints,
      };
      const forbiddenCanvasObj = fabricJson.objects.filter(
        (obj: any) => obj.area_type === FORBIDDEN,
      );
      const forbiddenAreas = forbiddenCanvasObj.map((obj: any) => ({
        name: obj.group_name,
        virtual_properties: {
          top_left_x: scalePointToOne(obj.left, obj.scaleX, 'width'),
          top_left_y: scalePointToOne(obj.top, obj.scaleY, 'height'),
          horizontal_length: scalePointToOne(obj.width, obj.scaleX, 'width'),
          vertical_length: scalePointToOne(obj.height, obj.scaleY, 'height'),
          angle: obj.angle,
        },
      }));
      const dispatchCanvasObj = fabricJson.objects.filter(
        (obj: any) => obj.area_type === DISPATCH,
      );
      const dispatchAreas = dispatchCanvasObj.map((obj: any) => {
        const width = scalePointToOne(obj.width, obj.scaleX, 'width');
        const height = scalePointToOne(obj.height, obj.scaleY, 'height');
        const point_x = scalePointToOne(obj.left, obj.scaleX, 'width');
        const point_y = scalePointToOne(obj.top, obj.scaleY, 'height');
        return {
          name: obj.group_name,
          virtual_properties: {
            unit_of_measurement: 'px',
            top_left_x: point_x,
            top_left_y: point_y,
            horizontal_length: width,
            vertical_length: height,
          },
        };
      });
      const startingCanvasObj = fabricJson.objects.filter(
        (obj: any) => obj.area_type === STARTING,
      );
      const startingAreas = startingCanvasObj.map((obj: any) => {
        const width = scalePointToOne(obj.width, obj.scaleX, 'width');
        const height = scalePointToOne(obj.height, obj.scaleY, 'height');
        const point_x = scalePointToOne(obj.left, obj.scaleX, 'width');
        const point_y = scalePointToOne(obj.top, obj.scaleY, 'height');
        return {
          name: obj.group_name,
          virtual_properties: {
            unit_of_measurement: 'px',
            top_left_x: point_x,
            top_left_y: point_y,
            horizontal_length: width,
            vertical_length: height,
          },
        };
      });
      const rackCanvasObj = fabricJson.objects.filter(
        (obj: any) => obj.area_type === 'Rack',
      );
      // get unique group_name from rackCanvasObj
      const uniqueGroupNames = rackCanvasObj.reduce((acc: any, obj: any) => {
        if (!acc.includes(obj.group_name)) {
          acc.push(obj.group_name);
        }
        return acc;
      }, []);
      const rackAreas: any = [];
      uniqueGroupNames.forEach((groupName: string) => {
        const groupRackObj = rackCanvasObj.filter(
          (obj: any) => obj.group_name === groupName,
        );
        const rackX = scalePointToOne(groupRackObj[0].group_properties.left, 1, 'width');
        const rackY = scalePointToOne(groupRackObj[0].group_properties.top, 1, 'height');
        const rackWidth = scalePointToOne(groupRackObj[0].group_properties.width, 1, 'width');
        const rackHeight = scalePointToOne(groupRackObj[0].group_properties.height, 1, 'height');
        const rackX2 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackX + rackWidth : rackX;
        const rackY2 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackY : rackY + rackHeight;
        const rackX3 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackX : rackX + rackWidth;
        const rackY3 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackY + rackHeight : rackY;
        const rackX4 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackX2 : rackX2 + rackWidth;
        const rackY4 = groupRackObj[0].details.rows < groupRackObj[0].details.columns ? rackY2 + rackHeight : rackY2;
        const rackArea = {
          rack_label: groupName,
          virtual_properties: {
            unit_of_measurement: 'px',
            top_left_x: rackX,
            top_left_y: rackY,
            angle_deg: groupRackObj[0].group_properties.angle,
            horizontal_length: rackWidth,
            vertical_length: rackHeight,
          },
          real_properties: {
            unit_of_measurement: 'mm',
            height: getValueInMM(groupRackObj[0].details.rackHeight, scaleValue.unit),
          },
          num_of_rows: groupRackObj[0].details.rows,
          num_of_columns: groupRackObj[0].details.columns,
          num_of_levels: groupRackObj[0].details.shelvesQuantity,
          details: groupRackObj[0].details,
          aisle_side: [
            [
              [rackX, rackY],
              [rackX2, rackY2],
            ],
            [
              [rackX3, rackY3],
              [rackX4, rackY4],
            ],
          ],
          bays: [],
        };
        const baysObj = groupRackObj.map((obj: any) => ({
          bay_label: obj.bay_name,
          row_column: [obj.row_num, obj.col_num],
          virtual_properties: {
            unit_of_measurement: 'px',
            top_left_x: scalePointToOne(obj.left, obj.scaleX, 'width'),
            top_left_y: scalePointToOne(obj.top, obj.scaleY, 'height'),
            horizontal_length: scalePointToOne(obj.width, obj.scaleX, 'width'),
            vertical_length: scalePointToOne(obj.height, obj.scaleY, 'height'),
            angle_deg: obj.angle,
          },
          levels: obj.levels,
        }));
        rackArea.bays = baysObj;
        rackAreas.push(rackArea);
      });
      finalJson.image_dimension = image_dimensions;
      finalJson.map_origin = map_origin;
      finalJson.walls = wallObj;
      finalJson.forbidden_zones = forbiddenAreas;
      finalJson.picker_dispatch_point = dispatchAreas;
      finalJson.picker_starting_point = startingAreas;
      finalJson.racks = rackAreas;
      finalJson.version = '1.0.0';
      if (unit) {
        finalJson.scale = {
          frontend_value: currentMapData.metaData.scale.frontend_value,
          frontend_unit_of_measurement: unit,
          backend_value: getValueInMM(currentMapData.metaData.scale.frontend_value, unit),
          backend_unit_of_measurement: 'mm/px',
        };
      } else if (localStorage.getItem('_scaleObj')) {
        const scl = JSON.parse(localStorage.getItem('_scaleObj')!);
        const orignalScale = covertScaleValueForOrignalImage(scl.scale);
        finalJson.scale = {
          frontend_value: orignalScale,
          frontend_unit_of_measurement: scl.unit,
          backend_value: getValueInMM(orignalScale, scl.unit),
          backend_unit_of_measurement: 'mm/px',
        };
        localStorage.removeItem('_scaleObj');
      } else if (currentMapData.metaData && currentMapData.metaData.scale) {
        finalJson.scale = {
          frontend_value: currentMapData.metaData.scale.frontend_value,
          frontend_unit_of_measurement: currentMapData.metaData.scale.frontend_unit_of_measurement,
          backend_value: getValueInMM(currentMapData.metaData.scale.frontend_value, currentMapData.metaData.scale.frontend_unit_of_measurement),
          backend_unit_of_measurement: 'mm/px',
        };
      }
      setChangesNumber(0);
      setCanvasInJson(finalJson);
      dispatch(setmapMetadata(finalJson));
      dispatch(saveMapDetails({ mapData: finalJson, uuid: currentFloor }));
      console.log(finalJson);
    }
  };

  const removeControlPoints = () => {
    canvas?.getObjects().forEach(object => {
      if (object['group_name' as keyof typeof object] === 'controls') {
        canvas?.remove(object);
      }
    });
    dispatch(setEditWallMode(false));
    saveCanvasAsJson();
  };

  const addPoint = (options: any) => {
    if (canvas) {
      setWallIncomplete(true);
      const pointOption = {
        id: new Date().getTime(),
        radius: 8,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: options.e.layerX / canvas.getZoom(),
        top: options.e.layerY / canvas.getZoom(),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        objectCaching: false,
      };
      const point = new fabric.Circle(pointOption);
      point['class' as keyof typeof point] = WALLPOINT;

      if (tempPoints.length === 0) {
        // fill first point with red color
        point.set({
          fill: 'red',
        });
      }

      const linePoints = [
        options.e.layerX / canvas.getZoom(),
        options.e.layerY / canvas.getZoom(),
        options.e.layerX / canvas.getZoom(),
        options.e.layerY / canvas.getZoom(),
      ];
      const lineOption = {
        strokeWidth: 5,
        fill: '#999999',
        stroke: WALLCOLOR,
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      };
      const line = new fabric.Line(linePoints, lineOption);
      line['class' as keyof typeof line] = WALLLINE;

      if (activeWallShape) {
        const pos = canvas.getPointer(options.e);
        const points = activeWallShape.get('points');
        points.push({
          x: pos.x,
          y: pos.y,
        });
        const polygon = new fabric.Polygon(points, {
          stroke: WALLCOLOR,
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        });
        canvas.remove(activeWallShape);
        canvas.add(polygon);
        activeWallShape = polygon;
        canvas.renderAll();
      } else {
        const polyPoint = [
          {
            x: options.e.layerX / canvas.getZoom(),
            y: options.e.layerY / canvas.getZoom(),
          },
        ];
        const polygon = new fabric.Polygon(polyPoint, {
          stroke: WALLCOLOR,
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        });
        activeWallShape = polygon;
        canvas.add(polygon);
      }

      activeWallLine = line;
      tempPoints.push(point);
      if (tempPoints.length === 2) {
        setScaleModalOpen(tempPoints);
      }
      wallLineArray.push(line);

      canvas.add(line);
      canvas.add(point);
    }
  };

  const generatePolygon = () => {
    if (canvas) {
      const points: any = [];
      // collect points and remove them from canvas
      tempPoints.forEach((point: any) => {
        points.push({
          x: point.left,
          y: point.top,
        });
        canvas.remove(point);
      });

      // remove lines from canvas
      wallLineArray.forEach((line: any) => {
        canvas.remove(line);
      });

      // remove selected Shape and Line
      canvas.remove(activeWallShape).remove(activeWallLine);

      // create polygon from collected points
      const polygon = new fabric.Polygon(points, {
        stroke: WALLCOLOR,
        strokeWidth: 5,
        fill: 'transparent',
        transparentCorners: false,
        selectable: false,
        hoverCursor: 'default',
      });
      polygon['id' as keyof typeof polygon] = new Date().getTime();
      tempPoints = [];
      wallLineArray = [];
      activeWallShape = null;
      activeWallLine = null;
      setWallIncomplete(false);
      dispatch(setPointer(SELECT));
      canvas.add(polygon);
      canvas?.getObjects().forEach(object => {
        if (object.type === POLYGON) {
          canvas?.sendToBack(object);
        }
      });
      saveCanvasAsJson();
    }
  };

  const resetWallVariables = () => {
    tempPoints = [];
    wallLineArray = [];
    activeWallShape = null;
    activeWallLine = null;
    setWallIncomplete(false);
    // remove all lines and points from canvas
    canvas?.getObjects().forEach((object: any) => {
      if (object.class === WALLPOINT) {
        canvas?.remove(object);
      }
      if (object.class === WALLLINE) {
        canvas?.remove(object);
      }
      if (object.type === POLYGON) {
        canvas?.remove(object);
      }
    });
    saveCanvasAsJson();
  };

  const belongsToSameRack = (objects: fabric.Object[]) => {
    const isRack = objects.every(object => object['area_type' as keyof typeof object] === RACK);
    const rackNames = objects.map(object => object['group_name' as keyof typeof object]);
    const rackName = rackNames[0];
    const isSameRack = rackNames.every(name => name === rackName);
    return isRack && isSameRack;
  };

  useEffect(() => {
    if (pointer !== EDIT_WALL && editWallMode) {
      removeControlPoints();
    }
    if (pointer !== NEW_WALL && wallIncomplete) {
      resetWallVariables();
    }
    canvas?.on('mouse:down', o => {
      // disable context menu
      if (o.button === 1) {
        // const rackDetails = document.getElementById('area-details-container')!;
        // rackDetails.style.display = 'none';
        dispatch(resetClickedArea());
        isDown = true;
        const cursorPointer = canvas.getPointer(o.e);
        origX = cursorPointer.x;
        origY = cursorPointer.y;
        if (pointer === AREA_CREATOR) {
          tempRect = new fabric.Rect({
            left: origX,
            top: origY,
            originX: 'left',
            originY: 'top',
            width: cursorPointer.x - origX,
            height: cursorPointer.y - origY,
            angle: 0,
            fill: 'rgba(255,0,0,0.5)',
            transparentCorners: false,
          });
          canvas.add(tempRect);
        } else if (pointer === NEW_WALL) {
          if (o.target && (o.target as any).id === tempPoints[0].id) {
            // when click on the first point
            generatePolygon();
          } else {
            addPoint(o);
          }
        } else {
          getAreaData(cursorPointer.x, cursorPointer.y);
        }
      } else if (o.button === 3) {
        const activeObj = canvas.getActiveObject();
        if (activeObj) {
          if (!activeObj['area_type' as keyof typeof activeObj] && !activeObj['_objects' as keyof typeof activeObj]) {
            document.addEventListener('contextmenu', e => {
              e.preventDefault();
            });
            handleEditModalOpen();
          } else if (
            !activeObj['_objects' as keyof typeof activeObj] &&
            activeObj['area_type' as keyof typeof activeObj] &&
            activeObj['area_type' as keyof typeof activeObj] !== RACK
          ) {
            document.addEventListener('contextmenu', e => {
              e.preventDefault();
            });
            setEditOtherArea(activeObj);
            handleEditModalOpen();
          } else if (activeObj['_objects' as keyof typeof activeObj]) {
            const objects = activeObj['_objects' as keyof typeof activeObj];
            if (belongsToSameRack(objects)) {
              document.addEventListener('contextmenu', e => {
                e.preventDefault();
              });
              console.log('same rack', objects);
              setEditRackObject(objects);
              handleEditModalOpen();
            }
          }
        }
      }
    });

    canvas?.on('mouse:move', o => {
      if ((pointer !== AREA_CREATOR || !isDown) && pointer !== NEW_WALL) return;
      const cursorPointer = canvas.getPointer(o.e);
      if (pointer === AREA_CREATOR) {
        if (origX > cursorPointer.x) {
          tempRect.set({ left: Math.abs(cursorPointer.x) });
        }
        if (origY > cursorPointer.y) {
          tempRect.set({ top: Math.abs(cursorPointer.y) });
        }
        tempRect.set({
          width: Math.abs(origX - cursorPointer.x),
          height: Math.abs(origY - cursorPointer.y),
        });

        canvas.renderAll();
      } else if (pointer === NEW_WALL) {
        if (activeWallLine && activeWallLine.class === WALLLINE) {
          const cursor = canvas.getPointer(o.e);
          activeWallLine.set({
            x2: cursor.x,
            y2: cursor.y,
          });
          const points = activeWallShape.get('points');
          points[tempPoints.length] = {
            x: cursor.x,
            y: cursor.y,
          };
          activeWallShape.set({
            points,
          });
        }
        canvas.renderAll();
      }
    });

    canvas?.on('mouse:up', () => {
      isDown = false;
      if (pointer === AREA_CREATOR) {
        dispatch(setPointer(SELECT));
      }
    });

    return () => {
      canvas?.off('mouse:down');
      canvas?.off('mouse:move');
      canvas?.off('mouse:up');
    };
  }, [pointer, canvas]);

  useEffect(() => {
    canvas?.on('mouse:wheel', opt => {
      if (pointer === NEW_WALL || pointer === EDIT_WALL) return;
      const delta = opt.e.deltaY;
      let zoomValue = canvas.getZoom();
      zoomValue *= 0.999 ** delta;
      if (zoomValue > 20) zoomValue = 20;
      if (zoomValue < 0.01) zoomValue = 0.01;
      if (zoomValue <= 2 && zoomValue >= 0.1) {
        setZoom(zoomValue);
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomValue);
      }
      redrawRulers(canvas.getZoom(), scaleValue.scale);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    canvas?.on('mouse:down', (opt) => {
      if (pointer === NEW_WALL || pointer === EDIT_WALL) return;
      const evt = opt.e;
      if (evt.altKey === true) {
        isDragging = true;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      }
    });
    canvas?.on('mouse:move', (opt) => {
      if (pointer === NEW_WALL || pointer === EDIT_WALL) return;
      if (isDragging) {
        const { e } = opt;
        const vpt: any = canvas.viewportTransform;
        vpt[4] += e.clientX - lastPosX;
        vpt[5] += e.clientY - lastPosY;
        canvas.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
      }
    });
    canvas?.on('mouse:up', () => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      // @ts-ignore
      canvas.setViewportTransform(canvas?.viewportTransform);
      isDragging = false;
    });

    return () => {
      canvas?.off('mouse:wheel');
      canvas?.off('mouse:down');
      canvas?.off('mouse:move');
      canvas?.off('mouse:up');
    };
  }, [canvas, pointer]);

  const deleteActiveObject = () => {
    console.log(canvas?.toJSON(FABRICOPTIONS));
    const activeGroup = canvas?.getActiveObjects();
    console.log(currentProjectData.is_sample_project);
    if (currentProjectData && currentProjectData.is_sample_project) return;
    console.log(currentProjectData.is_sample_project);
    if (!activeGroup) return;
    // check is any object in active group has group_name controls
    const isControlPoint = activeGroup?.some(object => object['group_name' as keyof typeof object] === 'controls');
    if (isControlPoint) return;
    // check if any object in active group has area_type rack
    const isRack = activeGroup?.some(object => object['area_type' as keyof typeof object] === RACK);
    console.log('isRack', isRack);
    if (isRack) {
      // if there are multiple racks in active group then make sure all the bays are selected of that belongs to same group_name else do not delete any objects
      const racks = activeGroup?.filter(object => object['area_type' as keyof typeof object] === RACK);
      // get all the group_names of racks in racks array & remove duplicates
      const groupNames = racks?.map(rack => rack['group_name' as keyof typeof rack]);
      const uniqueRackNames = [...new Set(groupNames)];
      let allRacksSelected: boolean[] = [];
      // iterate over uniqueRackNames and check the length of bays of each rack & compare it with rows * columns
      uniqueRackNames?.forEach(rackName => {
        const rackArr = racks?.filter(rack => rack['group_name' as keyof typeof rack] === rackName);
        console.log('rackArr', rackArr);
        const rows = rackArr?.[0]['details' as keyof typeof rackArr[0]]['rows' as keyof typeof rackArr[0]];
        const columns = rackArr?.[0]['details' as keyof typeof rackArr[0]]['columns' as keyof typeof rackArr[0]];
        console.log('rows', rows);
        console.log('columns', columns);
        if (rackArr?.length !== rows * columns) {
          allRacksSelected = [...allRacksSelected, false];
        } else {
          allRacksSelected = [...allRacksSelected, true];
        }
      });
      console.log('allRacksSelected', allRacksSelected);
      if (allRacksSelected?.includes(false)) return;
      // if all the bays are selected of all the racks in active group then delete all the objects including other area_type
      canvas?.remove(...activeGroup);
      setChangesNumber(changesNumber + activeGroup.length);
      console.log(canvas?.toJSON(FABRICOPTIONS));
      updateUnassignedLocationsOnObjectsChange();
    } else {
      canvas?.remove(...activeGroup);
      setChangesNumber(changesNumber + activeGroup.length);
    }
  };

  useEffect(() => {
    $('body').on('keydown', e => {
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if (!editModalOpen) {
          deleteActiveObject();
        }
      }
      if (e.key === 'Enter' && pointer === EDIT_WALL) {
        removeControlPoints();
        dispatch(setPointer(SELECT));
      }
    });
    // remove the on keydown event when component unmounts
    return () => {
      $('body').off('keydown');
    };
  }, [editModalOpen, tempPoints]);

  const handleUnitChangeForBins = (unit: string) => {
    // get rack objects and convert all the bin values according to latest unit
    if (canvas) {
      const objects = canvas.getObjects();
      if (objects.length > 0) {
        objects.forEach((obj: any) => {
          if (obj.area_type !== RACK) return;
          const { levels, details } = obj;
          if (levels.length === 0 || Object.keys(details).length === 0) return;
          let newLevels = [...levels];
          // iterate over levels and convert bin values
          newLevels = newLevels.map((level: any) => {
            const newLevel = { ...level };
            const { bins } = newLevel;
            let newBins = [...bins];
            if (bins.length === 0) return;
            newBins = bins.map((bin: any) => {
              const newBin = {
                ...bin,
                real_properties: {
                  width: getValueInMM(details.binWidth, unit),
                  height: getValueInMM(details.binHeight, unit),
                  length: getValueInMM(details.binLength, unit),
                  unit_of_measurement: 'mm/px',
                },
              };
              return newBin;
            });
            newLevel.bins = newBins;
            return newLevel;
          });
          obj.set({
            levels: newLevels,
          });
        });
        saveCanvasAsJson(unit);
      }
    }
  };

  window.addEventListener('resize', () => {
    canvasResize();
  });

  const editWall = () => {
    if (canvas) {
      dispatch(setEditWallMode(true));
      const objects = canvas.getObjects(POLYGON);
      if (objects.length > 0) {
        const polygon = objects[0] as fabric.Polygon;
        polygon.points!.forEach((point, index) => {
          const control = new fabric.Circle({
            radius: 8,
            fill: 'red',
            left: point.x,
            top: point.y,
            originX: 'center',
            originY: 'center',
            hasBorders: false,
            hasControls: false,
          });
          control['index' as keyof typeof control] = index;
          control['group_name' as keyof typeof control] = 'controls';
          canvas.add(control);
        });
      }
    }
  };

  const removeWall = () => {
    canvas?.getObjects().forEach(object => {
      if (object.type === POLYGON) {
        canvas?.remove(object);
      }
    });
    // removeControlPoints();
    // dispatch(setPointer(NEW_WALL));
  };
  return (
    <FabricContext.Provider
      // eslint-disable-next-line
      value={{
        canvas,
        initCanvas,
        activeObject,
        setActiveObject,
        canvasResize,
        constructBays,
        addItemsToSlot,
        zoom,
        setZoom,
        undoAction,
        redoAction,
        markAreaName,
        saveCanvasAsJson,
        changesNumber,
        setChangesNumber,
        canvasInJson,
        editWall,
        removeWall,
        setScaleModalOpen,
        scaleModalOpen,
        resetWallVariables,
        redrawRulers,
        editRackObject,
        setEditRackObject,
        reconstructBays,
        editOtherArea,
        setEditOtherArea,
        reconstructOtherArea,
        handleUnitChangeForBins,
      }}
    >
      {children}
    </FabricContext.Provider>
  );
};
