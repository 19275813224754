import { CircularProgress } from '@mui/material';
import React from 'react';
import { CircleLoadingContainer } from './styles';

const CircularLoading = () => (
  <CircleLoadingContainer>
    <CircularProgress />
  </CircleLoadingContainer>
);

export default CircularLoading;
