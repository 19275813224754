import { styled } from '@mui/material/styles';
import Grid, { GridProps } from '@mui/material/Grid';
import Box, { BoxProps } from '@mui/material/Box';
import Container, { ContainerProps } from '@mui/material/Container';
import Typography, { TypographyProps } from '@mui/material/Typography';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { ButtonProps, IconButton, Link } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Button } from '../../components';

const SignupContainerGrid = styled(Grid)<GridProps>(() => ({
  height: '100vh',
  display: 'flex',
}));

const SignupContainerInnerGrid = styled(Grid)<GridProps>(() => ({
  height: '100vh',
}));

const SignupLeftItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.paper,
  width: 'inherit',
  padding: '0rem 2rem',
}));

const SignupRightItemGrid = styled(Grid)<GridProps>(({ theme }) => ({
  height: 'inherit',
  backgroundColor: theme.palette.background.dark,
  width: 'inherit',
}));

const SignupInnerBlock = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'inherit',
}));

const SignupContainer = styled(Container)<ContainerProps>(() => ({
  root: {
    maxWidth: 'inherit !important',
  },
}));

const ForgotButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  fontWeight: 600,
}));

const LoginButton = styled(Button)(() => ({
  textTransform: 'capitalize',
  display: 'flex',
  fontWeight: 600,
  padding: 0,
}));

const LoginText = styled(Typography)<TypographyProps>(({ theme }) => ({
  display: 'flex',
  color: theme.palette.common.white,
}));

const CoverTitle = styled(Typography)<TypographyProps>(() => ({
  background:
    'linear-gradient(89.68deg, #3BA6F9 10.34%, #36ADF3 18.23%, #00F6C0 94.46%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textTransform: 'capitalize',
}));

const CoverSubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const TextContainer = styled(Box)(() => ({
  marginTop: '8px',
  maxWidth: '500px',
}));

const PasswordBtn = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.passwordIcn,
  },
}));

const CheckBoxLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  color: theme.palette.common.white,
  marginRight: 6,
}));

const ErrorMsg = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 5,
  whiteSpace: 'pre-line',
  '& .MuiTypography-root': {
    color: theme.palette.text.primary,
  },
  '& .closeBtn': {
    display: 'flex',
    marginTop: 25,
  },
}));

const TermLink = styled(Button)<ButtonProps>(() => ({
  lineHeight: 1,
  fontSize: 16,
  padding: 0,
  textTransform: 'lowercase',
}));

const SignUpSuccessMessage = styled(Typography)<TypographyProps>(() => ({
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 'bold',
  textTransform: 'capitalize',
}));

const SignUpSuccessDesc = styled(Typography)<TypographyProps>(() => ({
  textAlign: 'center',
  fontSize: 15,
  marginTop: 15,
}));

const ClickToLogin = styled(Link)(() => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginTop: 15,
}));

const SignUpIcon = styled(CheckCircle)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const SignupIconBlock = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

const TermsModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.common.white,
  padding: 13,
  borderRadius: 5,
  width: '62vw',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

const TermsBlock = styled(Box)<BoxProps>(() => ({
  height: '80vh',
  overflowX: 'auto',
}));

const SignupSuccessBlock = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'left',
  maxWidth: '60%',
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export {
  SignupContainerGrid,
  SignupInnerBlock,
  SignupContainer,
  SignupLeftItemGrid,
  SignupRightItemGrid,
  ForgotButton,
  LoginButton,
  LoginText,
  CoverTitle,
  CoverSubTitle,
  TextContainer,
  PasswordBtn,
  CheckBoxLabel,
  ErrorMsg,
  ModalContainer,
  TermLink,
  SignUpSuccessMessage,
  SignUpSuccessDesc,
  ClickToLogin,
  SignUpIcon,
  SignupIconBlock,
  TermsModalContainer,
  TermsBlock,
  SignupSuccessBlock,
  SignupContainerInnerGrid,
};
