import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  ForgotInnerBlock,
  ForgotLeftItemGrid,
  CoverSubTitle,
  BackToLoginButton,
  SendButton,
  CoverTitle,
} from '../styles';
import { FormikInputField } from '../../../components';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

type ForgotFormProps = {
  t: any;
  navigate: any
  getEmail: (email: string) => void
};

const ForgotForm = ({ t, navigate, getEmail }: ForgotFormProps) => (
  <ForgotLeftItemGrid
    item
    justifyContent="center"
    display="flex"
    alignSelf="center"
  >
    <ForgotInnerBlock>
      <CoverTitle variant="h4">{t('forgot.forgot_title')}</CoverTitle>
      <CoverSubTitle variant="body1">
        {t('forgot.forgot_subtitle')}
      </CoverSubTitle>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values: any) => {
          getEmail(values.email);
        }}
      >
        {() => (
          <Form>
            <Field
              name="email"
              type="email"
              label={t('forgot.email_field_label')}
              component={FormikInputField}
            />
            <SendButton
              title={t('forgot.send_button_text')}
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, mb: 2, maxWidth: 400 }}
              type="submit"
            />
          </Form>
        )}
      </Formik>

      <BackToLoginButton
        startIcon={<ArrowBackIcon />}
        title={t('forgot.back_to_login_button')}
        variant="text"
        fullWidth
        onClick={() => navigate('/app/login')}
      />
    </ForgotInnerBlock>
  </ForgotLeftItemGrid>
);

export default ForgotForm;
