import React from 'react';
import { useTranslation } from 'react-i18next';
import lottieJson from '../../../assets/images/with_sw.json';
import {
  CoverSubTitle,
  CoverTitle,
  LoginInnerBlock,
  LoginRightItemGrid,
  StyledPlayer,
} from '../styles';

const LoginImage = () => {
  const { t } = useTranslation();
  return (
    <LoginRightItemGrid
      item
      justifyContent="center"
      display="flex"
      alignSelf="center"
    >
      <LoginInnerBlock>
        <StyledPlayer autoplay loop src={JSON.stringify(lottieJson)} />
        <CoverTitle variant="h4">{t('login.login_cover_title')}</CoverTitle>
        <CoverSubTitle variant="body1">
          {t('login.login_cover_subtitle1')}
        </CoverSubTitle>
        <CoverSubTitle variant="body1">
          {t('login.login_cover_subtitle2')}
        </CoverSubTitle>
      </LoginInnerBlock>
    </LoginRightItemGrid>
  );
};

export default LoginImage;
