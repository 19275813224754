import React from 'react';
import { ListItemText, Typography } from '@mui/material';
import { ItemContainer, ItemIcon } from '../styles';

type propTypes = {
  icon: any;
  handleClick: () => void;
  text: string;
  disabled?: boolean;
};

const ActionMenuItem = ({ icon, handleClick, text, disabled }: propTypes) => (
  <ItemContainer onClick={handleClick} disabled={disabled}>
    <ItemIcon>{icon}</ItemIcon>
    <ListItemText>
      <Typography variant="body1">{text}</Typography>
    </ListItemText>
  </ItemContainer>
);

export default ActionMenuItem;
