import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  downloadJsonData,
  addConectedDevice,
  updateConnectedDevice,
  deleteConnectedDevice,
  getDeviceList,
} from '../../services/apiDefinition';
import axiosApiInstance from '../../services/axios';
import {
  projectCreateResponse,
} from '../../utils/types/apiTypes';

export const getProjectData = createAsyncThunk('deviceDashboard/projectData', async (project: string) => {
  const response = await axiosApiInstance.get(`${downloadJsonData}/${project}`);
  return response.data;
});

type addDeviceType = {
  data: any;
  projectId: string;
};

export const addDeviceInArea = createAsyncThunk(
  'device/addDeviceInArea',
  async ({ data, projectId }: addDeviceType) => {
    try {
      const response = await axiosApiInstance.post<projectCreateResponse>(
        addConectedDevice,
        data,
        {
          withCredentials: true,
          params: { current_project: projectId },
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

type updateDeviceType = {
  data: any;
  projectId: string;
  deviceId: string;
};

export const updateDeviceInArea = createAsyncThunk(
  'device/updateDeviceInArea',
  async ({ data, projectId, deviceId }: updateDeviceType) => {
    try {
      const response = await axiosApiInstance.put<projectCreateResponse>(
        `${updateConnectedDevice}${deviceId}/`,
        data,
        {
          withCredentials: true,
          params: { current_project: projectId },
        },
      );
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

type deleteDeviceType = {
  projectId: string;
  id: string;
};

export const deleteDeviceInArea = createAsyncThunk(
  'device/deleteDeviceInArea',
  async ({
    projectId,
    id,
  }: deleteDeviceType) => {
    try {
      await axiosApiInstance.delete<projectCreateResponse>(
        `${deleteConnectedDevice}${id}`,
        {
          withCredentials: true,
          params: { current_project: projectId },
        },
      );
      return id;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);

export const getDevices = createAsyncThunk(
  'device/getDeviceList',
  async () => {
    try {
      const response = await axiosApiInstance.get(getDeviceList);
      return response.data;
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 599) {
        throw new Error(e.response.data.message);
      }
    }
  },
);
