import { createSlice } from '@reduxjs/toolkit';
import { DataFrame, toJSON } from 'danfojs';
import { inventoryReducer } from '../../utils/types/reducerStateTypes';
import { getInventoryData, resetInventoryInfluxDB, getWMSInventory } from '../thunks/inventoryThunk';

const initialState: inventoryReducer = {
  data: [],
  reading: false,
  importOpen: false,
  filteredRows: [],
  faliure: false,
  queryOn: false,
  resetComplete: false,
  deleting: false,
  wmsInventoryLoading: false,
  wmsDataLoaded: false,
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setReading: (state, action) => {
      state.reading = action.payload;
    },
    toggleInventoryImportModal: (state, action) => {
      state.importOpen = action.payload;
    },
    handleFilteredRows: (state, action) => {
      state.filteredRows = action.payload;
    },
    setQueryOn: (state, action) => {
      state.queryOn = action.payload;
    },
  },
  extraReducers: {
    [getInventoryData.pending.type]: state => {
      state.faliure = false;
      state.reading = true;
      state.resetComplete = false;
      state.deleting = false;
    },
    [getInventoryData.fulfilled.type]: (state, action) => {
      const data = action.payload.query;
      if (data.length > 0) {
        const newData = [...data];
        const newDataFrame = new DataFrame(newData);
        newDataFrame.drop({ columns: ['result'], inplace: true });
        newDataFrame.drop({ columns: ['table'], inplace: true });
        const jsonData = toJSON(newDataFrame);
        state.data = jsonData as any;
      } else {
        state.data = [];
      }
      state.reading = false;
    },
    [getInventoryData.rejected.type]: state => {
      state.reading = false;
      state.faliure = true;
    },

    [resetInventoryInfluxDB.pending.type]: state => {
      state.faliure = false;
      state.deleting = true;
      state.reading = false;
    },
    [resetInventoryInfluxDB.fulfilled.type]: state => {
      state.deleting = false;
      state.queryOn = false;
      state.filteredRows = [];
      state.faliure = false;
      state.resetComplete = true;
    },
    [resetInventoryInfluxDB.rejected.type]: state => {
      state.deleting = false;
      state.faliure = true;
    },

    [getWMSInventory.pending.type]: state => {
      state.wmsInventoryLoading = true;
      state.wmsDataLoaded = false;
    },
    [getWMSInventory.fulfilled.type]: state => {
      state.wmsInventoryLoading = false;
      state.wmsDataLoaded = true;
    },
    [getWMSInventory.rejected.type]: state => {
      state.wmsInventoryLoading = false;
      state.wmsDataLoaded = false;
    },
  },
});

export const {
  setData,
  setReading,
  toggleInventoryImportModal,
  handleFilteredRows,
  setQueryOn,
} = inventorySlice.actions;

export default inventorySlice;
