import React from 'react';
import {
  Box,
  CardActionArea,
  CardActions,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FolderIcon from '@mui/icons-material/Folder';
import { AreasText, CardBody, ProjectBox } from '../styles';
import Actions from './actions';
import { projectType } from '../../../utils/types/projectTypes';
import useProjects from '../useProjects';

type propTypes = {
  project: projectType;
};

const ProjectsBox = ({ project }: propTypes) => {
  const { switchCurrProject } = useProjects();
  const { t } = useTranslation();
  // const areaCount = project.areas?.length;
  return (
    <Grid item xs={12} md={6} lg={4}>
      <ProjectBox key={project.hashed_identifier}>
        <Box
          display="flex"
          justifyContent="space-between"
          height="100%"
          position="relative"
        >
          <CardActionArea
            onClick={() => switchCurrProject(project.hashed_identifier)}
          >
            <CardBody
              avatar={<FolderIcon fontSize="large" />}
              title={
                <Typography variant="body1" sx={{ marginRight: '20px' }}>
                  {`${project.project_name} ${
                    project.is_sample_project
                      ? `(${t('allProjects.sample')})`
                      : ''
                  }`}
                </Typography>
              }
              subheader={
                <AreasText>
                  {project.details?.city?.description || ''}
                </AreasText>
              }
              sx={{ alignItems: 'start' }}
            />
          </CardActionArea>
          <CardActions>
            <Actions project={project} />
          </CardActions>
        </Box>
      </ProjectBox>
    </Grid>
  );
};

export default ProjectsBox;
